import * as actions from './actions';

const INITIAL_STATE = {
  [actions.ONLINE_ORDERS]: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.ONLINE_ORDERS: {
      return {
        ...state,
        [action.type]: action.payload,
      };
    }

    default:
      return state;
  }
};
