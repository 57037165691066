import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import red from '@material-ui/core/colors/red';
import lightGreen from '@material-ui/core/colors/lightGreen';
import amber from '@material-ui/core/colors/amber';
import teal from '@material-ui/core/colors/teal'
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ddd_DD_HHmm } from '../../../../../util/date';
import { orderStates } from '../../../../../configs/constants';
import TimePicker from './TimePicker';

const { 
  ORDER_OPEN,
  ORDER_ACCEPT, ORDER_ACCEPTED, 
  ORDER_DECLINE, ORDER_DECLINED, 
  ORDER_PREPARING, 
  ORDER_READY_FOR_DELIVER, 
  ORDER_DELIVERING, ORDER_DELIVERED, 
  ORDER_CLOSED, ORDER_CANCELED 
} = orderStates;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '12px 8px'
  },
  cardHeader: {
    padding: 8
  },  
  cardHeaderTitle: {
    display: 'flex',
    flexDirection: 'column'
  },
  cardHeaderColapseBtn: {
    padding: 0,
    paddingLeft: 3
  },  
  cardHeaderSubheader: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  cardContentOrder: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 8
  },
  cardContentOrderTitle: {
    lineHeight: 'normal',
    color: 'rgba(0,0,0, .7)',
    textAlign: 'center'
  },
  withDeliveryDate: {
    width: 'fit-content',
    padding: '0px 6px',
    backgroundColor: amber[800],
    borderRadius: '4px',
    color: '#fff'
  },
  sku: {
    padding: '0px 6px',
    marginRight: 6,
    color: '#fff',
    backgroundColor: '#444',
    borderRadius: '4px',
    lineHeight: 'inherit',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  payment: {
    padding: '0px 7px',
    marginRight: 4,
    color: '#fff',
    backgroundColor: teal[600],
    borderRadius: '4px',
    height: 'fit-content',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  totalPrice: {
    height: 'fit-content',
    lineHeight: 'initial',
    width: 85,
    textAlign: 'right'
  },
  delay: {
    padding: '0px 7px',
    marginLeft: 6,
    color: '#fff',
    backgroundColor: amber[800],
    borderRadius: '4px',
    lineHeight: 'inherit',
    fontWeight: 'bold',
  },
  deliveryAtTop: {
    padding: '0px 4px',
    marginLeft: 0,
    width: 'fit-content',
    color: '#fff',
    backgroundColor: '#3f51b5',
    borderRadius: 4,
    height: 'fit-content'
  },
  deliveryAtTopWarning: {
    padding: '0px 4px',
    marginLeft: 0,
    width: 'fit-content',
    color: '#fff',
    backgroundColor: amber[800],
    borderRadius: 4,
    height: 'fit-content'
  },
  deliveryAt: {
    padding: '0px 4px',
    color: '#fff',
    backgroundColor: amber[800],
    borderRadius: '4px',
    lineHeight: 'inherit',
    marginLeft: 0,
    width: 'fit-content',
    position: 'relative',
    top: -2
  },
  warningButton: {
    display: 'flex',
    color: '#fff',
    backgroundColor: red[600],
    '&:hover': {
        backgroundColor: red[300],
    },
  },
  successButton: {
    display: 'flex',
    color: '#fff',
    backgroundColor: lightGreen[600],
    '&:hover': {
        backgroundColor: lightGreen[300],
    },
  },
  successButtonSmall: {
    color: '#fff',
    backgroundColor: lightGreen[600],
    '&:hover': {
        backgroundColor: lightGreen[300],
    },
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));


export default function OrderCard({ openDialog, translate, updateDeliveryDate, selectedTab, order, setOrderState }) {
  console.log(order.id, {order})
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState({});
  const [actionButton, setDisabled] = React.useState({ fromSelectedTab: selectedTab, isDisabled: false })

  const language = 'ro'

  const { customerInfo, orderDetails, orders, timestamp } = order;
  const { orderId, currency, delay, payment, totalPrice, delivery  } = orderDetails;
  const { deliveryPrice, totalPriceWithDelivery } = delivery || {};
  const { noDelivery } = customerInfo
  
  
  let createdAt
  let withDeliveryDate
  let deliveryDate
  let driverPickupTime
  try {
    createdAt = ddd_DD_HHmm(orderDetails.createdAt.toMillis());
    withDeliveryDate = orderDetails.withDeliveryDate;
    deliveryDate = delay 
      ? ddd_DD_HHmm(timestamp)
      : ddd_DD_HHmm(orderDetails.deliveryDate.toMillis());
    driverPickupTime = orderDetails.driverPickupTimestamp 
      ? ddd_DD_HHmm(orderDetails.driverPickupTimestamp * 1000)
      : null;
  } catch (err) {
    createdAt = order.id
    console.warn('@OrderCard --->', { id: order.id, order, orderDetails, customerInfo, timestamp })
  }



  const handleExpandClick = groupName => event => {
    console.log('handleExpandClick')
    event.preventDefault();
    setExpanded({ ...expanded, [groupName]: !expanded[groupName] });
  };

  const seccondsFrom = (minutesInString) => parseInt(minutesInString) * 60
  
  const setDriverPickupTime = (pickupTime) => {
    const currentDate = new Date();
    const currentDateSeconds = Math.floor(currentDate.getTime() / 1000);
    return (currentDateSeconds + seccondsFrom(pickupTime))
  }

  const handleOrderStateChange = newOrderState => event => {
    console.log('handleOrderStateChange', newOrderState);
    event.preventDefault();
    // trebuie regandit partea de disbale buttons atunci cand se apasa "reject -> cancel/ok"
    // setDisabled({ fromSelectedTab: selectedTab, isDisabled: true })
    setOrderState(newOrderState)
  }

  const handleOrderCanceled = () => {
    console.log('handleOrderCanceled');
    openDialog({
      dialogTitle: translate('What was the reason for cancelling the order?'),
      dialogContentText: '',
      textFieldLabel: '',
      dialogCancel: translate('Cancel'),
      dialogOk: 'Ok',
      order,
    })
  }
  
  
  const OrderPrductsList = () => {
    return (
      <CardContent>
        {orders.map(order => {

          const productName = order.name[language];
          const sku = order.sku;
          const quantity = order.quantity;
          const comment = order.comment || null;
          const price1 = order.price;
          const productTotal = price1 * quantity;

          return (
            <div key={`key-${order.categoryId}-${order.productId}`} className={classes.cardContentOrder}>
              <Typography variant="subtitle1" className={classes.cardContentOrderTitle}>{`${productName}`}</Typography>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {sku && <Typography variant="caption" className={classes.sku} component="span">{`${sku}`}</Typography>}
                <Typography variant="body2" color="textSecondary">{`${quantity} x ${price1} ${currency} = ${productTotal} ${currency}`}</Typography>
              </div>
              {comment && <Typography variant="body2" color="textSecondary">{`${comment}`}</Typography>}
            </div>
          )
        })}

        {!noDelivery && deliveryPrice !== 0 && 
          <div className={classes.cardContentOrder}>
            <Typography variant="subtitle1" className={classes.cardContentOrderTitle}>Cost livrare</Typography>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography variant="body2" color="textSecondary">{`${deliveryPrice} ${currency}`}</Typography>
            </div>
          </div>
        }
      </CardContent>
    )
  }

  const OrderActions = () => {

    const disabled = actionButton.fromSelectedTab === selectedTab && actionButton.isDisabled
    switch (selectedTab) {
      case orderStates.ORDER_OPEN:
      case orderStates.ORDER_NEW:
      {
        if (withDeliveryDate) {
          return (
            <CardActions style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'  }}>
              <Button disabled={disabled} onClick={handleOrderCanceled} variant="contained" className={classes.warningButton}>{translate(ORDER_DECLINE)}</Button>
              <Button disabled={disabled} onClick={handleOrderStateChange({ status: ORDER_ACCEPTED })} variant="contained" className={classes.successButton}>{translate(ORDER_ACCEPT)}</Button>
            </CardActions>
          )
        }

        return (
          <CardActions style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Button disabled={disabled} variant="outlined" size="small" onClick={handleOrderStateChange({ status: ORDER_PREPARING, driverPickupTimestamp: setDriverPickupTime('+15min') })}>+15min</Button>
            <Button disabled={disabled} variant="outlined" size="small" onClick={handleOrderStateChange({ status: ORDER_PREPARING, driverPickupTimestamp: setDriverPickupTime('+30min') })}>+30min</Button>
            <Button disabled={disabled} variant="outlined" size="small" onClick={handleOrderStateChange({ status: ORDER_PREPARING, driverPickupTimestamp: setDriverPickupTime('+45min') })}>+45min</Button>
            <TimePicker translate={translate} handleTimeChange={(driverPickupTimestamp) => setOrderState({ status: ORDER_PREPARING, driverPickupTimestamp })} />
            <Button disabled={disabled} variant="contained" size="small" onClick={handleOrderCanceled} className={classes.warningButton}>{translate(ORDER_DECLINE)}</Button>
          </CardActions>
        )
      }


      case orderStates.ORDER_ACCEPTED: 
      {
        return (
          <CardActions style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex', flex: 1, justifyContent: 'space-around' }}>
              <Button disabled={disabled} onClick={handleOrderStateChange({ status: ORDER_PREPARING })} variant="contained" className={classes.successButton}>{translate(ORDER_PREPARING)}</Button>
            </div>
          </CardActions>
        )
      }

      case orderStates.ORDER_PREPARING: 
      {

        if (withDeliveryDate && !driverPickupTime && !noDelivery) {
          return (
            <CardActions style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <TimePicker translate={translate} handleTimeChange={(driverPickupTimestamp) => setOrderState({ status: ORDER_PREPARING, driverPickupTimestamp })} type="datetime-local" minDateTime={orderDetails.deliveryDate.seconds} maxDateTime={orderDetails.deliveryDate.seconds} />
              <Button disabled={disabled} variant="contained" size="small" onClick={handleOrderCanceled} className={classes.warningButton}>{translate(ORDER_DECLINE)}</Button>
            </CardActions>
          )
        }

        return (
          <CardActions style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
              <Button disabled={disabled} onClick={handleOrderCanceled} variant="contained" className={classes.warningButton}>{translate(ORDER_CANCELED)}</Button>
              {noDelivery 
                ? <Button disabled={disabled} onClick={handleOrderStateChange({ status: ORDER_CLOSED })} variant="contained" className={classes.successButton}>{translate(ORDER_DELIVERED)}</Button>
                : <Button disabled={disabled} onClick={handleOrderStateChange({ status: ORDER_DELIVERING })} variant="contained" className={classes.successButton}>{translate(ORDER_DELIVERING)}</Button>
              }
            </div>
          </CardActions>
        )
      }


      case orderStates.ORDER_READY_FOR_DELIVER: 
      {
        return (
          <CardActions style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
              <Button disabled={disabled} onClick={handleOrderStateChange({ status: ORDER_DELIVERING })} variant="contained" className={classes.successButton}>{translate(ORDER_DELIVERING)}</Button>
            </div>
          </CardActions>
        )
      }

      case orderStates.ORDER_DELIVERING: 
      {
        return (
          <CardActions style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
              <Button disabled={disabled} onClick={handleOrderCanceled} variant="contained" className={classes.warningButton}>{translate(ORDER_CANCELED)}</Button>
              <Button disabled={disabled} onClick={handleOrderStateChange({ status: ORDER_CLOSED })} variant="contained" className={classes.successButton}>{translate(ORDER_DELIVERED)}</Button>
            </div>
          </CardActions>
        )
      }

      default:
        return null;
    }
  }

  const OrderDetails = () => {
    switch (selectedTab) {
      case orderStates.ORDER_OPEN:
      case orderStates.ORDER_NEW:
      case orderStates.ORDER_PREPARING:
      case orderStates.ORDER_ACCEPTED:
      case orderStates.ORDER_DELIVERING: 
      case orderStates.ORDER_READY_FOR_DELIVER: 
      {
        return (
          <CardContent>  
            {orderDetails.remarks && <Typography variant="body2" style={{ marginBottom: 8 }} color="textSecondary" component="p">{`${orderDetails.remarks}`}</Typography>}
            <Typography variant="subtitle2">{`${customerInfo.name}`}</Typography>
            <Typography variant="subtitle2">{`${customerInfo.address}`}</Typography>
            <a href={`tel:${customerInfo.phone}`}><Typography variant="subtitle2" style={{ width: 'fit-content' }}>{`${customerInfo.phone}`}</Typography></a>
            {customerInfo.email && <Typography variant="subtitle2">{`${customerInfo.email}`}</Typography>}
          </CardContent>
        )
      }

      default: {
        if (orderDetails.remarks || withDeliveryDate) {
          return (
            <CardContent>  
              {orderDetails.remarks && <Typography variant="body2" style={{ marginBottom: 8 }} color="textSecondary" component="p">{`${orderDetails.remarks}`}</Typography>}
              {withDeliveryDate && <Typography variant="subtitle2" className={classes.withDeliveryDate} component="p">{`${deliveryDate}`}</Typography>}
            </CardContent>
          )
        }

        return null
      }
    }
  }
  
  const disabled = actionButton.fromSelectedTab === selectedTab && actionButton.isDisabled

  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.cardHeader}
        onClick={[ORDER_OPEN].includes(selectedTab) ? () => {} : handleExpandClick('orderDetails')}
        title={
          <div className={classes.cardHeaderTitle}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="button" className={driverPickupTime ? classes.deliveryAtTop : classes.deliveryAtTopWarning}>{`${driverPickupTime || deliveryDate}`}</Typography>
                <Typography variant="button" style={{ marginLeft: 6 }}>{`#${orderId}`}</Typography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="caption" className={classes.payment}>{`${payment}`}</Typography>
                <Typography variant="button" className={classes.totalPrice}>{`${noDelivery ? totalPrice : totalPriceWithDelivery} ${currency}`}</Typography>
                {[ORDER_ACCEPTED, ORDER_PREPARING, ORDER_DELIVERING].includes(selectedTab) && 
                  <IconButton
                    className={clsx(classes.cardHeaderColapseBtn, classes.expand, {
                      [classes.expandOpen]: expanded.orderDetails,
                    })}
                    onClick={handleExpandClick('orderDetails')}
                    aria-expanded={expanded.orderDetails}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                </IconButton>}
              </div>
            </div>
          </div>
        }
      />
      
        {[ORDER_OPEN].includes(selectedTab) && <OrderPrductsList />}
        {[ORDER_OPEN].includes(selectedTab) && <OrderDetails />}
        {[ORDER_OPEN].includes(selectedTab) && <OrderActions />}
      
      
      <Collapse in={expanded.orderDetails && [ORDER_ACCEPTED, ORDER_PREPARING, ORDER_DELIVERING].includes(selectedTab)} timeout="auto" unmountOnExit>
        <OrderPrductsList />
        <OrderDetails />
        <OrderActions />
      </Collapse>
      


      <Collapse in={expanded.actionButtons && [ORDER_OPEN].includes(selectedTab)} timeout="auto" unmountOnExit>
        <Button disabled={disabled} onClick={handleOrderStateChange({ status: ORDER_DECLINED })} variant="contained" className={classes.warningButton}>{translate(ORDER_DECLINED)}</Button>
      </Collapse>
      
    </Card>
  );
}
