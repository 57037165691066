import keys from 'lodash/keys'
import omit from 'lodash/omit'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import translateAction from '../../../util/translate'
import Avatar from '@material-ui/core/Avatar'
import OrderList from './OrderList'
import Menu from './Menu'
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Add from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import FeedbackIcon from '@material-ui/icons/FeedbackOutlined'
import DialogContent from '@material-ui/core/DialogContent';
import MenuActions from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { orderStates } from '../../../configs/constants'
import styles from './styles'
import Feedbacks from './Feedbacks'

const HIDE_TIPS = true

const ORDER = 'ORDER'
const FULL_ORDER_LIST = 'FULL_ORDER_LIST'
const MENU = 'MENU'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})


const fixDecimals = amount => {
    let value = `${amount}`.split('.')[0]
    let decimals = `${amount}`.split('.')[1]
    if (value === '0' && decimals !== undefined) {
        decimals = (decimals + '00').substr(0, 3)
    } else {
        if (decimals === undefined) {
            decimals = '00'
        } else {
            decimals = `${decimals}0`.substr(0, 2)
        }
    }
    if (decimals === '00') {
        return `${value}`
    }
    return `${value}.${decimals}`
}





const TableActions = ({ displayFullOrderList, clearTable })  => {
	const [anchorEl, setAnchorEl] = React.useState(null);
  
	const handleClick = (event) => {
	  setAnchorEl(event.currentTarget);
	};
  

	const handleClose = action => {
		console.log('handleClose', { action })
		switch (action) {
			case 'displayFullOrderList':
				displayFullOrderList()
				break;
			case 'clearTable':
				clearTable()
				break;
			default:
				break;
		}
		setAnchorEl(null);
	}
  
	return (
	    <div>
            <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <MenuActions
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            >
                {displayFullOrderList && <MenuItem onClick={() => handleClose('displayFullOrderList')}>Orders List & Pay</MenuItem>}
                {clearTable && <MenuItem onClick={() => handleClose('clearTable')}>Close Table</MenuItem>}
                {/* <MenuItem disabled onClick={() => handleClose('changeWaiter')}>Change waiter</MenuItem> */}
            </MenuActions>
        </div>
	);
}



const PersonActions = ({ removePerson, clearUnverifiedOrder, clearAndBlockPerson, changeTable }) => {
    
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = action => {
        console.log('handleClose', { action })
        switch (action) {
            case 'removePerson':
                console.log('removePerson')
                removePerson()
                break;
            case 'clearUnverifiedOrder':
                console.log('clearUnverifiedOrder')
                clearUnverifiedOrder()
                break;
            case 'clearAndBlockPerson':
                console.log('clearAndBlockPerson')
                clearAndBlockPerson()
                break;
            case 'changeTable':
                console.log('changeTable')
                break;
            default:
                break;
        }
        setAnchorEl(null);
    }

    return (
		<div>
			<IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
				<MoreVertIcon style={{ fontSize: 'medium' }} />
			</IconButton>
			<MenuActions
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				{removePerson && <MenuItem onClick={() => handleClose('removePerson')}>Remove</MenuItem>}
				{clearUnverifiedOrder && <MenuItem onClick={() => handleClose('clearUnverifiedOrder')}>Clear request</MenuItem>}
				{clearAndBlockPerson && <MenuItem onClick={() => handleClose('clearAndBlockPerson')}>Clear & Block</MenuItem>}
				{/* <MenuItem disabled onClick={() => handleClose('changeTable')}>Change table</MenuItem> */}
			</MenuActions>
		</div>
    );
}

class TableDetails extends Component {
    
    state = {
        openClearTable: false,
        show: '',
        menu: {},
        visibleFeedbacks: []
    }


    componentDidUpdate() {
        window.history.pushState(null, null, window.location.href)

        window.onpopstate = (wind) => {
            wind.preventDefault()

            if (this.state.show !== '') {
                if (this.state.categoryExpanded) {
                    this.setState({ closeMenuCategory: !this.state.closeMenuCategory, categoryExpanded: false })
                } else {
                    this.setState({ show: '' })
                }
            } else if (this.state.openClearTable) {
                console.log('onBack -> tabke -> handleCloseClearTableDialog')
                this.handleCloseClearTableDialog()
                window.history.go(1)
            } else if (this.state.show === '') {
                console.log('onBack -> tabke display -> handleClose')
                this.handleClose()
                window.history.go(1)
            }
        }

    }


    componentWillUnmount() {
        window.onpopstate = () => { }
    }


    translate = (text) => {
        return translateAction(text, this.props.language)
    }


    handleClose = () => {
        const { show } = this.state
        if (show === ORDER) {
            return this.setState({ show: '', menu: {} }, () => this.props.displayOrder({ display: false }))
        }

        if (show === FULL_ORDER_LIST) {
            return this.setState({ show: '', menu: {} }, () => this.props.displayOrder({ display: false }))
        }

        if (show.includes(MENU)) {
            return this.setState({ show: show.replace(MENU, '') }, () => this.props.restaurantMenu({ display: false }))
        }

        return this.setState({ show: '', menu: {} }, () => this.props.hideTableDetails())
    };

    

    clearTable = (params) => {
        console.log('**** clearTable', this.props.selectedTable, params)
        
        this.props.closeTable(params)
        this.handleClose()
    }


    handleOpenClearTableDialog = () => {
        this.setState({ openClearTable: true });
    };

    handleCloseClearTableDialog = () => {
        this.setState({ openClearTable: false });
    };


    confirmOrder = (params) => {
        console.log('@confirmOrder', params)
        this.props.setOrderStatus(params)
    }


    removePerson = (params) => {
        console.log('@TableDetails -> removePerson', params)
        this.props.removePerson(params)
    }
    
    clearAndBlockPerson = (params) => {
        console.log('@TableDetails -> clearAndBlockPerson', params)
        this.props.clearAndBlockPerson(params)
    }

    clearUnverifiedOrder = (params) => {
        console.log('@TableDetails -> clearUnverifiedOrder', params)
        this.props.clearUnverifiedOrder(params)
    }


    displayMenu = (params) => {
        console.log('@displayMenu', params)
        this.setState({ show: MENU }, () => this.props.restaurantMenu(params))
    }

    displayOrder = (params) => {
        console.log('@displayOrder', params)
        this.setState({ show: ORDER }, () => this.props.displayOrder(params))
    }

    displayFullOrderList = (params) => {
        console.log('@displayFullOrderList', params)
        this.setState({ show: FULL_ORDER_LIST }, () => this.props.displayOrder(params))
    }


    displayMenuFromOrderList = (params) => {
        console.log('@displayMenuFromOrderLits', params)
        this.setState({ show: ORDER + MENU, menu: { ...params.menu } }, () => this.props.restaurantMenu(params))
    }

    displayMenuFromTableDetails = (params) => {
        console.log('@displayMenuFromTableDetails', params)
        this.setState({ show: MENU, menu: { ...params.menu } }, () => this.props.restaurantMenu(params))
    }


    displayOrders = ({ personId, type, orders }) => {
        const { classes, menu, currency, language, restaurantOrderId } = this.props
        let lastOrderTime = null
        let lastOrderId = null
        let ordersList = null

        switch (type) {
            case orderStates.ORDER_UNVERIFIED: {
                const currentOrder = orders[orders.length - 1]

                if (currentOrder) {
                    lastOrderId = currentOrder.id

                    lastOrderTime = new Date(Date.now() - new Date(currentOrder.timestamp.seconds * 1000)).toGMTString().substr(18, 4)
                    const h = parseInt(lastOrderTime.split(':')[0], 10)
                    const m = parseInt(lastOrderTime.split(':')[1], 10)
                    if (lastOrderTime !== '') {
                        if (h < 1) {
                            if (m < 3) {
                                lastOrderTime = `just now`
                            } else {
                                lastOrderTime = `${m}min ago`
                            }
                        } else {
                            lastOrderTime = `${h}h ${m}min ago`
                        }
                    }

                    if (currentOrder.status === type) {
                        this.DISABLE_ADDING_NEW_PERSON = true
                        ordersList = keys(omit(currentOrder, ['id', 'status', 'timestamp', 'state'])).map(categoryId => {
                            console.log('categoryId', categoryId)
                            const categoryName = menu.find(c => c.details.id === categoryId).details.name[language]

                            return (
                                <div key={categoryId} style={{ paddingBottom: 10 }}>
                                    <Typography className={classes.categoryName} onClick={() => this.displayMenuFromTableDetails({ menu: { categoryId }, restaurantOrderId, personId })}>
                                        {`${categoryName}`}
                                    </Typography>
                                    {keys(currentOrder[categoryId]).map(productId => {
                                        const product = menu.find(c => c.details.id === categoryId).items.find(p => p.id === productId)
                                        const quantity = currentOrder[categoryId][productId].quantity

                                        let price1

                                        let confirmedPrice1
                                        let confirmedQuanity
                                        let removedList = []
                                        let removedMore = quantity

                                        if (currentOrder.state === orderStates.ORDER_MODIFIED_BY_WAITER && currentOrder[categoryId][productId].quantity < 0) {
                                            // scade din comanda facuta
                                            const lastConfirmedOrders = [...orders].reverse().filter(o => o.state !== orderStates.ORDER_MODIFIED_BY_WAITER)

                                            for (let i = 0; i < lastConfirmedOrders.length; i++) {
                                                const confirmedOrder = lastConfirmedOrders[i]

                                                if (confirmedOrder[categoryId] && confirmedOrder[categoryId][productId]) {

                                                    confirmedPrice1 = confirmedOrder[categoryId][productId].price1
                                                    confirmedQuanity = confirmedOrder[categoryId][productId].quantity

                                                    removedList = [
                                                        ...removedList,
                                                        {
                                                            quantity: confirmedQuanity < Math.abs(removedMore) ? -confirmedQuanity : removedMore,
                                                            price1: confirmedPrice1
                                                        }
                                                    ]

                                                    removedMore = removedMore + ((confirmedOrder[categoryId] && confirmedOrder[categoryId][productId].quantity) || 0)

                                                    if (removedMore === 0) {
                                                        break
                                                    }
                                                }


                                            }
                                        } else {
                                            price1 = currentOrder[categoryId][productId].price1
                                        }


                                        const productName = product.name[language]
                                        const productWeight = (product.weight === undefined || product.weight === null || product.weight === '' || product.weight === '0' || product.weight === 0) ? '' : product.weight

                                        if (removedList.length > 0) {
                                            return (
                                                <div key={`productId-${categoryId}-${productId}`} style={{ paddingBottom: '6px', textAlign: 'center' }}
                                                    onClick={() => this.displayMenuFromTableDetails({ menu: { categoryId, productId }, restaurantOrderId, personId })}
                                                >
                                                    <Typography className={classes.item}>
                                                        {`${productName} ${productWeight}`}
                                                    </Typography>
                                                    {
                                                        removedList.map((p, index) => {
                                                            const productTotal = fixDecimals(p.quantity * p.price1)
                                                            return (
                                                                <Typography key={index} className={classes.item}>
                                                                    {`${p.quantity} x ${p.price1} ${currency} = ${productTotal} ${currency}`}
                                                                </Typography>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            )
                                        }

                                        const productTotal = fixDecimals(quantity * price1)

                                        return (
                                            <div key={`productId-${categoryId}-${productId}`} style={{ paddingBottom: '6px', textAlign: 'center' }}
                                                onClick={() => this.displayMenuFromTableDetails({ menu: { categoryId, productId }, restaurantOrderId, personId })}
                                            >
                                                <Typography className={classes.item}>{`${productName} ${productWeight}`}</Typography>
                                                <Typography className={classes.item}>{`${quantity} x ${price1} ${currency} = ${productTotal} ${currency}`}</Typography>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })
                    }
                }

                break
            }

            default:
                break
        }

        return ({
            lastOrderId,
            lastOrderTime,
            ordersList
        })
    }

    setFeedbackVisiblity = personId => {
        console.log('setFeedbackVisiblity', personId)
        let visibleFeedbacks = []
        if (this.state.visibleFeedbacks.includes(personId)) {
            visibleFeedbacks = this.state.visibleFeedbacks.filter(vfb => vfb !== personId)
        } else {
            visibleFeedbacks = [ ...this.state.visibleFeedbacks,  personId ]
        }
        this.setState({ ...this.state, visibleFeedbacks })
    }


    getPersonsList = orders => {
        this.DISABLE_ADDING_NEW_PERSON = false

        console.log('@getPersonsList', orders)
        if (orders === undefined || !Array.isArray(orders.persons)) {
            return null
        }
        const { currency, feedbacks, language } = this.props
        const { persons, moneyOnTable } = orders
        const restaurantOrderId = orders.id

        let MONEY_ON_TABLE = parseFloat(moneyOnTable) || 0

        return persons.map((person, index) => {
            const { classes } = this.props
            const { lastOrderTime, lastOrderId, ordersList } = this.displayOrders({ personId: person.id, type: orderStates.ORDER_UNVERIFIED, orders: person.orders || [] })
            
            if (this.DISABLE_ADDING_NEW_PERSON === false){
                this.DISABLE_ADDING_NEW_PERSON = person.orders.length === 0
            }

            const personId = person.id
            const hasToPay = parseFloat(person.hasToPay) || 0
            const paid = parseFloat(person.paid || 0)

            let remainsToPay = hasToPay - parseFloat(person.paid || 0)
            if (MONEY_ON_TABLE > 0 && remainsToPay > 0) {
                if (MONEY_ON_TABLE > remainsToPay) {
                    MONEY_ON_TABLE = MONEY_ON_TABLE - remainsToPay
                    remainsToPay = 0
                } else {
                    remainsToPay = hasToPay - (paid + MONEY_ON_TABLE)
                    MONEY_ON_TABLE = 0
                }
            }
            console.log('MONEY_ON_TABLE',{ MONEY_ON_TABLE, hasToPay, paid, remainsToPay, lastorder: person.orders[person.orders.length-1] })
            
            let payWith = ''
            const payExtras = this.props.orders['PAY-extra']
            if (payExtras) {
                const payExtra = payExtras.filter(extra => extra.personId === personId)[0]
                if (payExtra !== undefined) {
                    payWith = payExtra.payWith
                }
            }
            console.log('PAY - EXTRAS', { payWith, remainsToPay })

            const feedbacksPerson = feedbacks.filter(fb => fb.personId === person.id)
            const isFeedbackVisible = this.state.visibleFeedbacks.includes(person.id)

            return (
                <Card key={person.id} className={classes.cardPerson}>

                    <CardHeader
                        avatar={<Avatar className={classes.avatar}>{(index + 1)}</Avatar>}
                        action={
                            ((person.orders.length !== 0) && (person.orders[person.orders.length-1] === undefined || (person.orders[person.orders.length-1] && person.orders[person.orders.length-1].status && person.orders[person.orders.length-1].status !== orderStates.ORDER_UNVERIFIED)))
                            
                            ? (feedbacksPerson.length === 0 ? null
                                : <IconButton onClick={() => this.setFeedbackVisiblity(person.id)}>
                                    <FeedbackIcon />
                                </IconButton> 
                            )

                            : (
                                <div style={{ display: 'flex' }}>
                                    {
                                        feedbacksPerson.length > 0 && 
                                        <IconButton onClick={() => this.setFeedbackVisiblity(person.id)}>
                                            <FeedbackIcon />
                                        </IconButton>
                                    }
                                    <PersonActions 
                                        removePerson={ 
                                            person.orders.length !== 0 
                                            ? false 
                                            : () => this.removePerson({ restaurantOrderId, personId }) 
                                        }
                                        clearUnverifiedOrder={ 
                                            person.orders[person.orders.length-1] === undefined || (person.orders[person.orders.length-1] && person.orders[person.orders.length-1].status && person.orders[person.orders.length-1].status !== orderStates.ORDER_UNVERIFIED) 
                                            ? false
                                            : () => this.clearUnverifiedOrder({ restaurantOrderId, personId }) 
                                        } 
                                        clearAndBlockPerson={
                                            personId.indexOf('WAITER') !== -1
                                            ? false
                                            : () => this.clearAndBlockPerson({ restaurantOrderId, personId })
                                        }
                                    />
                                </div>
                            )
                        }
                        title={
                            <div style={{ display: 'flex', flexDirection: 'row', fontSize: 'larger', fontWeight: 'bolder' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Typography style={{ color: (remainsToPay === hasToPay && hasToPay !== 0) ? 'red' : '#333', paddingRight: 10, fontSize: 'inherit', fontWeight: 'inherit' }}>{`${fixDecimals(hasToPay)} ${currency}`}</Typography>
                                    {   
                                        (remainsToPay === hasToPay && hasToPay !== 0) && (Math.abs(remainsToPay) === hasToPay || remainsToPay === 0) && payWith !== '' &&
                                        <Typography style={{ padding: '0px 5px', borderRadius: 8, marginLeft: 4, fontSize: 'x-small', fontWeight: '900', backgroundColor: 'black', color: 'white' }}>{`${payWith}`}</Typography>
                                    }
                                </div>
                                {
                                    (Math.abs(remainsToPay) === hasToPay || remainsToPay === 0) 
                                        ? null 
                                        : remainsToPay > 0
                                            ? <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <Typography className={classes.red} style={{ fontSize: 'inherit', fontWeight: 'inherit' }}>{`(${fixDecimals(remainsToPay)} ${currency})`}</Typography>
                                                <Typography style={{ padding: '0px 5px', borderRadius: 8, marginLeft: 4, fontSize: 'x-small', fontWeight: '900', backgroundColor: 'black', color: 'white' }}>{`${payWith}`}</Typography>
                                            </div>
                                            
                                            : (
                                                HIDE_TIPS 
                                                    ? null
                                                    : <Typography className={classes.green} style={{ fontSize: 'inherit', fontWeight: 'inherit' }}>
                                                        {`(+${fixDecimals(Math.abs(remainsToPay))} ${currency})}`}
                                                    </Typography>
                                            )
                                }
                            </div>
                        }
                        subheader={lastOrderTime && `last order ${lastOrderTime}`}
                    />


                    <CardContent className={classes.cardContent}>
                        {ordersList}
                    </CardContent>


                    <CardActions>
                        {
                            ordersList &&
                            <Button 
                                size="small" color="primary" 
                                onClick={() => this.confirmOrder({ restaurantOrderId, personId, personOrderId: lastOrderId, status: orderStates.ORDER_CONFIRMED })}
                            >
                                CONFIRM
                            </Button>
                        }
                        <div style={{ marginLeft: 'auto' }}>
                            {
                                person.orders.length > 0 &&
                                <Button size="small" color="primary"
                                    onClick={() => this.displayOrder({ restaurantOrderId, personId, remainsToPay: fixDecimals(remainsToPay) })}
                                >ORDER</Button>
                            }

                            <Button size="small" color="primary"
                                onClick={() => this.displayMenu({ restaurantOrderId, personId })}
                            >MENU</Button>
                        </div>
                    </CardActions>
                    
                    {
                        isFeedbackVisible && 
                        <Feedbacks language={language} feedbacks={feedbacksPerson} />
                    }

                </Card>
            )
        })
    }

    addNewPersonAtTable = () => {
        console.log('@TableDetails -> addNewPersonAtTable')
        this.props.addNewPersonAtTable()
    }

    render() {
        console.log('TableDetails -> Render ', this.props)

        const translate = this.translate

        const { classes, orders, restaurantOrderId, currency, selectedTable, personId, displayOrder, feedbacks, language, waiterCreateOrders } = this.props
        const [ areaName, tableNumber ] = selectedTable.split(':')
        const { show } = this.state

        const MONEY_ON_TABLE = (orders && parseFloat(orders.moneyOnTable)) || 0
        let TOTAL_TO_PAY = 0
        let TABLE_PAID = 0

        if (orders && Array.isArray(orders.persons)) {
            for (const person of orders.persons) {
                const hasToPay = parseFloat(person.hasToPay) || 0
                const paid = parseFloat(person.paid || 0)

                TOTAL_TO_PAY = TOTAL_TO_PAY + hasToPay
                TABLE_PAID = TABLE_PAID + paid
            }
        }


        const displayPersonOrderMenu = (personId, display) => {
            if (personId && display) {
                const personOrders = (personId && orders && orders.persons.filter(p => p.id === personId)[0].orders) || []

                return (
                    <Menu
                        {...this.props}
                        orders={personOrders}

                        categoryExpanded={isExpanded => this.categoryExpanded(isExpanded)}
                        closeMenuCategory={this.state.closeMenuCategory}

                        goToCategory={this.state.menu.categoryId}
                        goToProduct={this.state.menu.productId}
                    />
                )
            }

            return null
        }



        const displayOrderList = ({ personId, displayOrder }) => {
            if (displayOrder) {

                const person = (personId && orders && orders.persons.filter(p => p.id === personId)[0])
                
                return (
                    <OrderList
                        {...this.props}
                        orders={personId ? person.orders || [] : this.props.orders}
                        paid={personId ? person.paid || 0 : 0}
                        displayMenu={({ categoryId, productId }) => this.displayMenuFromOrderList({ menu: { categoryId, productId }, restaurantOrderId, personId })}
                        handleClose={this.handleClose}
                    />
                )
            }
            return null
        }



        
        const TIP = (MONEY_ON_TABLE + TABLE_PAID) - TOTAL_TO_PAY 
        console.log({ TOTAL_TO_PAY, TABLE_PAID, MONEY_ON_TABLE, TIP })
        let tip = null
        if (TIP > 0) {
            tip = <Card key={tip} className={classes.cardPerson}>
                <CardHeader style={{ padding: 0, background: 'teal' }}
                    title={<Typography variant="h6" style={{ textAlign: 'center', color: 'white' }}>{`${translate('TIP')} ${fixDecimals(TIP)} ${currency}`}</Typography>}
                />
            </Card>
        }
        
        const personsList = waiterCreateOrders && ((tip === null || HIDE_TIPS) ? this.getPersonsList(orders) : [ tip, ...this.getPersonsList(orders) ])

        if (!waiterCreateOrders) {
            this.DISABLE_ADDING_NEW_PERSON = true
        }

        // Trebui un alt view ptr feedbacks --> un feed de feedbacks organizat pe zile/ore
        // const feedbackWithouOrderId = feedbacks.filter(feedback => feedback.tableId === selectedTable)

        return (
            <Dialog
                fullScreen
                open={this.props.showTable}
                onClose={this.handleClose}
                TransitionComponent={Transition}
            >
                <AppBar style={show.includes(MENU) ? { background: '#fff', boxShadow: 'none' } : { background: '#fff' }}>
                    <Toolbar className={classes.toolbar} style={{ paddingRight: 8 }}>
                        <Avatar className={classes.greenAvatar} onClick={this.handleClose}>{tableNumber}</Avatar>
                        <Typography variant="h6" style={{ marginRight: 'auto' }}>{areaName}</Typography>
                        
                        {
                            waiterCreateOrders && (!show.includes(MENU) && this.state.show !== FULL_ORDER_LIST) && this.state.show !== ORDER && orders && 
                            <TableActions 
                                displayFullOrderList={
                                    orders && Array.isArray(orders.persons) && orders.persons[0] && Array.isArray(orders.persons[0].orders) && orders.persons[0].orders[0]
                                    ? () => this.displayFullOrderList({ restaurantOrderId })
                                    : false
                                }
                                clearTable={
                                    orders
                                    ? () => this.clearTable({ restaurantOrderId, tip: `${TIP} ${currency}` })
                                    : false
                                }
                            />
                        }
                    </Toolbar>
                </AppBar>
                
                {show.includes(MENU) && displayPersonOrderMenu(personId, displayOrder)}

                {show === ORDER && displayOrderList({ personId, displayOrder })}

                {show === FULL_ORDER_LIST && displayOrderList({ displayOrder })}

                {
                    show === '' &&
                    <DialogContent className={classes.tdContent}>
                        <List className={classes.root}>
                            {/* !waiterCreateOrders && <Feedbacks language={language} feedbacks={feedbackWithouOrderId} /> */}
                            {personsList}
                        </List>

                        {
                            !this.DISABLE_ADDING_NEW_PERSON &&
                            <Fab disabled={this.DISABLE_ADDING_NEW_PERSON} className={classes.fabTealAddPerson} onClick={this.addNewPersonAtTable}>
                                <Add />
                            </Fab>
                        }

                    </DialogContent>
                }


            </Dialog>
        )
    }
}

TableDetails.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(TableDetails)
