
import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CssBaseline from '@material-ui/core/CssBaseline';
import { connect } from 'react-redux';
import * as actions from './appActions';

import { STAND_MENU } from '../configs/constants'

import LanguageScreen from './choose_language/components/LanguageScreen';

import RestaurantMenuBinding from './restaurant_menu/bindings/RestaurantMenuBinding';
import { isTableIdValid } from './waiter/utils';
import { resetLanguage } from './choose_language/actions';

const styles = {
  container: {
    height: '-webkit-fill-available',
    overflowY: 'hidden',
    overflowX: 'hidden',
  },
  main: {
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
};

const Customer = (props) => {
  console.log('111111111111111 -----------> @Customer', { props })
  

  useEffect(() => {
    const initialData = async () => {
      const restaurantUrl = props.match.isExact && props.match.params.restaurantUrl;
      const tableId = props.match.isExact && props.match.params.tableId;

      if (props.INFO && props.INFO.url !== restaurantUrl) {
        props.resetLanguage()
      }

      console.log('***********************@Customer - useEffect -->', { props, restaurantUrl, tableId });
      if (restaurantUrl && tableId) {
        const restaurantId = await props.getRestaurantInfo(restaurantUrl);
        console.log('@Customer - useEffect --> response props.getRestaurantInfo(restaurantUrl)', {restaurantId})

        props.setRestaurantIdAndTableId({ restaurantId, tableId });
        props.createUidFingerprint();
      }
    }

    const restaurantUrl = props.match.isExact && props.match.params.restaurantUrl;
    const tableId = props.match.isExact && props.match.params.tableId;
    if (!props.INFO || (props.INFO && (props.INFO.url !== restaurantUrl || props.TABLE_ID !== tableId))) {
      initialData()
    }
  });


  console.log('@Customer', { props });
  const { SELECTED_LANGUAGE, INFO, RESTAURANT_ID, TABLE_ID } = props;
  
  console.log('@Customer', { props, INFO, RESTAURANT_ID, TABLE_ID });

  const restaurantUrl = props.match.isExact && props.match.params.restaurantUrl;
  const tableId = props.match.isExact && props.match.params.tableId;
  if (!TABLE_ID || (INFO && INFO.url !== restaurantUrl) || TABLE_ID !== tableId) {
    return null
  }

  if (!INFO || (INFO && TABLE_ID && TABLE_ID !== STAND_MENU && !isTableIdValid(INFO.tablesConfig, TABLE_ID))) {
    return <Redirect to="/" path="/" />
  }

  

  const logoUrl = INFO === undefined ? null : INFO.logoUrl;
  const languages = INFO === undefined ? null : INFO.languages;
  
  if (!SELECTED_LANGUAGE) {
    return <LanguageScreen logoUrl={logoUrl} languages={languages} />;
  }

  return (
    <div style={styles.container}>
      <CssBaseline />
      <Helmet>
        <title>Orderix</title>
        <meta name="description" content="Orderix" />
      </Helmet>

      <div style={styles.main}>
        <RestaurantMenuBinding />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log('@Customer mapStateToProps', state);
  return {
    RESTAURANT_ID: state.SCANNER.restaurantId,
    TABLE_ID: state.SCANNER.tableId,
    INFO: state.RESTAURANT.INFO,
    SELECTED_LANGUAGE: state.CLIENT.SELECTED_LANGUAGE
  };
};

const mapDispatchToProps = (dispatch) => ({
  getRestaurantInfo: (restaurantUrl) => dispatch(actions.getRestaurantInfo(restaurantUrl)),
  setRestaurantIdAndTableId: ({ restaurantId, tableId }) => dispatch(actions.setRestaurantIdAndTableId({ restaurantId, tableId })),
  createUidFingerprint: () => dispatch(actions.createUidFingerprint()),
  resetLanguage: () => dispatch(resetLanguage())
});

export default connect(mapStateToProps, mapDispatchToProps)(Customer);
