export default `
    Menu                    = Menü
    Welcome                 = Willkommen
    Price                   = Preis
    Add                     = Hinzufügen
    Remove                  = Entfernen
    Order list              = Bestellliste
    Order                   = Bestellen
    Place order             = Bestellung aufgeben
    Tables                  = Tische
    Pay                     = Zahlen
    Recommended             = Empfohlen
    Total                   = Gesamtbetrag
    Call for waiter         = Rufen Sie nach Kellner
    PAY                     = ZAHLEN
    PAY CASH                = BAR BEZAHLEN
    PAY CARD                = Karte bezahlen
    CARD                    = KARTE
    CASH                    = KASSE
    Later                   = Später
`

