import React, { useState } from 'react'
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied'
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied'
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied'
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined'
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied'
import Rating from '@material-ui/lab/Rating'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import translateAction from '../../../util/translate'


const smiliesIcons = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon />,
        label: 'Very Dissatisfied'
    },
    2: {
        icon: <SentimentDissatisfiedIcon />,
        label: 'Dissatisfied'
    },
    3: {
        icon: <SentimentSatisfiedIcon />,
        label: 'Neutral'
    },
    4: {
        icon: <SentimentSatisfiedAltIcon />,
        label: 'Satisfied'
    },
    5: {
        icon: <SentimentVerySatisfiedIcon />,
        label: 'Very Satisfied'
    }
}


function SmiliesIconContainer(props) {
    const { value, ...other } = props
    return (
        <span {...other}>
            {smiliesIcons[value].icon}
        </span>
    )
}


SmiliesIconContainer.propTypes = {
    value: PropTypes.number.isRequired
}



export default function Feedbacks({ language, feedbacks }) {

    console.log('Feedback', { language, feedbacks })

    const [isVisible, setVisiblity] = useState(true)

    if (!isVisible || !feedbacks[0]) {
        return null
    }

    
    const translate = text => {
        return translateAction(text, language)
    }

    const handleVisibility = () => {
        setVisiblity(!isVisible)
    }

    const generateViewFromFeedbackForm = ({ feedbackForm }) => {
        console.log('feedbackForm', feedbackForm)
        return feedbackForm.map((feedback, index) => (
            <li key={`f-${index}`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                
                {
                    feedback.type !== 'text' &&
                    <Typography variant="caption">{`${translate(feedback.question)}`}</Typography>
                }
    
                {
                    feedback.type === 'level-smilies' &&
                    <Rating readOnly value={feedback.answer ? parseInt(feedback.answer, 10) : 3} getLabelText={value => smiliesIcons[value].label} 
                        IconContainerComponent={SmiliesIconContainer} 
                    />
                }
    
                {
                    feedback.type === 'text' &&
                    <Typography variant="caption" style={{ fontStyle: 'italic', margin: '4px 0px' }}>{feedback.answer}</Typography>
                }
            </li>
        ))
    }

    const feedbacksView = feedbacks.map((eachFeadback, index) => (
        <ul key={`feedback-${index}`} style={{ marginTop: (index === 0 ? 0 : 4), padding: '4px 10px', backgroundColor: 'rgba(155,175,75, .1' }}>
            {generateViewFromFeedbackForm(eachFeadback)}
        </ul>
    ))


    return (
        <div onClick={handleVisibility}>
            {feedbacksView}
        </div>
    )    
}