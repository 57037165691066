import isEmpty from 'lodash/isEmpty'
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import TableDetails from './TableDetails'
import ReadOrderQR from './ReadOrderQR'
import ListTables from './ListTables'
import CircularProgress from '@material-ui/core/CircularProgress'
import translateAction from '../../../util/translate'
import { userRoles } from '../../../configs/constants'
import UserRoleViewSelection from './UserRoleViewSelection'
import DispatcherBinding from './dispatcher/bindings/DispatcherBinding'
import NavBarWaiterComponent from './NavBarWaiterComponent'
import AssignedTables from './AssignedTables'

// import agileHubLogo from '../../../assets/images/agilehub64.png'


const styles = theme => ({
    progress: {
        margin: '33% auto', display: 'block'
    },
    flex: {
        flex: 1,
    },
    toolbar: {
        flex: '1',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
        background: '#fff',
        color: 'rgba(0,0,0, .5)'
    },
    logo: {
        height: '40px',
        flex: 1,
        objectFit: 'contain',
        objectPosition: 'left',
        verticalAlign: 'middle',
    },
})

class WaiterComponent extends Component {

    state = {
        selectedRole: 0
    }

    componentWillMount() {
        console.log('WaiterComponent -> CompoenentWillMount', this.props)
        this.props.getOpenedOrders()
        if (this.props.waiterCreateOrders) {
            this.props.getMenu()
        }
        if (!isEmpty(this.props.scannedData)) {
            this.props.handleQRCodeData(this.props.scannedData)
        }
    }


    componentDidUpdate() {
        console.log('RestaurantMenuComponent -> componentDidUpdate')
        if (this.props.showQRCodeScanner) {
            window.history.pushState(null, null, window.location.href)
            window.onpopstate = (wind) => {
                wind.preventDefault()

                console.log('onBack -> close qrCodeScanner')
                this.handleQRCodeScanner()
                window.history.go(1)
            }
        }
    }


    componentWillUnmount() {
        window.onpopstate = () => { }
    }

    translate = (text) => {
        return translateAction(text, this.props.language)
    }

    addNewPersonAtTable =(restaurantOrderId) => {
        let personsOnTheTable = []
        if (this.props.openOrders) {
            const selectedTable = this.props.openOrders.filter(od => od.tableId === this.props.selectedTable)[0] || {}
            personsOnTheTable = selectedTable.persons || []
        }

        const personId = `WAITER-${this.props.selectedTable}-${personsOnTheTable.length + 1}`
        this.props.addNewPersonAtTable({ restaurantOrderId, personId })
        this.props.restaurantMenu({ restaurantOrderId, personId })

    }

    hideTableDetails = () => {
        this.props.selectTable({ tableId: this.props.selectedTable })
    }

    handleQRCodeScanner = () => {
        this.props.handleQRCodeScanner()
    }

    handleLogoutWaiter = () => {
        this.props.waiterLogout()
    }


    getSelectedTableOrders = async selectedTable => {
        console.log('@WaiterComponent -> this.getSelectedTableOrders', selectedTable)
        const selectedTableOrder = this.props.openOrders.filter(od => od.tableId === selectedTable)[0]
        const orders = await this.props.getSelectedTableOrders(selectedTableOrder)
        console.log(`horecaOrders`, orders)
        return orders
    }
    
    handleRoleSelection = selectedRole => {
        console.log(`selectedRole`, selectedRole)
        this.setState({ selectedRole })
    }

    updateAssignedTablesStatus = assignedTables => {
        console.log('updateAssignedTablesStatus', { assignedTables })
        this.setState({ assignedTables })
    }

    handleAssigningTables = () => {
        console.log('handleAssigningTables')
        this.setState({ isAssigningTables: true })
    }

    handleAssigningTablesAction = saveAssigningTablesChanges => event => {
        console.log('handleAssigningTablesAction -> save assignedTables', saveAssigningTablesChanges, this.state.assignedTables)
        if (saveAssigningTablesChanges) {
            this.props.saveAssigningTablesChanges(this.state.assignedTables)
        }
        this.setState({ isAssigningTables: false })
    }

    render() {
        console.log('WaiterComponent -> render', this.props)
        const translate = this.translate
        const { classes, logoUrl, selectedTable, scannedData, menu, openOrders, waiterInfo,
            personId, displayOrder, showQRCodeScanner, feedbacks, waiterCreateOrders, accessToDirectOrders, allTables
        } = this.props

        if (menu === 'LOADING') {
            return (<CircularProgress className={classes.progress} color="secondary" />)
        }

        const { selectedRole, isAssigningTables } = this.state
        
        let assignedTables = {}
        if (this.state.assignedTables) {
            assignedTables = this.state.assignedTables
        } else if (waiterInfo && waiterInfo.hasAssignedTables) {
            assignedTables = waiterInfo.tables
        } 
        

        const tableOrders = openOrders && openOrders.filter(od => od.tableId === selectedTable)[0]
        const restaurantOrderId = (tableOrders && tableOrders.id) || null
        
        console.log({ personId, displayOrder, tableOrders })
        
        

        return (
            <div>
                <NavBarWaiterComponent 
                    logoUrl={logoUrl}
                    waiterInfo={waiterInfo}
                    waiterCreateOrders={waiterCreateOrders}
                    selectedRole={selectedRole}
                    handleQRCodeScanner={this.handleQRCodeScanner}
                    isAssigningTables={isAssigningTables}
                    handleAssigningTables={this.handleAssigningTables}
                    handleAssigningTablesAction={action => this.handleAssigningTablesAction(action)}
                    handleLogoutWaiter={this.handleLogoutWaiter}
                />
                
                <ReadOrderQR showQRCodeScanner={showQRCodeScanner} handleQRCodeData={qrCodeData => this.props.handleQRCodeData(qrCodeData)} close={this.handleQRCodeScanner} />


                {
                    !showQRCodeScanner && !isAssigningTables && waiterInfo && waiterInfo.roles 
                    && <UserRoleViewSelection 
                        translate={(text) => translate(text)}
                        roles={waiterInfo.roles} 
                        alocatedTables={waiterInfo.tables}
                        handleRoleSelection={role => this.handleRoleSelection(role)} 
                    />
                }

                {
                    !showQRCodeScanner && selectedRole === userRoles.DISPATCHER
                    && <DispatcherBinding />
                }

                {
                    !showQRCodeScanner && selectedRole === userRoles.WAITER && isAssigningTables
                    && <AssignedTables 
                        hasAssignedTables={waiterInfo.hasAssignedTables}
                        allTables={allTables} 
                        alocatedTables={assignedTables}
                        updateAssignedTablesStatus={assignedTables => this.updateAssignedTablesStatus(assignedTables)}
                    />
                }

                {
                    !showQRCodeScanner && !isAssigningTables && selectedRole === userRoles.WAITER
                    && <ListTables
                        alocatedTables={waiterInfo.tables}
                        scannedData={scannedData}
                        selectTable={({ restaurantOrderId, tableId }) => waiterCreateOrders 
                            ? this.props.selectTable({ restaurantOrderId, tableId })
                            : this.props.clearTableNotifications({ restaurantOrderId, tableId })
                        }
                        selectedTable={selectedTable}
                        openOrders={openOrders}
                        accessToDirectOrders={accessToDirectOrders}
                    />
                }



                {
                    selectedTable && 
                    <TableDetails 
                        {...this.props}
                        restaurantOrderId={restaurantOrderId}
                        showTable={selectedTable !== ''}
                        hideTableDetails={this.hideTableDetails}
                        orders={tableOrders}
                        addNewPersonAtTable={() => this.addNewPersonAtTable(restaurantOrderId)}
                        feedbacks={feedbacks}
                        waiterCreateOrders={waiterCreateOrders}
                    />
                }
               
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(WaiterComponent)
