import * as MODULE_CHOOSE_LANGUAGE from './choose_language/constants'
import * as MODULE_MENU from './restaurant_menu/constants'
import * as MODULE_WAITER from './waiter/constants'
import * as MODULE_SCANNER from './scan/constants'
import * as MODULE_DISPATCHER from './waiter/components/dispatcher/constants'
 
export const CHOOSE_LANGUAGE = MODULE_CHOOSE_LANGUAGE.MODULE_NAME;
export const MENU = MODULE_MENU.MODULE_NAME;
export const WAITER = MODULE_WAITER.MODULE_NAME;
export const SCANNER = MODULE_SCANNER.MODULE_NAME;
export const DISPATCHER = MODULE_DISPATCHER.MODULE_NAME;

