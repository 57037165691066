import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import amber from '@material-ui/core/colors/amber';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import AlertDialogView from './AlertDialogView';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  inputError: {
    color: 'red',
  },
  orangeBackground: {
    backgroundColor: amber[800],
  },
  tooltip: {
    fontSize: 12,
  },
}));

export default function TimePickers({
  handleTimeChange,
  translate,
  type = 'time',
  minDateTime,
  maxDateTime,
}) {
  const classes = useStyles();

  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  const [alertDialogData, setAlertDialogData] = useState({});
  const [time, setTime] = useState('');
  const [minDateString, setMinDateString] = useState();
  const [maxDateString, setMaxDateString] = useState();
  const [error, setError] = useState(false);
  const [tooltipText, setTooltipText] = useState('');
  const [timeoutId, setTimeoutId] = useState(null);


  const handleOpenAlertDialog = (alertDialogData) => {
    setIsAlertDialogOpen(true);
    setAlertDialogData(alertDialogData);
  };

  const handleAlertDialogClose = () => {
    setIsAlertDialogOpen(false);
    setAlertDialogData({});
  };

  const getSecondsFromTimeString = (timeString) => {
    if (type === 'datetime-local') {
      return Math.floor(new Date(timeString).getTime() / 1000);
    } 

    const currentDate = new Date();
    currentDate.setHours(timeString.split(':')[0]);
    currentDate.setMinutes(timeString.split(':')[1]);
    return Math.floor(currentDate.getTime() / 1000);
  }


  const formatDateTime = (date) => {
    if (type === 'datetime-local') {
      // Format date to "YYYY-MM-DDTHH:MM" without using toISOString()
      return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}T${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
    } else {
      // Format date to "HH:MM"
      return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
    }
  };

  const formatTooltipText = (dateString) => {
    // Format dateString from "YYYY-MM-DDTHH:MM" to "MMM dd, HH:MM"
    const date = new Date(dateString.replace('T', ' '));
    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.getDate();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${month} ${day}, ${hours}:${minutes}`;
  };

  const onTimeChange = (event) => {
    const newTime = event.target.value;
    let isValidTime = true;

    // Clear any existing timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    if (newTime < minDateString || (maxDateString && newTime > maxDateString)) {
      setError(true);
      
      let tooltipErrorText = '';
      if (maxDateString) {
        tooltipErrorText = `${formatTooltipText(minDateString)} - ${formatTooltipText(maxDateString)}`;
      }

      setTooltipText(tooltipErrorText);
      isValidTime = false;
    } else {
      setError(false);
    }

    setTime(newTime);

    // Set a new timeout
    const newTimeoutId = setTimeout(() => {
      // Call handleOpenAlertDialog if the time is valid
      if (isValidTime && newTime) {
        handleOpenAlertDialog({
          dialogContentText: `${translate('Confirm pickup time?')} ${newTime.replace('T', ' ')}`,
          textFieldLabel: '',
          dialogCancel: translate('Cancel'),
          handleDialogOk: () => {
            handleTimeChange(getSecondsFromTimeString(newTime));
          },
        });
      }
    }, 1000); // 1 second delay

    setTimeoutId(newTimeoutId);
  };

  const initializeDateTime = () => {
    const date = new Date();

    if (type === 'datetime-local' && minDateTime && maxDateTime) {
      const minDate = new Date(minDateTime * 1000);
      const maxDate = new Date(maxDateTime * 1000);

      minDate.setHours(minDate.getHours() - 1);
      const minDateString = formatDateTime(minDate);

      setMinDateString(minDateString);
      setMaxDateString(formatDateTime(maxDate));
      setTime(minDateString);

      setError(date < minDate || date > maxDate);
    } else {
      const minDate = new Date();
      setMinDateString(formatDateTime(minDate));

      date.setHours(date.getHours() + 1);
      setTime(formatDateTime(date));
    }
  };

  useEffect(() => {
    initializeDateTime();
  }, [maxDateTime, minDateTime, type]);

  return (
    <>
      <form className={classes.container} noValidate>
        <Tooltip
          title={tooltipText}
          classes={{ tooltip: classes.tooltip }}
          open={error}
          placement="top"
        >
          <TextField
            type={type}
            value={time}
            onChange={onTimeChange}
            className={`${classes.textField} ${isAlertDialogOpen ? classes.orangeBackground : ''}`}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: minDateString,
              max: maxDateString,
              className: error ? classes.inputError : null,
            }}
            error={error}
          />
        </Tooltip>
      </form>
      {isAlertDialogOpen && (
        <AlertDialogView dialogData={alertDialogData} handleAlertDialogClose={handleAlertDialogClose} />
      )}
    </>
  );
}
