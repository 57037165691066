import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { userRoles } from '../../../configs/constants'
import store from '../../../redux/store';
import { bindToOnlineOrders } from './dispatcher/actions';


const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

export default function UserRoleViewSelection(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [hideTabs, setHideTbas] = React.useState(false);

  const { translate, alocatedTables, roles, handleRoleSelection } = props;

  useEffect(() => {
    const getUserRolesToDisplay = () => {
      const activeRoles = roles.filter(role => {
          if (role === userRoles.WAITER && !Object.keys(alocatedTables).length) {
              return false
          }
          return true
      })
      if (activeRoles.length === 1) {
        setHideTbas(true)
      }

      if (activeRoles.includes(userRoles.DISPATCHER)) {
        store.dispatch(bindToOnlineOrders())
      }

      handleRoleSelection(activeRoles[0]);
    }

    getUserRolesToDisplay()
  }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue);
    handleRoleSelection(roles[newValue]);
  };

  

  if (hideTabs) {
    return null
  }

  return (
    <Paper className={classes.root}>
      <Tabs 
        indicatorColor="primary" textColor="primary" variant="fullWidth"
        value={value} 
        onChange={handleChange} 
      >
        {
          roles.map(role => <Tab key={role} label={translate(role)} />)
        }
      </Tabs>
    </Paper>
  );
}
