export default `
    Menu                    = Меню
    Welcome                 = Желанный
    Price                   = Цена
    Add                     = Добавлять
    Remove                  = Удаление
    Order list              = Список заказов
    Order                   = Заказ
    Place order             = Сделать заказ
    Tables                  = Таблицы
    Pay                     = Платить
    Recommended             = Рекомендуемый
    Total                   = Общий
`

