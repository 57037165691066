import keys from 'lodash/keys'
import orderBy from 'lodash/orderBy'
import groupBy from 'lodash/groupBy'
import omit from 'lodash/omit'
import axios from 'axios';

import * as constants from './constants'
import { UPDATE_RESTAURANT_INFO } from '../appActions'
import { getLastPrice } from '../../util/restaurant'
import { orderStates, productStates, tableIs, waiterServices, URL_NOTIFY_WAITER, STAND_MENU, URL_SEND_EMAILS, FEEDBACK_TYPE } from '../../configs/constants'
import translate from './../../util/translate'
import { smallImageURL } from './../../util/smallImageURL'
import { isCategoryAvailableNow } from './../../util/available'


export const MENU = `${constants.MODULE_NAME}/MENU`
export const ORDER = `${constants.MODULE_NAME}/ORDER`
export const SET_CURRENT_ORDER_FROM_DB = `${constants.MODULE_NAME}/SET_CURRENT_ORDER_FROM_DB`
export const CALL_WAITER = `${constants.MODULE_NAME}/CALL_WAITER`
export const SEND_ORDER_TO_WAITER = `${constants.MODULE_NAME}/SEND_ORDER_TO_WAITER`
export const NOTIFICATION_SENT_TO_WAITER = `${constants.MODULE_NAME}/NOTIFICATION_SENT_TO_WAITER`
export const RESET_ORDER_TABLE_CLOSED = `${constants.MODULE_NAME}/RESET_ORDER_TABLE_CLOSED`
export const DELETE_ORDER = `${constants.MODULE_NAME}/DELETE_ORDER`
export const PAY_WITH = `${constants.MODULE_NAME}/PAY_WITH`
export const ORDER_PAID = `${constants.MODULE_NAME}/ORDER_PAID`

let db
let currency
let restaurantOrdersId
let listenerOnOrderUpdate = null



const applySortOrder = (items, sortOrder) => {
    if (!(sortOrder && sortOrder.length)) {
        return items;
    }

    return items.slice()
        .sort((a, b) => sortOrder.indexOf(a.id) - sortOrder.indexOf(b.id))
}
  
export const getMenu = () => async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({
        type: MENU,
        payload: 'LOADING'
    })
    const restaurantId = getState().SCANNER['restaurantId']
    if (!restaurantId) {
        return
    }

    currency = getState().RESTAURANT.INFO.defaultCurrency.toUpperCase()

    db = getFirestore()

    let docRef = db.collection("restaurants").doc(restaurantId)
    docRef.get().then(function (doc) {
        console.log('*** firestore', { doc })
        if (doc.exists) {
            const restaurant = doc.data()
            console.log('*** firestore -> Restaurant data:', { restaurant })
            if (restaurant.active) {
                const refCategories = db.collection(`categories`).where('restaurantId', '==', restaurantId)
                console.log('*** firestore -> refCategories', { refCategories })
                refCategories.onSnapshot({ includeMetadataChanges: true }, snapshotCategorii => {
                    const categories = applySortOrder(snapshotCategorii.docs
                        .filter(doc => doc.data().available || (!doc.data().available && doc.data().availabilities !== undefined && doc.data().availabilities.length > 0) )
                        .map(doc => {
                            const thumbImg = smallImageURL(doc.data().imageSrc, "thumb@256_");
                            return ({ id: doc.id, ...doc.data(), thumbImg })
                        })
                        , restaurant.categoriesOrder)

                    console.log('ordered', { categories })
                    const categoryIds = categories.map(categ => categ.id)


                    const refProducts = db.collection(`products`).where('restaurantId', '==', restaurantId)
                    console.log('*** firestore -> refProducts', { refProducts })


                    refProducts.onSnapshot({ includeMetadataChanges: true }, snapshotProducts => {
                        const productsGroupedByCategory = groupBy(
                            snapshotProducts.docs
                                .filter(doc => categoryIds.includes(doc.data().categoryId))
                                .map(doc => {
                                    const thumbImg = smallImageURL(doc.data().imageSrc, "thumb@256_");
                                    return ({ id: doc.id, ...doc.data(), thumbImg })
                                })
                            , 'categoryId')
                        
                        let products = {}
                        Object.keys(productsGroupedByCategory).forEach(categoryId => {
                            const { productsOrder = null} = categories.find(category => category.id === categoryId)
                            console.log({productsOrder})
                            products = {
                                ...products, 
                                [categoryId]: applySortOrder(productsGroupedByCategory[categoryId], productsOrder) 
                            }
                        })

                        console.log('*** firestore', { categories })
                        console.log('*** firestore', { products })

                        const menu = categories.map(category => {
                            return ({
                                details: { ...category },
                                items: products[category.id] === undefined ? [] : [...products[category.id]]
                            })
                        }).filter(m => m.items.length > 0)

                        console.log('*** firestore', { menu })

                        const recommendedProducts = orderBy(snapshotProducts.docs
                            .filter(doc => categoryIds.includes(doc.data().categoryId))
                            .filter(doc => {
                                const isThisCategoryAvailable = isCategoryAvailableNow(categories.find(({ id }) => id === doc.data().categoryId)?.availabilities)
                                return isThisCategoryAvailable && doc.data().recommended && doc.data().available
                            })
                            .map(doc => {
                                const thumbImg = smallImageURL(doc.data().imageSrc, "thumb@256_");
                                return ({ 
                                    id: doc.id, 
                                    categoryPosition: categories.filter(c => c.id === doc.data().categoryId)[0].position, 
                                    ...doc.data(),
                                    thumbImg
                                })
                            })
                            , 'categoryPosition')



                        if (recommendedProducts.length > 0) {
                            const recommended = {
                                details: {
                                    id: 'recommended',
                                    name: ''
                                },
                                items: [...recommendedProducts]
                            }
                            return dispatch({
                                type: MENU,
                                payload: [{ ...recommended }, ...menu]

                            })
                        }

                        return dispatch({
                            type: MENU,
                            payload: [...menu]

                        })
                    })
                })


            } else {
                console.log('*** firestore Restaurant not available')
            }
        } else {
            console.log('*** firestore Restaurant not exists in our db!')
        }
    }).catch(function (error) {
        console.log('*** firestore -> Error getting document:', error)
    })
}




const processOrderData = (refCurrentOrder, uid) => dispatch => {

    console.log('@processOrderData -> refCurrentOrder', refCurrentOrder.data())

    if (!refCurrentOrder.data() || refCurrentOrder.data().status === tableIs.CLOSED) {
        return dispatch(unsubscribeForOrderUpdates())
    }
    
    const personCurrentOrder = ((refCurrentOrder.data() || {}).persons || []).filter(person => person.id === uid)[0]
    
    const isPersonIdBlocked = refCurrentOrder.data() && (refCurrentOrder.data().blockedPersonsIds || {})[uid]
    if (isPersonIdBlocked) {
        return dispatch({
            type: UPDATE_RESTAURANT_INFO,
            payload: { accessToDirectOrders: false }
        })
    }

    console.log('@processOrderData -> personCurrentOrder', personCurrentOrder)
    const currentOrder = personCurrentOrder === undefined
        ? ([{ meta: { id: uid, }, order: {} }])
        : (personCurrentOrder.orders || []).map(order => ({
            meta: {
                id: personCurrentOrder.id,
                status: order.status,
                timestamp: order.timestamp.toMillis(),
            },
            order: { ...omit(order, ['id', 'status', 'timestamp']) }
        }))

    dispatch({
        type: 'SET_CURRENT_ORDER_FROM_DB_v2',
        payload: currentOrder,
        paid: personCurrentOrder && (personCurrentOrder.hasToPay && personCurrentOrder.paid && (parseFloat(personCurrentOrder.hasToPay) <= (parseFloat(personCurrentOrder.paid) + parseFloat(personCurrentOrder.moneyOnTable || 0)))),
        multiplePersons: ((refCurrentOrder.data() || {}).persons || []).length > 1
    })
}



const unsubscribeForOrderUpdates = () => dispatch => {
    console.log('@unsubscribeForOrderUpdates')
    
    dispatch({ type: RESET_ORDER_TABLE_CLOSED })
    
    listenerOnOrderUpdate = null
    restaurantOrdersId = null
}

const registerForOrderUpdates = ({ restaurantOrdersId, uid }) => (dispatch, getState) => {
    const restaurantId = getState().SCANNER['restaurantId']
    console.log('@registerForOrderUpdates', { restaurantId, restaurantOrdersId, uid })
    listenerOnOrderUpdate = db.collection(`restaurants/${restaurantId}/horecaOrders`).doc(restaurantOrdersId)
        .onSnapshot(snapshot => {
            console.log('Current ORDER changed:', snapshot)
            dispatch(processOrderData(snapshot, uid))
        })
}



export const getCurrentOrder = () => async (dispatch, getState) => {
    const restaurantId = getState().SCANNER['restaurantId']
    const tableId = getState().SCANNER['tableId']
    const uid = getState().RESTAURANT['UID_FINGERPRINT']

    console.log('getCurrentOrder ->', { restaurantId, tableId, uid })
    
    const query = db.collection(`restaurants/${restaurantId}/horecaOrders`)
        .where('status', '==', tableIs.OPEN)
        .where('tableId', '==', tableId)
        .limit(1)

    const queryResults = await query.get()

    console.log('@getCurrentOrder -> queryResults', queryResults.docs)
    if (queryResults.docs[0] !== undefined) {
        const _restaurantOrdersId = queryResults.docs[0].id
        dispatch(registerForOrderUpdates({ restaurantOrdersId: _restaurantOrdersId, uid }))
    }
}


export const handleOrderChange = (newOrder) => (dispatch, getState) => {
    dispatch({
        type: ORDER,
        payload: newOrder
    })
}



export const callWaiterFor = ({ serviceType, hasToPay, extra }) => async (dispatch, getState, { getFirebase, getFirestore }) => {
    console.log('@actions -> callWaiterFor', serviceType, getState())

    const restaurantId = getState().SCANNER.restaurantId
    const tableId = getState().SCANNER.tableId
    const uid = getState().RESTAURANT['UID_FINGERPRINT']
    const timestamp = new Date()

    let orderIdReponse
    if (!restaurantOrdersId) {
        orderIdReponse = await dispatch(getOrderId({ restaurantId, tableId }))
        restaurantOrdersId = orderIdReponse.restaurantOrdersId
    }
    console.log('restaurantOrdersId', restaurantOrdersId)

    if (serviceType !== waiterServices.ORDER) {
        if (serviceType === waiterServices.PAY) {
            dispatch({ type: PAY_WITH, payload: extra.payWith })
        }
        if (extra !== undefined) {
            return dispatch(notifyWaiter({ uid, serviceType: serviceType, extra: { ...extra, personId: uid } }))
        }

        
        return dispatch(notifyWaiter({ uid, serviceType: serviceType }))
    }


    if (serviceType === waiterServices.ORDER) {
        const ALL_ORDERS = getState().MENU[ORDER]
        let NEW_ORDER = { ...ALL_ORDERS[ALL_ORDERS.length - 1].order }
        const categs_NEW_ORDER = keys(NEW_ORDER)

        for (const categ of categs_NEW_ORDER) {
            for (const product of keys(NEW_ORDER[categ])) {
                const price1 = getLastPrice(getState().MENU["MENU/MENU"].filter(m => m.details.id === categ)[0].items.filter(p => p.id === product)[0])
                NEW_ORDER = {
                    ...NEW_ORDER,
                    [categ]: {
                        ...NEW_ORDER[categ],
                        [product]: {
                            ...NEW_ORDER[categ][product],
                            price1,
                            area: 'KITCHEN',
                            status: productStates.PENDING
                        }
                    }
                }
            }
        }

        dispatch(addNewOrder({ restaurantOrdersId, uid, newOrder: { id: Date.now(), timestamp, status: orderStates.ORDER_UNVERIFIED, ...NEW_ORDER }, hasToPay }))

        return dispatch({
            type: SEND_ORDER_TO_WAITER,
        })
    }

}


const getOrderId = ({ restaurantId, tableId }) => async (dispatch, getState) => {
    if (restaurantId === undefined) {
        restaurantId = getState().SCANNER['restaurantId']
    }
    if (tableId === undefined) {
        tableId = getState().SCANNER['tableId']
    }

    const uid = getState().RESTAURANT['UID_FINGERPRINT']

    console.log('getOrderId', { restaurantId, tableId, uid })
    const query = await db.collection(`restaurants/${restaurantId}/horecaOrders`)
        .where('restaurantId', '==', restaurantId)
        .where('status', '==', tableIs.OPEN)
        .where('tableId', '==', tableId)
        .limit(1)
        .get()

        const currentOrder = query.docs[0]
    
    if (currentOrder === undefined) {
        let newOrder = {
            restaurantId,
            waiter: ``,
            tableId: `${tableId}`,
            total: 0,
            currency,
            timestamp: new Date(),
            status: tableIs.OPEN
        }
        console.log('getOrderId addNewOrder ->', { newOrder })
        const order = await db.collection(`restaurants/${restaurantId}/horecaOrders`).add(newOrder)
        console.log('addNewOrder response ->', { order })
        return { restaurantOrdersId: order.id, isNewId: true }
    } 
        
    restaurantOrdersId = currentOrder.id
    dispatch(registerForOrderUpdates({ restaurantOrdersId, uid }))

    return { restaurantOrdersId }
}



const addNewOrder = ({ restaurantOrdersId, uid, newOrder, hasToPay }) => async (dispatch, getState) => {
    const restaurantId = getState().SCANNER['restaurantId']
    console.log('addNewOrder', { restaurantId, restaurantOrdersId, uid, newOrder, hasToPay })

    if (listenerOnOrderUpdate === null) {
        dispatch(registerForOrderUpdates({ restaurantOrdersId, uid }))
    }

    if (uid) {
        const table = db.collection(`restaurants/${restaurantId}/horecaOrders`).doc(restaurantOrdersId)
        const tableData = await table.get()
        let total = 0
        if (tableData.exists) {
            total = parseFloat(tableData.data().total) || 0
        }

        if (!restaurantOrdersId) {
            restaurantOrdersId = tableData.data().id
            console.log('@addNewOrder restaurantOrdersId - NEW ORDER - ', restaurantOrdersId)
        }

        dispatch(notifyWaiter({ uid, serviceType: waiterServices.ORDER }))


        const { persons = [] } = tableData.exists ? tableData.data() : {}

        const personAtTable = persons.filter(p => p.id === uid)[0]
        let newDataPersons = []
        let hadToPay = 0

        if (personAtTable) {
            newDataPersons = persons.map(p => {
                if (p.id === uid) {
                    const hasUnverifiedOrder = p.orders.filter(po => po.status === orderStates.ORDER_UNVERIFIED)[0]
                    hadToPay = p.hasToPay
                    if (hasUnverifiedOrder) {
                        
                        return ({
                            ...p,
                            orders: p.orders.map(po => {
                                if (po.status === orderStates.ORDER_UNVERIFIED) {

                                    const categories = keys(omit(po, ['id', 'status', 'timestamp']))

                                    let updatedOrder = { ...po }

                                    for (const category of categories) {
                                        const products = keys(po[category])

                                        for (const product of products) {
                                            if (newOrder[category] && newOrder[category][product]) {
                                                updatedOrder = {
                                                    ...updatedOrder,
                                                    [category]: {
                                                        ...updatedOrder[category],
                                                        [product]: { 
                                                            ...newOrder[category][product], 
                                                            quantity: (newOrder[category][product].quantity + po[category][product].quantity)
                                                        }
                                                    }
                                                }
                                                delete newOrder[category][product]
                                            }
                                        }

                                        const productsFromNewOrder = keys(newOrder[category])
                                        
                                        if (productsFromNewOrder.length > 0) {
                                            updatedOrder = {
                                                ...updatedOrder,
                                                [category]: {
                                                    ...updatedOrder[category],
                                                    ...newOrder[category]
                                                }
                                            }
                                        } 
                                        delete newOrder[category]
                                    }

                                    const otherCategoriesProductsFromNewOrder = keys(omit(newOrder, ['id', 'status', 'timestamp']))
                                    if (otherCategoriesProductsFromNewOrder.length > 0) {
                                        updatedOrder = {
                                            ...updatedOrder,
                                            ...newOrder
                                        }
                                    }
                                    
                                    return ({ ...updatedOrder })
                                }
                                
                                return po
                            }),
                        })
                    }

                    return ({
                        ...p,
                        orders: [...p.orders, { ...newOrder }],
                    })
                }
                return p
            })
        } else {
            newDataPersons = [...persons, { id: uid, orders: [{ ...newOrder }] }]
        }

        console.log({ total, hadToPay, hasToPay, newDataPersons })
        
        table.update({ persons: newDataPersons })

    }

}

export const deleteOrder = params => async (dispatch, getState) => {
    const { orderStatus } = params
    console.log('action -> deleteOrder', orderStatus )
    
    if (orderStatus === orderStates.ORDER_NOT_SENT) {
        return dispatch({ type: DELETE_ORDER })
    }

    const restaurantId = getState().SCANNER['restaurantId']
    const uid = getState().RESTAURANT['UID_FINGERPRINT']
    const tableId = getState().SCANNER['tableId']

    if (!restaurantOrdersId) {
        restaurantOrdersId = (await dispatch(getOrderId({ restaurantId, tableId }))).restaurantOrdersId
    }

    console.log('action -> deleteOrder', { restaurantId, tableId, uid, restaurantOrdersId })
    const tableOrdersRef = await db.collection(`restaurants/${restaurantId}/horecaOrders`).doc(restaurantOrdersId)
    const tableOrdersData = await db.collection(`restaurants/${restaurantId}/horecaOrders`).doc(restaurantOrdersId).get()

    
    
    let newDataPersons = []
    if (tableOrdersData.exists) {
        const { persons = [] } = tableOrdersData.data()
        for (const person of persons) {
            if (person.id !== uid) { 
                newDataPersons = [ ...newDataPersons, person ] 
            } else {
                newDataPersons = [ 
                    ...newDataPersons, 
                    { 
                        ...person, 
                        orders: person.orders.filter(order => order.status !== orderStates.ORDER_UNVERIFIED) 
                    } 
                ]
            }
        }
        return tableOrdersRef.update({ persons: newDataPersons })
    }
}

const notifyWaiter = ({ uid, serviceType, extra }) => async (dispatch, getState) => {

    const language = getState().RESTAURANT.INFO.language
    const restaurantId = getState().SCANNER.restaurantId
    const tableId = getState().SCANNER.tableId
    
    console.log('notifyWaiter', { restaurantId, tableId, restaurantOrdersId, uid, serviceType })
    if (restaurantOrdersId && uid) {
        const title = tableId === STAND_MENU
            ? `${translate(STAND_MENU, language)}`
            : `${translate('Table', language)} ${tableId}`
        const bodyMessage = `${translate(serviceType.replace('/_/g', ' '), language)}`
        axios.post(URL_NOTIFY_WAITER, { restaurantId, tableId, title, bodyMessage, serviceType: serviceType.replace('/_/g', ' '), extra })

        const tableOrdersRef = await db.collection(`restaurants/${restaurantId}/horecaOrders`).doc(restaurantOrdersId)
        const tableOrdersData = await db.collection(`restaurants/${restaurantId}/horecaOrders`).doc(restaurantOrdersId).get()
        console.log('tableOrdersData response', { tableOrdersData })

        if (!tableOrdersData.exists) {
            await dispatch(unsubscribeForOrderUpdates())
            restaurantOrdersId = undefined
            return dispatch(callWaiterFor({ serviceType }))
        }

        if (extra) {
            let found = false
            const dbExtras = tableOrdersData.data()[`${serviceType}-extra`] && (tableOrdersData.data()[`${serviceType}-extra`] || []).map(e => {
                if (e.personId === extra.personId) {
                    found = true
                    return { ...e, ...extra }
                } else return e
                
            })
            const newExtras = found ? [ ...dbExtras ] : [ ...dbExtras, extra ]
            tableOrdersRef.update({ [serviceType]: Date.now(), [`${serviceType}-GCM`]: true, [`${serviceType}-extra`]: newExtras })
        } else {
            tableOrdersRef.update({ [serviceType]: Date.now(), [`${serviceType}-GCM`]: true })
        }
    }

    
    let message = ''
    switch (serviceType) {
        case waiterServices.INFO:
        case waiterServices.WANT_TO_ORDER:
            message = translate('Soon a waiter will come at you service', language)
            break
        
        case waiterServices.ORDER:
            message = translate('Your order request is sent', language)
            break

        case waiterServices.PAY:
        case waiterServices.PAY_CASH:
        case waiterServices.PAY_CARD:
            message = translate('Your payment request is sent', language)
            break
        
        case waiterServices.FEEDBACK:
            message = translate('Thank you for your feedback', language)
            break
        default:
            break;
    }
    dispatch({
        type: NOTIFICATION_SENT_TO_WAITER,
        payload: { open: Date.now(), message }
    })
}


const addNewPerssonWithEmptyOrder = (uid) => async (dispatch, getState) => {
    const restaurantId = getState().SCANNER['restaurantId']
    const tableOrdersRef = await db.collection(`restaurants/${restaurantId}/horecaOrders`).doc(restaurantOrdersId)
    const tableOrdersData = await tableOrdersRef.get()
    
    console.log('addNewPerssonWithEmptyOrder ->', { uid, tableOrdersData })

    if (listenerOnOrderUpdate === null) {
        dispatch(registerForOrderUpdates({ restaurantOrdersId, uid }))
    }
}

export const postFeedback = (feedbackData) => async (dispatch, getState) => {
    
    const { feedbackForm } = feedbackData

    const restaurantId = getState().SCANNER['restaurantId']
    const name = getState().RESTAURANT.INFO.name
    const language = getState().RESTAURANT.INFO.language
    const uid = getState().RESTAURANT['UID_FINGERPRINT']
    const tableId = getState().SCANNER['tableId']
    
    const { listOfFeedbackEmails } = getState().RESTAURANT.INFO.feedback

    if (!restaurantOrdersId) {
        const orderIdReponse = await dispatch(getOrderId({ restaurantId, tableId }))
        restaurantOrdersId = orderIdReponse.restaurantOrdersId
    
        if (orderIdReponse.isNewId) {
            dispatch(addNewPerssonWithEmptyOrder(uid))
        }
    }

    console.log('postFeedback', { restaurantId, uid, tableId, feedbackForm })

    

    const feedbackRef = db.collection(`restaurants/${restaurantId}/feedbacks`)

    const response = await feedbackRef.add({ 
        READ_ADMIN: false,
        READ_WAITER: false,
        timestamp: new Date(),
        feedbackForm, 
        tableId,
        restaurantOrdersId,
        personId: uid
    })

    console.log('response', response.id)

    
    if (Array.isArray(listOfFeedbackEmails) && !!listOfFeedbackEmails.length) {
        const content = generateFeedbackEmailBody({ tableId, feedbackForm, name, language })

        sendEmail({
            from : { Email: `feedbacks@orderix.ro`, Name: `Orderix.ro` },
            to: listOfFeedbackEmails.map(Email => ({ Email, Name: '' })),
            subject: `Feedback ${name} - ${tableId}`,
            content
        })
    }

    dispatch(callWaiterFor({ serviceType: waiterServices.FEEDBACK }))
}


const generateFeedbackEmailBody = params => {
    const { tableId, feedbackForm, name, language } = params
    
    let content = `<div><h2>${name} - ${tableId}</h2>`
    feedbackForm.forEach(question => {
        const { answer, type } = question
        
        let _answer = ''
        switch (type) {
            case FEEDBACK_TYPE.RATING:
                for (let i=0; i<(answer || 3); i++) {
                    _answer = _answer + `<span style="font-size:20px; align-self: center;">&#11088;</span>`
                }
                break
            case FEEDBACK_TYPE.TEXT:
                _answer = `<div style="color: #555; font-size:16px; white-space: pre-wrap;">${answer ? answer : '-'}</div>`
                break
            default:
                break
        }

        content = content + `
            <div style="padding: 8px 20px;">
                <div style="color: #333; font-size:16px; font-weight: bold; margin-right: 10px;">${question[language]}</div>
                ${_answer}
            </div>
        `
    })
    content = content + `</div>`

    return content
}

const sendEmail = (params) => {
    const { to, from, subject, content } = params
    axios.post(URL_SEND_EMAILS, { 
        to, from, subject, content
    })
}