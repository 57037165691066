import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import SelectTablesIcon from "@material-ui/icons/ViewModuleSharp";
import Button from '@material-ui/core/Button';
import { userRoles } from '../../../configs/constants'
import QRCodeScannerIcon from '@material-ui/icons/Fullscreen'

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1
  },
  toolbar: {
    flex: '1',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    background: '#fff',
    color: 'rgba(0,0,0, .5)'
  },
  logo: {
      height: '40px',
      flex: 1,
      objectFit: 'contain',
      objectPosition: 'left',
      verticalAlign: 'middle',
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  menu: {
    '& .MuiPaper-root': {
      color: 'rgba(0,0,0, .5)'
    }
  }
}));

export default function PrimarySearchAppBar(props) {
  const classes = useStyles();
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(menuAnchorEl);

  const { 
    logoUrl, waiterInfo, waiterCreateOrders, selectedRole, isAssigningTables,
    handleQRCodeScanner, handleAssigningTables, handleAssigningTablesAction, handleLogoutWaiter 
  } = props


  if (!waiterInfo) {
    return null
  }

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const renderMenu = (
    <Menu
      className={classes.menu}
      anchorEl={menuAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {selectedRole === userRoles.WAITER &&
        <MenuItem onClick={() => {
          handleAssigningTables()
          handleMenuClose()
        }}>
          <IconButton>
            <SelectTablesIcon />
          </IconButton>
          <p>Assigning tables</p>
        </MenuItem>
      }

      <MenuItem onClick={handleLogoutWaiter}>
        <IconButton>
          <AccountCircle />
        </IconButton>
        <p>Log out</p>
      </MenuItem>
    </Menu>
  );



  const getToolbarRightActions = isAssigningTables => {
    if (isAssigningTables) {
      return (
        <div>
          <Button aria-haspopup="true" onClick={handleAssigningTablesAction(false)} color="inherit">
            <Typography>Cancel</Typography>
          </Button>
          <Button aria-haspopup="true" onClick={handleAssigningTablesAction(true)} color="inherit">
            <Typography>OK</Typography>
          </Button>
        </div>
      )
    }

    return (
      <div>
        {
          (waiterCreateOrders && selectedRole === userRoles.WAITER) &&
          <IconButton onClick={handleQRCodeScanner} color="inherit">
            <QRCodeScannerIcon />
          </IconButton>
        }
        
        <Button aria-haspopup="true" onClick={handleMenuOpen} color="inherit">
          <Typography>{waiterInfo.name}</Typography>
        </Button>
      </div>
    )
  }
   
  const toolbarRightActions = getToolbarRightActions(isAssigningTables)

  return (
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar className={classes.toolbar}>
          <img className={classes.logo} src={logoUrl} alt="" />
          {toolbarRightActions}
        </Toolbar>
      </AppBar>
      {renderMenu}
    </div>
  );
}
