export default `
    Menu                    = Menu
    Welcome                 = Bienvenue
    Price                   = Prix
    Add                     = Ajouter
    Remove                  = Supprimer
    Order list              = Liste de commande
    Order                   = Ordre
    Place order             = Passer la commande
    Tables                  = Tableaux
    Pay                     = Payer
    Recommended             = Recommandé
    Total                   = Total
`

