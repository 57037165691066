import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './rootReducer'
import { reduxFirestore, getFirestore } from 'redux-firestore'
import { reactReduxFirebase, getFirebase } from 'react-redux-firebase'
import fbConfig from '../configs/fbConfig'

let store = null;
let middlewares = [ thunk.withExtraArgument({ getFirebase, getFirestore }) ]

if (process.env.NODE_ENV === 'development') {
  const { logger } = require(`redux-logger`)
  middlewares = [ ...middlewares, logger ];
}

store = createStore(
  rootReducer,
  {},
  compose(applyMiddleware(...middlewares), reduxFirestore(fbConfig), reactReduxFirebase(fbConfig))
);
export default store;
