import React from 'react';
import ReactDOM from 'react-dom';
import './assets/fonts.css'
import './index.css';
import './fixCamera.js'
import App from './App';
import * as serviceWorker from './serviceWorker';

const DEBUG = process.env.NODE_ENV !== 'production';

if(!DEBUG){
    if(!window.console) {
      window.console = {};
    }
    const methods = ["log", "debug", "warn", "info"];
    for(let i=0;i<methods.length;i++){
        console[methods[i]] = function(){};
    }
}


window.oncontextmenu = (e) => {
    e.preventDefault();
  }

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.register({
  onUpdate: registration => {
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
    window.location.reload();
  }
});
