import * as actions from './appActions'


const INITIAL_STATE = {
}


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        
        case actions.RESTAURANT_INFO:
        case actions.RESTAURANT_ID: 
        case actions.WAITER_ID:
        case actions.UID_FINGERPRINT:
        {
            return {
                ...state,
                [action.type]: action.payload
            }
        }
        

        case actions.SIGNED_IN_SUCCESFULL:
        {
            return {
                ...state,
                [action.type]: { ...action.payload },
                [actions.SIGNED_IN_ERROR]: undefined
            }
        }

        case actions.SIGNED_IN_ERROR:
        {
            return {
                ...state,
                [action.type]: action.payload,
                [actions.SIGNED_IN_SUCCESFULL]: undefined
            }
        }
        
        
        case actions.RESET_RESTAURANT_INFO: 
        {
            return INITIAL_STATE
        }
        
        case actions.UPDATE_RESTAURANT_INFO:
        {
            return {
                ...state,
                [actions.RESTAURANT_INFO]: { ...state[actions.RESTAURANT_INFO], ...action.payload }
            }
        }


        default:
            return state
    }
}
