import { connect } from 'react-redux'
import DispatcherView from './../components/DispatcherView'

import * as actions from './../actions'
import * as constants from './../constants'


const mapStateToProps = state => {
    console.log('DispatcherView bindings state', state)
    return ({
        onlineOrders: state[constants.MODULE_NAME][actions.ONLINE_ORDERS],
        language: state.RESTAURANT.INFO === undefined ? null : state.RESTAURANT.INFO.language,
    })
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    setOrderState: ({ orderDocId, newOrderState }) => dispatch(actions.setOrderState({ orderDocId, newOrderState })),
})

export default connect(mapStateToProps, mapDispatchToProps)(DispatcherView) 

