import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function FormDialog({ handleDialogClose, dialogData }) {
  const {
    dialogTitle, dialogContentText, textFieldLabel, dialogCancel, dialogOk
  } = dialogData

  const [values, setValues] = useState({
    obs: ''
  });


  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleButtonOk = () => {
    if (!values.obs.trim().length) {
      handleDialogClose()
    }
    handleDialogClose(values.obs)
  }

  return (
    <Dialog 
      open={true} 
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        {dialogContentText && <DialogContentText>
          {dialogContentText}
        </DialogContentText>
        }
        <TextField autoFocus margin="dense"
          id="obs"
          label={textFieldLabel}
          type="text"
          fullWidth
          multiline
          value={values.obs}
          onChange={handleChange('obs')}
        />
      </DialogContent>
      <DialogActions>
        {dialogCancel && <Button onClick={handleDialogClose} color="primary">
          {dialogCancel}
        </Button>}
        <Button disabled={!values.obs.trim().length} onClick={handleButtonOk} color="primary">
          {dialogOk}
        </Button>
      </DialogActions>
    </Dialog>
  );
}