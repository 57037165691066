const generateAreasAndTables = tablesConfig => {
  let tables = {}
  
  if (!tablesConfig) {
    return tables
  }

  tablesConfig.forEach(({ uuid, areaName, tableNumbersFrom=1, tablesNo }) => {
    const initialTables = (tables[areaName] && ([...tables[areaName]])) || []
    tables = { ...tables, [areaName]: [ ...initialTables, ...generateTables(areaName, tableNumbersFrom, tablesNo) ] } 
  })
  return tables
}

const generateTables = (areaName, tableNumbersFrom, tablesNo) => {
  let _tables = []
  for (let tableNumber=tableNumbersFrom; tableNumber<(tableNumbersFrom+tablesNo); tableNumber++) {
    _tables = [ ..._tables, `${areaName}:${tableNumber}` ]
  }
  return _tables
}

const sortAreaNames = tables => {
  let sortedAreas = {}
  Object.keys(tables).forEach(areaName => {
    sortedAreas = { ...sortedAreas, [areaName]: tables[areaName] }
  }) 
  return sortedAreas
}



export const getTables = ({ tablesConfig, assignedTables }) => {
  let tables = {}

  if (!tablesConfig || tablesConfig.length === 0) {
    return {}
  }


  if (!assignedTables || assignedTables.length === 0) {
    tables = generateAreasAndTables(tablesConfig)
    return sortAreaNames(tables)
  }

  assignedTables.split(',').forEach(assignedTable => {
    const [areaUuid, tableNumber] = assignedTable.split('--')
    const { areaName } = tablesConfig.find(tables => tables.uuid === areaUuid)
    tables = 
      tables[areaName] 
        ? { ...tables, [areaName]: [ ...tables[areaName], `${areaName}:${tableNumber}` ]  }
        : { ...tables, [areaName]: [ `${areaName}:${tableNumber}` ]  }
  });
  return sortAreaNames(tables)
}


export const getInfoFrom = tableId => {
  const [areaName, tableNumber] = tableId.split(':')
  return { areaName, tableNumber }
}

//test if QR code scanned has a valid table - area and number
export const isTableIdValid = (tablesConfig, tableId) => {
  const [areaName] = tableId.split(':')
  const restaurantTables = generateAreasAndTables(tablesConfig)
  return restaurantTables[areaName] && restaurantTables[areaName].find(table => table === tableId)
}


export const adaptorAssignedTables = ({ assignedTables, tablesConfig }) => {
    let _assignedTables = []
    let areaNamesAssigned = Object.keys(assignedTables)

    const orderTables = tables => {

      return tables.sort((a,b) => {
        const nrA = parseInt(a.split(':')[1])
        const nrB = parseInt(b.split(':')[1])

        if (nrA > nrB)
          return 1
        if (nrA < nrB)
          return -1

        return 0;
      })
    }


    areaNamesAssigned.forEach(areaName => {
        const { uuid } = (tablesConfig.find(tableData => tableData.areaName === areaName) || {})
        _assignedTables = [..._assignedTables, ...orderTables(assignedTables[areaName])
          .map(table => `${uuid}--${table.split(':')[1]}`)]
    })

    return _assignedTables.toString()
}