import uniqueId from 'lodash/uniqueId'
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import translateAction from '../../../util/translate'
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import teal from '@material-ui/core/colors/teal';
import getWeekDays from '../../../util/weekdays'
import { orderStates } from '../../../configs/constants'
import { LazyLoadImage } from 'react-lazy-load-image-component';

import placeholderImg from '../../../assets/images/placeholder_200.png'


const fixDecimals = amount => {
    let value = `${amount}`.split('.')[0]
    let decimals = `${amount}`.split('.')[1]
    if (value === '0' && decimals !== undefined) {
        decimals = (decimals + '00').substr(0, 3)
    } else {
        if (decimals === undefined) {
            decimals = '00'
        } else {
            decimals = `${decimals}0`.substr(0, 2)
        }
    }
    if (decimals === '00') {
        return `${value}`
    }
    return `${value}.${decimals}`
}


const styles = theme => ({
    root: {

    },
    card: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        padding: '0px !important',
        margin: '10px 0px'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    recommended: {
        color: 'pink',
        fontFamily: `Oswald', sans-serif`,
        position: 'absolute',
        top: '13px',
        left: '2px',
        transform: 'rotate(-20deg)',
        fontSize: 'larger',
    },
    contentText: {
        fontFamily: `'Oswald', sans-serif`,
        color: '#555',
        textAlign: 'center',
        fontSize: '14px',
        lineHeight: 1.15,
    },
    biggerPrice: {
        textDecoration: 'line-through',
        fontSize: '12px',
        marginTop: 2,
        opacity: .65,
        paddingRight: 6,
        fontWeight: 'bold',
        lineHeight: 2,
    },
    price: {
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: 1.75,
    },
    bestPrice: {
    },
    contentPay: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    pay: {
        fontFamily: `'Oswald', sans-serif`,
        color: '#999999',
        fontSize: 'small',
    },
    orderedPortions: {
        fontFamily: `'Oswald', sans-serif`,
        fontSize: 30,
        fontWeight: 'bold',
        color: '#F1204C',
        lineHeight: 'initial'
    },
    btn: {
        color: '#ddd',
        padding: '8px'
    },
    infoText: {
        fontFamily: `'Oswald', sans-serif`,
        color: '#888',
        fontSize: '12px',
        paddingBottom: `${theme.spacing(1)}px`,
        paddingTop: '0px !important',
        paddingLeft: '10px',
        paddingRight: '10px',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        overflowWrap: 'anywhere'
    },
    cover: {
        width: 130,
        height: 130,
        backgroundSize: 'cover',
        margin: 10
    },
    infoCover: {
        width: '100%',
        aspectRatio: '16/9',
        backgroundSize: 'cover',
    },
    controls: {
        display: 'flex',
        width: '70%',
        margin: '0 auto'
    },
    actions: {
        display: 'flex',
        position: 'absolute',
        top: '100px',
        right: '0px',
    },
    expand: {
        color: 'rgba(0,0,0, .2)',
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        marginLeft: 'auto',
        [theme.breakpoints.up('sm')]: {
            marginRight: -8,
        },
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },

    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    productsOrdered: {
        fontSize: theme.typography.pxToRem(15),
        flex: 1,
        textAlign: 'right'
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    fabTeal: {
        color: theme.palette.common.white,
        backgroundColor: teal[500],
        '&:hover': {
            backgroundColor: teal[600],
        },
    },
    progress: {
        margin: '33% auto', display: 'block'
    },


    paperRoot: {
        backgroundColor: '#ffffff'
    },

    
    listCategories: {
        display: 'flex',
        overflow: 'auto hidden',
        height: '90px',
        paddingTop: '4px',
        paddingBottom: '8px'
    },


    categoryContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 'max-content',
    },

    badge: {
        top: 2,
        right: 4,
        fontFamily: 'Oswald',
        fontSize: 12,
        color: '#ffffff',
        backgroundColor: '#000000',
        border: '2px solid #ffffff',
    },

    btnCategory: {
        margin: '4px 8px',
        width: '42px',
        height: '42px'
    },
    category: {
        width: '42px',
        height: '42px',
        color: 'rgba(42,46,54,0.7)',
        backgroundColor: '#d1d1d1',
    },
    selectedCategory: {
        width: '42px',
        height: '42px',
        backgroundColor: '#F1204C', 
    },

    categoryIcon: {
        color: 'rgba(42,46,54, 0.7)',
    },

    selectedCategoryIcon: {
        color: '#ffffff'
    },

    categoryText: {
        fontSize: 'small',
        color: '#5F5F5F',
        fontFamily: `'Oswald', sans-serif`,
        width: '100px',
        overflow: 'hidden',
        maxHeight: '31px',
        lineHeight: 1.2,
        margin: '0px 4px',
    },
    selectedCategoryText: {
        fontSize: 'small',
        color: '#F1204C',
        fontFamily: `'Oswald', sans-serif`,
        maxHeight: '31px',
        lineHeight: 1.2,
        width: '100px',
        overflow: 'hidden',
        margin: '0px 4px',
    },


    productOf: {
        fontFamily: `'Oswald', sans-serif`,
        fontSize: 'larger',
        color: 'rgba(0,0,0, .5)'
    },
    link: {
        color: 'rgba(0,0,0, .33)',
        fontFamily: `'Oswald', sans-serif`,
        fontSize: 'small'
    },

    cardCategoryAvailabilities: {
        margin: theme.spacing(1)
    }

});


class Item extends Component {

    state = { expanded: this.props.isThisCategorySpecial || false, order: {} };


    handleExpandClick = () => {
        this.setState({ expanded: !this.state.expanded });
    };


    translate = (text) => {
        return translateAction(text, this.props.language)
    }

    onAdd = item => event => {
        const newOrderPortions = (this.props.newOrderPortions || 0) + 1
        this.props.setOrder({ productId: item, quantity: newOrderPortions })
        event.stopPropagation()
    }
    
    onRemove = item => event => {
        const newOrderPortions = ((this.props.newOrderPortions || 0) - 1) < 0 ? 0 : (this.props.newOrderPortions - 1)
        this.props.setOrder({ productId: item, quantity: newOrderPortions })
        event.stopPropagation()
    }

    getLastPrice = (product, categoryPriceRules) => {

        let lastPrice = product.price

        if (categoryPriceRules !== undefined && categoryPriceRules.length > 0) {
            const categoryPriceRule = categoryPriceRules.filter(cpr => new Date(cpr.startDate) <= Date.now() && new Date(cpr.endDate) >= Date.now())[0]
            if (categoryPriceRule !== undefined) {
                if (categoryPriceRule.type === 'percent') {
                    lastPrice = product.price * (1 - (categoryPriceRule.value / 100))
                }
                if (categoryPriceRule.type === 'value') {
                    lastPrice = product.price - categoryPriceRule.value
                }
            }
        } else if (product.ranges !== undefined) {
            const specialOffer = product.ranges
                .filter(r => r.days.includes(new Date().getDay() || 7))
                .filter(r => 
                    (
                        (new Date().setHours(r.startTime.split(':')[0], r.startTime.split(':')[1]) <= Date.now()) 
                            &&
                        (Date.now() <= new Date().setHours(r.endTime.split(':')[0], r.endTime.split(':')[1]))
                    )
                )
            lastPrice = specialOffer[specialOffer.length-1] === undefined ? product.price : specialOffer[specialOffer.length-1].price
        }

        if (lastPrice % 1 !== 0) {
            return lastPrice.toFixed(2)
        }
        return lastPrice
    }


    checkIfIsInTimeRange = product => {
        if (product.ranges === undefined) {
            return false
        }
        const specialOffer = product.ranges
            .filter(r => r.days.includes(new Date().getDay() || 7))
            .filter(r => 
                (
                    (new Date().setHours(r.startTime.split(':')[0], r.startTime.split(':')[1]) <= Date.now()) 
                        &&
                    (Date.now() <= new Date().setHours(r.endTime.split(':')[0], r.endTime.split(':')[1]))
                )
            )
        
        return specialOffer.length > 0
    }
    

    render() {
        const { classes, orderedPortions, language, currency, isThisCategoryAvailableNow, isThisCategorySpecial, accessToDirectOrders, isThisAnInfoCategory, categoryPriceRules } = this.props
        const translate = this.translate

        const {
            available,
            id,
            thumbImg,
            name,
            price,
            weight,
        } = this.props.item

        if (!available)
            return null

        const description = this.props.item.description[language] === undefined ? '' : this.props.item.description[language]
        
        // if this is an info category, then render it differently
        if (isThisAnInfoCategory) {
            return (
                <div id={id.replace(/[0-9]/g, '')} className={classes.root}>
                    <ListItem style={{ padding: '4px 10px' }}>
                        <Card className={classes.card}>
                            <LazyLoadImage
                                wrapperClassName={classes.infoCover}
                                placeholderSrc={placeholderImg}
                                src={thumbImg || placeholderImg}
                                loading="lazy"
                                style={{ width: '100%', aspectRatio:'16/9', objectFit: 'contain' }}
                            />
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: 'fit-content', padding: '5px' }}>
                                <CardContent className={classes.details}>
                                    <div className={classes.content}>
                                        <Typography className={classes.contentText}>{`${name[language]} ${weight || ''}`}</Typography>
                                    </div>
                                
                                    <div className={classes.infoText} style={{ marginTop: '10px', paddingLeft: '0px', paddingRight: '0px', paddingBottom: '0px' }}>
                                        <div style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: description }} />
                                    </div>
                                </CardContent>
                            </div>
    
                        </Card>
                    </ListItem>
                </div>
            )
        }


        const lastPrice = this.getLastPrice(this.props.item, categoryPriceRules)


        let isInTimeRange = true
        let timeRangesForThisSpecialCategory = []
        if (isThisCategorySpecial) {
            isInTimeRange = this.checkIfIsInTimeRange(this.props.item)
            timeRangesForThisSpecialCategory = (this.props.item.ranges || []).map(range => {
                const weekdays = getWeekDays(language)
                const days = range.days.map(dayNumber => weekdays[dayNumber])
                return (
                    <Typography  key={uniqueId()} className={classes.contentText} style={{ fontSize: 11, paddingBottom: 5, color: '#888' }}>{`[${days}] [${range.startTime} - ${range.endTime}] - ${range.price} ${currency}`}</Typography>
                )
            })
        }

        const isPriceZero = lastPrice === 0
        
        if (accessToDirectOrders) {
            return (
                <div id={id.replace(/[0-9]/g, '')} className={classes.root}>
                    <ListItem style={{ padding: '4px 10px' }}>
                        <Card className={classes.card} onClick={this.handleExpandClick}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: 150 }}>
                                <LazyLoadImage
                                    wrapperClassName={classes.cover}
                                    height={130}
                                    width={130} 
                                    placeholderSrc={placeholderImg}
                                    src={thumbImg || placeholderImg}
                                    loading="lazy"
                                />
                                <CardContent className={classes.details} style={{ padding: 10 }}>
                                    <div className={classes.content}>
                                        <Typography className={classes.contentText}>{`${name[language]} ${weight || ''}`}</Typography>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            {!isThisCategorySpecial && price !== lastPrice && <Typography className={classNames(classes.contentText, classes.biggerPrice )} variant="caption" color="textSecondary">{`${price} ${currency}`}</Typography>}
                                            {(isThisCategorySpecial && !isInTimeRange) || isPriceZero ? null :
                                                <Typography 
                                                    className={(price !== lastPrice) || (isThisCategorySpecial && isInTimeRange) 
                                                        ? classNames(classes.contentText, classes.price, classes.bestPrice)
                                                        : classNames(classes.contentText, classes.price)
                                                    } variant="caption" color="textSecondary">{`${lastPrice} ${currency}`}</Typography>
                                            }
                                        </div>
                                    </div>


                                    <div className={classes.controls} style={{ justifyContent: orderedPortions === 0 ? 'center' : 'space-between' }} >
                                        {
                                            orderedPortions === 0 ? null
                                                : (
                                                    <IconButton className={classes.btn} aria-label={`${translate('Remove')}`} onClick={this.onRemove(id)} style={{touchAction: 'none'}}>
                                                        <RemoveCircleOutlineIcon />
                                                    </IconButton>
                                                )
                                        }

                                        {
                                            orderedPortions === 0 ? null
                                                : (
                                                    <Typography className={classes.orderedPortions} onClick={this.onAdd(id)}>{orderedPortions}</Typography>
                                                )
                                        }

                                        <IconButton className={classes.btn} aria-label={`${translate('Add')}`} onClick={this.onAdd(id)} style={{touchAction: 'none', visibility: ((isThisCategoryAvailableNow && !isThisCategorySpecial) || (isThisCategorySpecial && isInTimeRange)) ? 'visible' : 'hidden'}}> 
                                            <AddCircleOutlineIcon />
                                        </IconButton>
                                    </div>


                                    <div className={classes.contentPay}>
                                        {
                                            orderedPortions === 0 ? null
                                                : (
                                                    <div className={classes.contentPay}>
                                                        <div style={{ width: '50px', height: '1px', backgroundColor: '#888', marginBottom: '4px' }} />
                                                        <Typography className={classes.pay}>
                                                            {`${translate('Pay')}: ${fixDecimals(lastPrice * orderedPortions)} ${currency}`}
                                                        </Typography>
                                                    </div>
                                                )
                                        }
                                    </div>

                                    {(description.length > 0 || timeRangesForThisSpecialCategory.length > 0) &&
                                        <CardActions className={classes.actions} disableSpacing>
                                            <IconButton
                                                className={classNames(classes.expand, {
                                                    [classes.expandOpen]: this.state.expanded,
                                                })}
                                                onClick={this.handleExpandClick}
                                                aria-expanded={this.state.expanded}
                                                aria-label="Show more"
                                            >
                                                <ExpandMoreIcon />
                                            </IconButton>
                                        </CardActions>
                                    }

                                </CardContent>
                            </div>
                            
                            {(description.length > 0 || timeRangesForThisSpecialCategory.length > 0) &&
                                <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                                    <CardContent className={classes.infoText} style={{ paddingBottom: 8 }}>
                                        <div style={{ whiteSpace: 'pre-wrap', lineHeight: 1 }} dangerouslySetInnerHTML={{ __html: description }} />
                                        {timeRangesForThisSpecialCategory.length > 0 
                                            && <div style={{ 
                                                paddingTop: '8px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-start'}}
                                            >{timeRangesForThisSpecialCategory}</div>
                                        }
                                    </CardContent>
                                </Collapse>
                            }


                        </Card>
                    </ListItem>
                </div>
            );
        }


        return (
            <div id={id.replace(/[0-9]/g, '')} className={classes.root}>
                <ListItem style={{ padding: '4px 10px' }}>
                    <Card className={classes.card} onClick={this.handleExpandClick}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: 150 }}>
                            <LazyLoadImage
                                wrapperClassName={classes.cover}
                                height={130}
                                width={130} 
                                placeholderSrc={placeholderImg}
                                src={thumbImg || placeholderImg}
                                loading="lazy"
                            />
                            <CardContent className={classes.details}>
                                <div className={classes.content}>
                                    <Typography className={classes.contentText}>{`${name[language]} ${weight || ''}`}</Typography>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        {!isThisCategorySpecial && price !== lastPrice && <Typography className={classNames(classes.contentText, classes.biggerPrice )} color="textSecondary">{`${price} ${currency}`}</Typography>}
                                        {(isThisCategorySpecial && !isInTimeRange) || isPriceZero ? null :
                                            <Typography 
                                                className={(price !== lastPrice) || (isThisCategorySpecial && isInTimeRange) 
                                                    ? classNames(classes.contentText, classes.price, classes.bestPrice)
                                                    : classNames(classes.contentText, classes.price)
                                                }  color="textSecondary">{`${lastPrice} ${currency}`}</Typography>
                                        }
                                    </div>
                                </div>
                                

                                <div style={{ display: 'flex', flexDirection: 'column', flex: 1, overflowY: 'auto' }}>
                                    {timeRangesForThisSpecialCategory.length > 0 &&
                                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-start' }}>
                                            {timeRangesForThisSpecialCategory}
                                        </div>
                                    }

                                
                                   <div className={classes.infoText} style={{ paddingLeft: '0px', paddingRight: '0px', paddingBottom: '1px' }}>
                                        <div style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: description }} />
                                    </div>
                                </div>
                            </CardContent>
                        </div>

                    </Card>
                </ListItem>
            </div>
        )
    }
}




class Items extends Component {

    state = {
        order: {}
    }

    componentWillMount() {
        console.log('Items -> CompoenentWillMount', this.props)
    }

    translate = (text) => {
        return translateAction(text, this.props.language)
    }

    setOrder = (item) => {
        console.log('**** set order', item)
        this.props.handleOrderChange(item)
    }

    listItems = (data) => {

        if (data.filter(d => d.available).length === 0) {
            return null
        }

        const { 
            classes, order, selectedCategory, isThisCategoryAvailableNow, isThisCategorySpecial, 
            isThisAnInfoCategory, categoriesNotAvailableNow, accessToDirectOrders, categoryPriceRules
        } = this.props
        
        return data.map(prep => {

            let orderedPortions = 0
            let newOrderPortions = 0
            let ORDER_NOT_SENT = { meta: { status: orderStates.ORDER_NOT_SENT }, order: { } }

            order.forEach(o => { 
                const categ = o.order[prep.categoryId]
                if (categ) {
                    const prod = o.order[prep.categoryId][prep.id]
                    if (prod) {
                        orderedPortions = orderedPortions + prod.quantity  
                        if (o.meta === undefined || o.meta.status === orderStates.ORDER_NOT_SENT) {
                            newOrderPortions = newOrderPortions + prod.quantity
                            ORDER_NOT_SENT = { ...o }
                        }
                    }
                }
            })

            
            if (selectedCategory === 'recommended' && categoriesNotAvailableNow.includes(prep.categoryId)) {
                return null
            }

            return (
                <Item
                    key={prep.id}
                    item={{ ...prep }}
                    classes={classes}
                    language={this.props.language}
                    currency={this.props.currency}
                    setOrder={(order) => this.setOrder({ ORDER_NOT_SENT, ...order, categoryId: prep.categoryId })}
                    orderedPortions={orderedPortions}
                    newOrderPortions={newOrderPortions}
                    isRecommendedSelected={selectedCategory === 'recommended'}
                    isThisCategoryAvailableNow={isThisCategoryAvailableNow}
                    isThisCategorySpecial={isThisCategorySpecial}
                    isThisAnInfoCategory={isThisAnInfoCategory}
                    accessToDirectOrders={accessToDirectOrders}
                    categoryPriceRules={categoryPriceRules}
                />
            )
        })

    }

    render() {
        const { data, categoryAvailabilities, classes, language } = this.props
        console.log(this.props, this.state)

        if (data === undefined) {
            return null
        }

        let cardAvailabilities = null
        if (categoryAvailabilities) {
            cardAvailabilities = categoryAvailabilities.map(range => {
                const weekdays = getWeekDays(language)
                const days = range.days.map(dayNumber => weekdays[dayNumber])

                return (
                    <Card key={uniqueId()} className={classes.cardCategoryAvailabilities}>
                        <CardHeader style={{ padding: 0, background: 'teal' }}
                            title={
                                <div style={{ textAlign: 'center', color: 'white', padding: 5 }}>
                                    <Typography variant="body2" style={{ textAlign: 'center', color: 'white' }}>{`${days}`}</Typography>
                                    <Typography variant="body2" style={{ textAlign: 'center', color: 'white' }}>{`${range.startTime} - ${range.endTime}`}</Typography>
                                </div>
                            }
                        />
                    </Card>
                )
            })
        }
        
        
        const listItems = this.listItems(data)

        return (
            <List>
                {cardAvailabilities}
                {listItems}
            </List>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Items)
