export default `
    Menu                    = Menü
    Welcome                 = Fogadtatás
    Price                   = Ár
    Add                     = Hozzáad
    Remove                  = Eltávolít
    Order list              = Rendelési lista
    Order                   = Megbízás
    Place order             = Helyezze el a rendelést
    Tables                  = Asztalok
    Pay                     = Fizet
    Recommended             = Ajánlott
    Total                   = Teljes
`

