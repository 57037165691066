/* eslint-disable react/no-multi-comp */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress'
import ChooseLanguageBinding from '../bindings/ChooseLanguageBinding';
// import Typography from '@material-ui/core/Typography';
// import agileHubLogo from '../../../assets/images/agilehub64.png'

const styles = {
    progress: {
        marginTop: '40vh',
    },
    productOf: {
        fontFamily: `'Oswald', sans-serif`,
        fontSize: 'larger',
        color: 'rgba(0,0,0, .5)'
    },
    link: {
        color: 'rgba(0,0,0, .33)',
        fontFamily: `'Oswald', sans-serif`,
        fontSize: 'small'
    }
};

class LanguageScreen extends Component {
    state = {
        open: true,
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { classes, logoUrl, languages } = this.props;
        console.log('LanguageScreen - render', this.state, this.props)
        const noLanguages = !Array.isArray(languages)

        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {/* <div style={{ marginTop: '15%', display: 'flex', justifyContent: 'center' }}> */}
                    <img style={{
                        width: '90%',
                        objectFit: 'contain',
                        top: '18%',
                        position: 'absolute',
                    }}src={logoUrl} alt="" />
                {/* </div> */}
                {noLanguages &&  <CircularProgress size={48} className={classes.progress} color="secondary" />}
                <Dialog disableBackdropClick={true} disableEscapeKeyDown={true} open={this.state.open} onClose={this.handleClose} aria-labelledby="simple-dialog-title">
                    <ChooseLanguageBinding onClose={() => this.handleClose()} />
                </Dialog>
                {/* <div style={{ position: 'fixed', bottom: 24, left: 0, right: 0, opacity: .85, pointerEvents: 'none' }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography className={classes.productOf}>{`PRODUCT OF AGILEHUB`}</Typography>
                        <Typography className={classes.link}>{`www.agilehub.ro`}</Typography>
                    </div>
                </div> */}
            </div>
        );
    }
}

LanguageScreen.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LanguageScreen);
