import { connect } from 'react-redux'
import ChooseLanguageComponent from './../components/ChooseLanguageComponent'
import * as actions from './../actions'

const mapStateToProps = state => {
    console.log('ChooseLanguages bindings state', state)
    return ({
        restaurantId: state.SCANNER.restaurantId,
        languagesList: state.RESTAURANT.INFO === undefined ? undefined : state.RESTAURANT.INFO.languages,
    })
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return ({
        setLanguage: (lanugage) => dispatch(actions.setLanguage(lanugage)),
        ...ownProps
    })
}

const ChooseLanguageBinding = connect(mapStateToProps, mapDispatchToProps)(ChooseLanguageComponent)

export default ChooseLanguageBinding
