import axios from 'axios'
import * as constants from './constants'
import { RESTAURANT_INFO, SIGNED_IN_ERROR, rescan } from '../appActions'
import { WAITER_INFO } from '../waiter/actions'
import { getTables } from '../waiter/utils'
import { URL_GCM_SUBSCRIBE_TO_TOPIC, URL_GET_USER, URL_UPDATE_USER } from '../../configs/constants';
export const QR_CODE_DATA = `${constants.MODULE_NAME}/QR_CODE_DATA`

export const handleQRCodeData = (qrCodeData) => async dispatch => {
    dispatch({
        type: QR_CODE_DATA,
        payload: qrCodeData
    })
    await dispatch(getRestaurantInfo(qrCodeData))
}

export const getRestaurantInfo = ({ restaurantId, restaurantUrl, waiterId }) => async (dispatch, getState, { getFirebase, getFirestore }) => {
    console.log('@scan - getRestaurant INFO', { restaurantId, restaurantUrl, waiterId })
    const db = getFirestore()

    const refAllLanguages = await db.collection('languages').get()
    const allLanguages = refAllLanguages.docs.map(doc => ({ ...doc.data() }))

    let refRestaurant
    let restaurant

    if (restaurantUrl) {
        refRestaurant = await db.collection("restaurants")
            .where('url', '==', restaurantUrl)
            .get()
        
        restaurant = refRestaurant.docs[0] && refRestaurant.docs[0].data()
        
        if (!restaurant) {
            refRestaurant = await db.collection("restaurants")
                .where('id', '==', restaurantUrl)
                .get()
            
            restaurant = refRestaurant.docs[0] && refRestaurant.docs[0].data()
        }
    } else if (restaurantId) {
        refRestaurant = await db.collection("restaurants")
            .where('id', '==', restaurantId)
            .get()
        
        restaurant = refRestaurant.docs[0] && refRestaurant.docs[0].data()
    }


    if (!restaurant) {
        console.warn('No such restaurant!')
        dispatch(rescan())
        return false
    }

    console.log('@scan - getRestaurantInfo ---->', { restaurant })
    
    if (!restaurant.active) {
        console.warn('*** firestore Restaurant not available')
        dispatch(rescan())
        return false
    }

    const id = refRestaurant.docs[0].id
    if (!getState().SCANNER['restaurantId']) {
        dispatch({
            type: QR_CODE_DATA,
            payload: { restaurantId: id }
        })
    }

    const { url, waiterCreateOrders=true, languages, defaultCurrency, logoUrl, tablesConfig, assignedWaiterids, accessToDirectOrders, feedback={}, review={}, callForWaiter } = restaurant
    
    console.log('assignedWaiterids', {restaurant, assignedWaiterids, waiterId}, (waiterId && assignedWaiterids.indexOf(waiterId) !== -1) )
    if (waiterId && assignedWaiterids.indexOf(waiterId) === -1) {
        console.warn('userul nu este WAITER !!!')
        alert(`Userul nu este ospatar! ${waiterId}`)
        return false
    }

    const allTables = getTables({ tablesConfig })

    dispatch({
        type: RESTAURANT_INFO,
        payload: { 
            accessToDirectOrders,
            callForWaiter,
            languages: languages.map(language => ({...allLanguages.filter(l => l.code === language)[0] })), 
            language: languages[0], 
            defaultCurrency, 
            logoUrl, 
            name: restaurant.name, 
            tablesConfig,
            allTables,
            url,
            waiterCreateOrders,
            feedback,
            review
        }
    })


    if (!waiterId) {
        return false
    }

    const { data: waiterData } = await axios.post(URL_GET_USER, { uid: waiterId })
    console.log('@scan actions getRestaurantInfo -> waiter', waiterData)
    
    if (!waiterData || !waiterData.active) {
        console.warn('waiter - no data', waiterId)
        return false
    }

    const { name, active, assignedTables, notificationToken, roles } = waiterData
    const hasAssignedTables = assignedTables && assignedTables.length > 0
    const tables = getTables({ tablesConfig, assignedTables })

    let waiter = {
        name, 
        active,
        tables, 
        hasAssignedTables,
        notificationToken,
        roles
    }
    console.log('@WAITER_INFO', { ...waiter, waiterId })
    const latestNotificationToken = localStorage.getItem('nt');

    if (!!latestNotificationToken && notificationToken !== latestNotificationToken) {
        axios.post(URL_UPDATE_USER, { 
            uid: waiterId, 
            updatedData:  { 
                notificationToken: latestNotificationToken, 
                isWaiterLoggedIn: true 
            }
        }) 

        waiter = { ...waiter, notificationToken: latestNotificationToken }
    } else {
        axios.post(URL_UPDATE_USER, { 
            uid: waiterId, 
            updatedData:  { 
                isWaiterLoggedIn: true 
            }
        }) 
    }

    localStorage.setItem('s', JSON.stringify({ r: restaurant.id, w: waiterId }))

    dispatch({
        type: WAITER_INFO,
        payload: waiter
    })

    return true
}