export default `
    Menu                    = Menu
    Welcome                 = Benvenuto
    Price                   = Prezzo
    Add                     = Aggiungere
    Remove                  = Rimuovere
    Order list              = Lista degli ordini
    Order                   = Ordine
    Place order             = Effettua l'ordine
    Tables                  = Tabelle
    Pay                     = Pagare
    Recommended             = Raccomandato
    Total                   = Totale
    Call for waiter         = Chiama il cameriere
    PAY                     = PAGARE
    PAY CASH                = PAGARE IN CONTANTI
    PAY CARD                = PAY CARD
    CARD                    = CARTA
    CASH                    = DENARO CONTANTE
    Later                   = Dopo
`

