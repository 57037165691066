import { orderStates } from '../../configs/constants';
import * as actions from './actions'



const INITIAL_STATE = {
    [actions.ORDER]: []
}


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case actions.NOTIFICATION_SENT_TO_WAITER:
        case actions.MENU: 
        case actions.PAY_WITH: 
        {
            return {
                ...state,
                [action.type]: action.payload,
            }
        }

        case actions.ORDER: {

            let newOrder
            newOrder = state["MENU/ORDER"].map(s => {
                if (s.meta.status === undefined
                    || (s.meta.status === action.payload.meta.status && action.payload.meta.status === orderStates.ORDER_NOT_SENT)
                    || s.meta.id === action.payload.meta.id) {
                    return action.payload
                }
                return s
            })
            
            if (newOrder.filter(no => no.meta.status === orderStates.ORDER_NOT_SENT).length === 0) {
                return {
                    ...state,
                    [actions.ORDER]: [...newOrder, action.payload ]
                }
            }
            return {
                ...state,
                [actions.ORDER]: [...newOrder]
            }

        }

        case 'SET_CURRENT_ORDER_FROM_DB_v2': {
            const s = state[actions.ORDER]
            const p = action.payload
            console.log({ s, p })
            const idsP = p.map(o => o.meta.id)
            const unverifiedOrders = idsP.length === 0 ? [] : s.filter(o => !idsP.includes(o.meta.id))

            return {
                ...state, 
                [actions.ORDER]: [ ...action.payload, ...unverifiedOrders ],
                [actions.ORDER_PAID]: action.paid,
                waitingOrderConfirmation: unverifiedOrders[0] && unverifiedOrders[0].meta.status === orderStates.ORDER_UNCONFIRMED,
                multiplePersons: action.multiplePersons
            }
        }
        

        case actions.SEND_ORDER_TO_WAITER: {
            const order = [ ...state[actions.ORDER].filter(o => o.meta.status !== orderStates.ORDER_NOT_SENT) ]
            return {
                ...state,
                [actions.ORDER]: order,
                waitingOrderConfirmation: true
            }
        }

        case actions.DELETE_ORDER:
        {
            return {
                ...state,
                [actions.ORDER]: state[actions.ORDER].filter(order => (order.meta.status !== orderStates.ORDER_NOT_SENT))
            }
        }

        case actions.RESET_ORDER_TABLE_CLOSED:
        {
            return {
                ...state,
                [actions.ORDER]: []
            }   
        }

        default:
            return state
    }
}
