export default `
    Menu                                                    = Meniu
    Welcome                                                 = Bine ati venit
    Price                                                   = Pret
    Add                                                     = Adauga
    Remove                                                  = Sterge
    Order list                                              = Lista comenzii dvs.
    Order                                                   = Comanda
    Place order                                             = Genereaza comanda
    Table                                                   = Masa
    Tables                                                  = Mese
    Pay                                                     = Pret
    Recommended                                             = Recomandat
    Total                                                   = Total
    Not available                                           = Indisponibil
    Send to waiter                                          = Trimiteti la ospatar
    Call for waiter                                         = Cheama ospatarul
    WANT_TO_ORDER                                           = Vreau sa comand
    PAY                                                     = PLATA
    PAY_CASH                                                = PLATA CASH
    PAY_CARD                                                = PLATA CARD
    How do you want to pay?                                 = Cum doriti sa achitati?
    CARD                                                    = CARD
    CASH                                                    = CASH
    Later                                                   = Mai tarziu
    Call waiter to pay for all orders or just yours?        = Achitati doar dvs. sau se achita intreaga comanda de la masa?
    Just my order                                           = Doar eu
    We pay all                                              = Intreaga comanda
    Are you sure you want to clear this order?              = Doriti sa stergeti aceasta comanda?
    Yes                                                     = Da
    No                                                      = Nu
    PAID                                                    = ACHITAT
    SEND                                                    = TRIMITE
    CANCEL                                                  = ANULEAZA
    Your feedback matters                                   = Parerea dvs. conteaza
    Are you feeling good at us?                             = Va simtiti bine la noi?
    Food quality?                                           = Calitatea mancarii?
    Cleanliness?                                            = Curatenia?
    Do you want to tell us something?                       = Doriti sa ne spuneti ceva?
    Your request is sent                                    = Cererea dvs. a fost transmisa
    Thank you for your feedback                             = Multumim pentru feedbackul trimis
    Soon a waiter will come at you service                  = In curand un ospatar o sa vina la masa dvs.
    Your order request is sent                              = Cererea dvs. de comanda a fost transmisa
    Your payment request is sent                            = Cererea dvs. de plata a fost transmisa
    WAITER                                                  = OSPATAR
    DISPATCHER                                              = DISPECER
    ORDER NOT SENT TO WAITER                                = COMANDA NETRANSMISA CATRE OSPATAR
    ORDER UNVERIFIED                                        = COMANDA NU A FOST VERIFICATA
    ORDER CONFIRMED                                         = COMANDA CONFIRMATA
    ORDER PROCESSING                                        = COMANDA IN CURS DE PROCESARE
    ORDER DELIVERED                                         = COMANDA LIVRATA
    PREPARING                                               = PREGATIRE
    DELIVERING                                              = LIVRARE
    CANCELED                                                = ANULATA
    DECLINE                                                 = REFUZA
    ACCEPT                                                  = ACCEPTA
    ACCEPTED                                                = ACCEPTATE
    OPEN                                                    = COMENZI NOI
    What was the reason for cancelling the order?           = Care este motivul anularii comenzii?
    New order!                                              = Comanda noua!  
    Confirm pickup time?                                    = Confirmați ora de ridicare a comenzii? 
    
`

