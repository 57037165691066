export const ddmmyyyy_HHmm = (millis) => {
  return `${new Date(millis).toLocaleDateString()} ${new Date(millis).toTimeString().slice(0,5)}`
}

export const HHmm = (millis) => {
  return `${new Date(millis).toTimeString().slice(0,5)}`
}

export const ddd_DD = (millis) => {
  return `${new Date(millis).toString().slice(4, 10)}`
}

export const ddd_DD_HHmm = (millis) => {
  return `${ddd_DD(millis)}, ${HHmm(millis)}`
}

