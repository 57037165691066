export const isCategoryAvailableNow = availabilities => {

  if (!availabilities) { return false }

  return availabilities
      .filter(r => r.days.includes(new Date().getDay() || 7))
      .filter(r => 
          (
              (new Date().setHours(r.startTime.split(':')[0], r.startTime.split(':')[1]) <= Date.now()) 
                  &&
              (Date.now() <= new Date().setHours(r.endTime.split(':')[0], r.endTime.split(':')[1]))
          )
  ).length > 0
}