import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles'

const useStyles = makeStyles(styles)

export default function InputAdornments({ props }) {
    const {
        translate,
        TOTAL_TO_PAY,
        currency,
        openClearTable,
        doPayment,
        clearTable
    } = props

    const classes = useStyles();
    const [values, setValues] = React.useState({});

    const handleChange = (prop, value) => event => {
        setValues({ ...values, [prop]: event.target ? event.target.value : value ? value : undefined });
    }

    const rest = values.amount === undefined
        ? 0
        : (parseFloat(values.amount) || 0) - parseFloat(TOTAL_TO_PAY)

    console.log({
        translate,
        TOTAL_TO_PAY,
        amount: values.amount,
        currency,
        openClearTable,
        clearTable
    })

    const handleFocus = event => {
        event.preventDefault()
        const { target } = event
        target.focus()
        if (target.value !== undefined) {
            target.setSelectionRange(0, target.value.length)
        }
    }


    const onContextMenu = event => {
        event.preventDefault();
    }

    const inputDisabled = true
    
    return (
        <Dialog
            open={openClearTable}
            onExit={handleChange('amount')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <div className={classes.marginDialogTitle} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                onClick={handleChange('amount', TOTAL_TO_PAY)}
            >
                <Typography variant="h5" gutterBottom>{`${translate('TOTAL')}`}</Typography>
                {!inputDisabled && <Typography variant="h5">{`${currency} ${TOTAL_TO_PAY}`}</Typography>}
            </div>


            <DialogContent>
                <FormControl fullWidth>
                    <Input
                        disabled={inputDisabled}
                        type="tel"
                        value={values.amount === undefined ? parseFloat(TOTAL_TO_PAY) : values.amount}
                        onChange={handleChange('amount')}
                        onClick={handleFocus}
                        style={{ fontSize: 'x-large' }}
                        inputProps={{ style: { textAlign: 'right' } }}
                        startAdornment={<InputAdornment position="start">{currency}</InputAdornment>}
                        onContextMenu={onContextMenu}
                    />
                </FormControl>

                <div className={classes.marginRest} style={{ visibility: rest !== 0 ? '' : 'hidden', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end', fontSize: '1rem', color: 'rgba(0,0,0, 0.54)' }}>
                    <Typography gutterBottom>{`${rest < 0 ? '' : translate('REST')}`}</Typography>
                    <Typography style={{ color: 'rgba(0,0,0, 0.9)', marginLeft: 'auto' }} variant="h5">{`${rest}`}</Typography>
                </div>

            </DialogContent>


            <DialogActions>
                <Button onClick={() => doPayment(values.amount === undefined ? parseFloat(TOTAL_TO_PAY) : values.amount)} color="primary" autoFocus>
                    {`${translate('PAY')}`}
                </Button>

                <Button onClick={() => clearTable()} color="primary" autoFocus>
                    {`${translate('CANCEL')}`}
                </Button>
            </DialogActions>

        </Dialog>
    );
}
