import keys from 'lodash/keys'
import filter from 'lodash/filter'
import uniqueId from 'lodash/uniqueId'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import translateAction from '../../../util/translate'
import Fab from '@material-ui/core/Fab'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import teal from '@material-ui/core/colors/teal'
import green from '@material-ui/core/colors/green'
import orange from '@material-ui/core/colors/orange'
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { orderStates, waiterServices } from '../../../configs/constants'




const fixDecimals = amount => {
    let value = `${amount}`.split('.')[0]
    let decimals = `${amount}`.split('.')[1]
    if (value === '0' && decimals !== undefined) {
        decimals = (decimals + '00').substr(0, 3)
    } else {
        if (decimals === undefined) {
            decimals = '00'
        } else {
            decimals = `${decimals}0`.substr(0, 2)
        }
    }
    if (decimals === '00') {
        return `${value}`
    }
    return `${value}.${decimals}`
}


const styles = theme => {
    return ({
        flex: {
            flex: 1,
        },
        root: {
            width: '100%',
        },

        btnTeal: {
            color: theme.palette.common.white,
            backgroundColor: teal[500],
            '&:hover': {
                backgroundColor: teal[600],
            },
            fontSize: 'larger',
            minHeight: '48px'
        },
        container: {
            height: 'inherit',
            width: '100%',
            overflowX: 'hidden',
            overflowY: 'scroll',
            WebkitOverflowScrolling: 'touch',
            position: 'fixed',
            top: '144px',
            paddingLeft: 6,
            paddingRight: 6,
            paddingBottom: '100px',
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'flex-start',
            flexDirection: 'column',
        },

        categoryName: {
            color: '#5F5F5F',
            fontSize: 18,
            fontWeight: 'bold',
            textAlign: 'center',
            fontFamily: `'Oswald', sans-serif`,
            paddingBottom: '6px',
            textTransform: 'uppercase',
            opacity: 1,
        },
        item: {
            fontSize: '13px',
            lineHeight: 1.25,
            opacity: 1,
        },
        itemsCategory: {
            paddingBottom: 12
        },
        orderedTimestamp: {
            marginTop: 6,
            backgroundColor: 'rgba(0,0,0, .1)',
            color: '#222',
            fontSize: 10,
            textAlign: 'center',
            fontFamily: `'Oswald', sans-serif`,
            opacity: .75,
        },
        buttonsContainer: {
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
            display: 'flex',
            justifyContent: 'flex-end',
            width: `calc(100% - ${theme.spacing(4)}px)`,
            alignItems: 'flex-end',
        },
        fab: {
            position: 'absolute',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        },
        fabTeal: {
            color: theme.palette.common.white,
            fontSize: 'larger',
            backgroundColor: teal[500],
            '&:hover': {
                backgroundColor: teal[600],
            },
        },
        payWith: {
            fontFamily: `'Oswald', sans-serif`,
            color: theme.palette.common.white,
            height: 32,
            backgroundColor: teal[500],
            '&:hover': {
                backgroundColor: teal[600],
            },
            border: 'transparent',
            borderStyle: 'solid',
            borderWidth: 3
        },
        payWithSelected: {
            fontFamily: `'Oswald', sans-serif`,
            color: theme.palette.common.white,
            height: 32,
            '&:hover': {
                backgroundColor: teal[600],
            },
            fontWeight: '900',
            backgroundColor: teal[600],
            border: theme.palette.common.white,
            borderStyle: 'solid',
            borderWidth: 3
        },
        button: {
            color: '#fff'
        },
        paid: {
            padding: '2px 12px',
            borderRadius: '6px',
            backgroundColor: '#5f5f5f',
            fontWeight: '600',
            color: '#fff',
            fontSize: 15,
        },
        payAllTable: {
            fontFamily: `'Oswald', sans-serif`,
            width: 120,
            padding: 0,
            color: theme.palette.common.white,
            height: 32,
            fontSize: 'smaller',
            backgroundColor: teal[500],
            '&:hover': {
                backgroundColor: teal[600],
            },
        },
        dialogTitle: {
            fontFamily: `'Oswald', sans-serif`,
            lineHeight: 1.4,
            fontSize: '1.2rem',
            textAlign: 'center',
            fontWeight: '500'
        }
    })
}



class Table extends Component {

    state = { chooseHowToPay: false, confirmationDeleteOrder: false, payAllTable: null, displayConfirmationPayAllTable: false }

    componentDidMount() {
        document.getElementById('orderlist').scrollTo(0, document.getElementById('orderlist').scrollHeight)
    }


    translate = (text) => {
        return translateAction(text, this.props.language)
    }

    handleClose = () => {
        this.props.hideOrderList()
    }

    goToCategory = categoryId => event => {
        console.log('onCLick goToCategory', categoryId)
        this.props.goToCategory(categoryId)
        event.stopPropagation()
    }

    goToProduct = (categoryId, productId) => event => {
        console.log('onCLick goToCategory', categoryId, productId)
        this.props.goToProduct(categoryId, productId)
    }

    getLastPrice = product => {
        if (product.ranges === undefined) {
            return product.price
        }
        const specialOffer = product.ranges
            .filter(r => r.days.includes(new Date().getDay() || 7))
            .filter(r =>
                (
                    (new Date().setHours(r.startTime.split(':')[0], r.startTime.split(':')[1]) <= Date.now())
                    &&
                    (Date.now() <= new Date().setHours(r.endTime.split(':')[0], r.endTime.split(':')[1]))
                )
            )
        const lastPrice = specialOffer[specialOffer.length - 1] === undefined ? product.price : specialOffer[specialOffer.length - 1].price
        return lastPrice
    }

    getOrderStatusBackgroudHeader = (orderStatus) => {
        switch (orderStatus) {

            case orderStates.ORDER_CANCELED_CUSTOMER_REQUEST:
            case orderStates.ORDER_CANCELED_LONG_WAITED:
            case orderStates.ORDER_CANCELED_ORDER_BY_MISTAKE:
            case orderStates.ORDER_CANCELED_OUT_OF_STOCK:
                return orange[500]

            case orderStates.ORDER_PREPARING:
            case orderStates.ORDER_CONFIRMED:
            case orderStates.ORDER_DELIVERED:
                return green[400]

            case orderStates.ORDER_NOT_SENT:
                return teal[300]

            case orderStates.ORDER_UNVERIFIED:
                return orange[300]

            default: {
                return 'transparent'
            }
        }
    }

    getOrderStatusBackgroudBody = (orderStatus) => {
        switch (orderStatus) {

            case orderStates.ORDER_CANCELED_CUSTOMER_REQUEST:
            case orderStates.ORDER_CANCELED_LONG_WAITED:
            case orderStates.ORDER_CANCELED_ORDER_BY_MISTAKE:
            case orderStates.ORDER_CANCELED_OUT_OF_STOCK:
                return orange[300]

            case orderStates.ORDER_PREPARING:
            case orderStates.ORDER_CONFIRMED:
            case orderStates.ORDER_DELIVERED:
                return green[100]

            case orderStates.ORDER_NOT_SENT:
                return teal[100]

            case orderStates.ORDER_UNVERIFIED:
                return orange[100]

            default: {
                return 'transparent'
            }
        }
    }


    displayListOf = ({ orderRequest, orders }) => {
        const { classes, menu, currency, language, accessToDirectOrders } = this.props
        const currentOrder = orderRequest.order
        console.log({ currentOrder, orderRequest, orders, menu })
        return keys(currentOrder).map(categoryId => {
            console.log('categoryId', categoryId)
            const categoryName = menu.find(c => c.details.id === categoryId).details.name[language]
            const containerBackgroundColor = this.getOrderStatusBackgroudBody(orderRequest.meta.status)

            return (
                <div
                    key={categoryId} style={{ paddingTop: 10, paddingBottom: 10, backgroundColor: accessToDirectOrders ? containerBackgroundColor : 'transparent' }}
                    onClick={this.goToCategory(categoryId)}
                >
                    <Typography className={classes.categoryName}>{`${categoryName}`}</Typography>
                    {keys(currentOrder[categoryId]).map(productId => {
                        const product = menu.find(c => c.details.id === categoryId).items.find(p => p.id === productId)
                        const quantity = currentOrder[categoryId][productId].quantity
                        let price1 = currentOrder[categoryId][productId].price1
                        if (price1 === undefined) {
                            price1 = this.getLastPrice(product)
                        }
                        const productName = product.name[language]
                        const productWeight = (product.weight === undefined || product.weight === null || product.weight === '' || product.weight === '0' || product.weight === 0) ? '' : product.weight
                        const unitPrice = price1
                        const productTotal = fixDecimals(quantity * unitPrice)

                        const productBackgroundColor = this.getOrderStatusBackgroudBody(currentOrder[categoryId][productId].status)
                        return (
                            <div
                                key={`productId-${categoryId}-${productId}`} style={{ paddingBottom: '6px', backgroundColor: productBackgroundColor }}
                                onClick={this.goToProduct(categoryId, productId)}
                            >
                                <Typography className={classes.item}>{`${productName} ${productWeight}`}</Typography>
                                <Typography className={classes.item}>{`${quantity} x ${unitPrice} ${currency} = ${productTotal} ${currency}`}</Typography>
                            </div>
                        )
                    })}
                </div>
            )
        })
    }


    getOrderList = () => {

        const { order, classes, accessToDirectOrders, orderPaid } = this.props
        let orderList = []
        this.showBtnPayment = false

        for (const orderRequest of order) {
            const currentOrder = orderRequest.order
            const orderStatusBackgroud = this.getOrderStatusBackgroudHeader(orderRequest.meta.status)

            const ordersSentToWaiter = filter(currentOrder, (order, categoryId) => filter(order, (orderDetails, product) => orderDetails.status).length > 0)
            const ordersNotSentToWaiter = filter(currentOrder, (order, categoryId) => filter(order, (orderDetails, product) => orderDetails.status === undefined).length > 0)

            const orderedTimestamp = orderRequest.meta.timestamp === undefined ? '' : new Date(orderRequest.meta.timestamp)

            this.showBtnSendToWaiter = ordersNotSentToWaiter.length > 0 
            if (!this.showBtnPayment) {
                this.showBtnPayment = accessToDirectOrders && !orderPaid && orderRequest.meta.status === orderStates.ORDER_CONFIRMED
            } 

            orderList = [
                ...orderList,
                <div key={uniqueId()}>

                    <Typography className={classes.orderedTimestamp}>{`${orderedTimestamp.toString().substr(4, 17)}`}</Typography>

                    {
                        accessToDirectOrders &&
                        (orderRequest.meta.status === orderStates.ORDER_UNVERIFIED || orderRequest.meta.status === orderStates.ORDER_NOT_SENT)
                        && (ordersSentToWaiter.length > 0 || ordersNotSentToWaiter.length > 0)
                        && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', backgroundColor: orderStatusBackgroud, alignItems: 'center' }}>
                            <Typography style={{ backgroundColor: orderStatusBackgroud, opacity: 1, padding: 6 }}>{`${this.translate(orderRequest.meta.status)}`}</Typography>
                            <IconButton className={classes.button} aria-label="delete" onClick={() => this.confirmationDeleteOrder({ orderStatus: orderRequest.meta.status })}>
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    }

                    {ordersSentToWaiter.length > 0 && this.displayListOf({ orderRequest, orders: ordersSentToWaiter })}
                    {ordersNotSentToWaiter.length > 0 && this.displayListOf({ orderRequest, orders: ordersNotSentToWaiter })}
                </div>

            ]
        }
        
        if (this.showBtnPayment) {
            this.showBtnPayment = order[order.length-1].meta.status === orderStates.ORDER_CONFIRMED
        }

        return orderList
    }

    getTotalToPay = () => {
        const { order, menu } = this.props

        let TOTAL = 0

        for (const orderRequest of order) {
            const currentOrder = orderRequest.order
            for (const categoryId of keys(currentOrder)) {
                for (const productId of keys(currentOrder[categoryId])) {
                    const quantity = currentOrder[categoryId][productId].quantity
                    let price1 = currentOrder[categoryId][productId].price1
                    if (price1 === undefined) {
                        const product = menu.find(c => c.details.id === categoryId).items.find(p => p.id === productId)
                        price1 = this.getLastPrice(product)
                    }
                    const productTotal = quantity * price1
                    TOTAL = TOTAL + productTotal
                }
            }
        }

        return fixDecimals(TOTAL)
    }

    callWaiterFor = (service) => {
        console.log('@OrderList -> callWaiterFor', service)
        this.setState({ chooseHowToPay: false }, this.props.callWaiterFor(service))
    }

    closeConfirmationPayDialog = () => {
        this.setState({ chooseHowToPay: false, displayConfirmationPayAllTable: false, payAllTable: null })
    }

    openConfirmationPayDialog = () => {
        this.setState({ chooseHowToPay: true, displayConfirmationPayAllTable: false })
    }

    handleCallWaiterForPay = extra => {
        console.log('handleCallWaiterForPay -> extra', extra)
        this.setState(
            { 
                chooseHowToPay: false,
                payAllTable: null, 
                displayConfirmationPayAllTable: false 
            }, 
            () => this.callWaiterFor({ serviceType: waiterServices.PAY, extra })
        )
    }

    confirmarePlata = () => {
        if (this.props.multiplePersons && this.state.payAllTable === null && !this.state.displayConfirmationPayAllTable) {
            return this.setState({ displayConfirmationPayAllTable: true, chooseHowToPay: false })
        }
        
        const payAllTable = this.state.payAllTable
        
        return (
            <Dialog open={this.state.chooseHowToPay}>
                <DialogTitle className={this.props.classes.dialogTitle} disableTypography={true}>{`${this.translate('How do you want to pay?')}`}</DialogTitle>
                <DialogContent style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', height: 56 }}>
                    <Fab
                        variant="extended"
                        className={this.props.classes.payWith}
                        onClick={() => this.handleCallWaiterForPay({ payWith: 'CARD', payAllTable })}
                    >
                        {`${this.translate('CARD')}`}
                    </Fab>
                    <Fab
                        variant="extended"
                        className={this.props.classes.payWith}
                        onClick={() => this.handleCallWaiterForPay({ payWith: 'CASH', payAllTable })}
                    >
                        {`${this.translate('CASH')}`}
                    </Fab>
                </DialogContent>
                <DialogActions >
                    <Button style={{ fontFamily: `'Oswald', sans-serif`, color: teal[500] }} onClick={() => this.closeConfirmationPayDialog()}>{`${this.translate('Later')}`}</Button>
                </DialogActions>
            </Dialog>
        )
    }
    

    handleConfirmationPay = payAllTable  => {
        console.log('handleConfirmationPay -> payAllTable', payAllTable)
        this.setState({ payAllTable, chooseHowToPay: true, displayConfirmationPayAllTable: false })
    }

    displayConfirmationPayAllTable = () => (
        <Dialog open={this.state.displayConfirmationPayAllTable} >
            <DialogTitle className={this.props.classes.dialogTitle} disableTypography={true}>{`${this.translate('Call waiter to pay for all orders or just yours?')}`}</DialogTitle>
            <DialogContent style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', height: 56, padding: 0 }}>
                    <Fab
                        variant="extended"
                        className={this.props.classes.payAllTable}
                        onClick={() => this.handleConfirmationPay(false)}
                    >
                        {`${this.translate('Just my order')}`}
                    </Fab>
                    <Fab
                        variant="extended"
                        className={this.props.classes.payAllTable}
                        onClick={() => this.handleConfirmationPay(true)}
                    >
                        {`${this.translate('We pay all')}`}
                    </Fab>
                </DialogContent>
            <DialogActions >
                {/* <Button style={{ fontFamily: `'Oswald', sans-serif`, color: teal[500] }} onClick={() => this.handleConfirmationPay(false)}>{`${this.translate('Just my order')}`}</Button> */}
                {/* <Button style={{ fontFamily: `'Oswald', sans-serif`, color: teal[500] }} onClick={() => this.handleConfirmationPay(true)}>{`${this.translate('We pay all')}`}</Button> */}
                <Button style={{ fontFamily: `'Oswald', sans-serif`, color: teal[500] }} onClick={() => this.closeConfirmationPayDialog()}>{`${this.translate('Later')}`}</Button>
            </DialogActions>
        </Dialog>
    )



    confirmationDeleteOrder = params => {
        this.setState({ confirmationDeleteOrder: !this.state.confirmationDeleteOrder, deleteOrderParams: params })
    }

    confirmareStergereComandaTrimisaLaWaiter = () => (
        <Dialog open={this.state.confirmationDeleteOrder}>
            <DialogTitle className={this.props.classes.dialogTitle} disableTypography={true}>{`${this.translate('Are you sure you want to clear this order?')}`}</DialogTitle>
            <DialogActions >
                <Button style={{ fontFamily: `'Oswald', sans-serif`, color: teal[500] }} onClick={() => this.handleConfirmationDeleteOrder(true)}>{`${this.translate('Yes')}`}</Button>
                <Button style={{ fontFamily: `'Oswald', sans-serif`, color: teal[500] }} onClick={() => this.handleConfirmationDeleteOrder(false)}>{`${this.translate('No')}`}</Button>
            </DialogActions>
        </Dialog>
    )

    handleConfirmationDeleteOrder = confirmDeleteOrder => {
        if (confirmDeleteOrder) {
            const deleteOrderParams = { ...this.state.deleteOrderParams }
            return this.setState({ confirmationDeleteOrder: false, deleteOrderParams: {} }, this.props.deleteOrder(deleteOrderParams))
        }
        return this.setState({ confirmationDeleteOrder: false, deleteOrderParams: {} })
    }


    render() {
        const translate = this.translate
        const { classes, currency, accessToDirectOrders } = this.props
        console.log('props', this.props)
        console.log('state', this.state)

        const height = (window.screen.height - window.innerHeight - 56) > 0
            ? 'calc(100vh - 144px - 56px)'
            : 'calc(100vh - 144px)'

        const TOTAL = this.getTotalToPay()
        const orderList = this.getOrderList()

            
        return (
            <div id="orderlist" className={classes.container} style={{ height }}>
                {orderList}
                <div style={{ padding: '8px 0px' }}>{'- - - - - - - - - - - - - - - - - - - -'}</div>
                <Typography className={classes.categoryName}>
                    {`${translate('Total').toUpperCase()}: ${TOTAL} ${currency}`}
                </Typography>

                <div className={classes.buttonsContainer}>
                    {
                        accessToDirectOrders && this.showBtnSendToWaiter &&
                        <Fab
                            variant="extended"
                            className={classes.fabTeal}
                            onClick={() => this.props.callWaiterFor({ serviceType: waiterServices.ORDER, hasToPay: `${TOTAL} ${currency}` })}
                            style={{ width: '70%', marginRight: 'auto', opacity: .9 }}
                        >
                            {`${translate('Send to waiter')}`}
                        </Fab>
                    }

                    {this.state.confirmationDeleteOrder && this.confirmareStergereComandaTrimisaLaWaiter()}
                    {this.state.displayConfirmationPayAllTable && this.displayConfirmationPayAllTable()}
                    {this.state.chooseHowToPay && this.confirmarePlata()}

                </div>
            </div>
        )
    }
}

Table.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(Table)


