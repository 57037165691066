import isEmpty from 'lodash/isEmpty'
import keys from 'lodash/keys'
import React, { Component } from 'react'
import Items from './Items'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Snackbar from '@material-ui/core/Snackbar'
import GenerateQROrder from './GenerateOrderQR'
import Zoom from '@material-ui/core/Zoom';
import Fab from '@material-ui/core/Fab';
import classNames from 'classnames';
import teal from '@material-ui/core/colors/teal'
import CircularProgress from '@material-ui/core/CircularProgress'
import OrderList from './OrderList'
import Avatar from '@material-ui/core/Avatar'
import Badge from '@material-ui/core/Badge'
import AppBar from '@material-ui/core/AppBar';
import NavBarBinding from '../../navbar/bindings/NavBarBinding'
import translateAction from '../../../util/translate'
import StarIcon from '@material-ui/icons/Star'
import NotifyWaiter from './NotifyWaiter'
import { orderStates, STAND_MENU } from '../../../configs/constants'
import { isCategoryAvailableNow } from '../../../util/available'


const HIDE_GENERATE_QR_ORDER = true
const ORDER = 'ORDER'
const INFO_CATEGORIES = ["ALERGENI", "ALLERGENS", "ANAF", "INFO"]

const styles = theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    productsOrdered: {
        fontSize: theme.typography.pxToRem(15),
        flex: 1,
        textAlign: 'right'
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    fabTeal: {
        color: theme.palette.common.white,
        fontSize: 'larger',
        backgroundColor: teal[500],
        '&:hover': {
            backgroundColor: teal[600],
        },
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        marginLeft: 'auto',
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    progress: {
        margin: '33% auto', display: 'block'
    },


    paperRoot: {
        backgroundColor: '#ffffff'
    },

    listCategories: {
        display: 'flex',
        overflowX: 'scroll',
        overflowY: 'hidden',
        WebkitOverflowScrolling: 'touch',
        height: '90px',
        paddingTop: '4px',
        paddingBottom: '8px'
    },


    categoryContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 'max-content',
    },

    badge: {
        top: 10,
        right: 10,
        fontFamily: 'Oswald',
        fontSize: 12,
        color: '#ffffff',
        backgroundColor: '#000000',
        border: '2px solid #ffffff',
    },

    btnCategory: {
        margin: '4px 8px',
        width: '42px',
        height: '42px'
    },
    category: {
        width: '42px',
        height: '42px',
        color: 'rgba(42,46,54,0.7)',
        backgroundColor: '#d1d1d1',
    },
    selectedCategory: {
        width: '42px',
        height: '42px',
        backgroundColor: '#F1204C',
    },

    categoryIcon: {
        color: 'rgba(42,46,54, 0.7)',
    },

    selectedCategoryIcon: {
        color: '#ffffff'
    },

    categoryText: {
        fontSize: 'small',
        color: '#5F5F5F',
        fontFamily: `'Oswald', sans-serif`,
        width: '100px',
        overflow: 'hidden',
        maxHeight: '31px',
        lineHeight: 1.2,
        margin: '0px 4px',
    },
    selectedCategoryText: {
        fontSize: 'small',
        color: '#F1204C',
        fontFamily: `'Oswald', sans-serif`,
        maxHeight: '31px',
        lineHeight: 1.2,
        width: '100px',
        overflow: 'hidden',
        margin: '0px 4px',
    },


    productOf: {
        fontFamily: `'Oswald', sans-serif`,
        fontSize: 'larger',
        color: 'rgba(0,0,0, .5)'
    },
    link: {
        color: 'rgba(0,0,0, .33)',
        fontFamily: `'Oswald', sans-serif`,
        fontSize: 'small'
    }

})

class RestaurantMenuComponent extends Component {

    state = {
        selectedCategory: null,
        scrollPositions: {},
    };


    componentWillMount() {
        console.log('RestaurantMenuComponent -> CompoenentWillMount', this.props)
        this.props.getMenu()
    }

    componentDidMount() {
        console.log('RestaurantMenuComponent -> componentDidMount', this.props)
        this.props.getCurrentOrder()
    }
    
    componentWillReceiveProps(nextProps) {
        this.showBtnSendToWaiter = true

        if (this.props.notification !== nextProps.notification) {
            this.handleNotificationDisplay()
        }

        const oldOrder = this.props.order.filter(o => !isEmpty(o.order)).length > 0
        const currentOrder = nextProps.order.filter(o => !isEmpty(o.order)).length > 0
        if (oldOrder !== currentOrder && !currentOrder ){
            this.goToCategory(this.state.lastSelectedCategory || this.state.selectedCategory)
        }
    }

    componentDidUpdate() {
        if (this.state.selectedCategory === null && this.props.menu.length > 0) {
            const selectedCategory = this.state.lastSelectedCategory || this.props.menu[0].details.id
            this.setState({
                selectedCategory
            }, () => {
                setTimeout(() => {
                    try {
                        document.querySelector(`#${selectedCategory.replace(/[0-9]/g, '')}`).scrollIntoViewIfNeeded()
                    } catch (error) { }
                }, 150)
            })
        }

        window.history.pushState(null, null, window.location.href)
        window.onpopstate = (wind) => {
            wind.preventDefault()
            if (this.state.openGenerateOrderQR) {
                console.log('onBack -> close generated qrCode')
                this.setState({
                    openGenerateOrderQR: false
                },
                    () => window.history.go(1)
                )
            } else {
                console.log('onBack -> close a category or orderlist')
                const selectedCategory = this.state.lastSelectedCategory
                if (selectedCategory) {
                    this.setState({
                        display: '',
                        selectedCategory
                    },
                        () => {
                            window.history.go(1)
                            document.querySelector(`#${selectedCategory.replace(/[0-9]/g, '')}`).scrollIntoViewIfNeeded()
                        }
                    )
                }
            }
        }
    }

    translate = (text) => {
        return translateAction(text, this.props.language)
    }


    getStyles = () => {
        const styles = {
        }
        return styles
    }

    handleChangeCategory = panel => () => {
        console.log('RestaurantmenuCompoment - handleChangeCategory categoryId', panel)
        const listProducts = document.querySelector(`#listProducts`) 
        const scrollPositions = { ...this.state.scrollPositions, [this.state.selectedCategory]: listProducts === null ? 0 : listProducts.scrollTop }
        const y = this.state.scrollPositions[panel] || 0
        
        this.setState({
            selectedCategory: panel,
            scrollPositions
        }, () => {
            document.querySelector(`#listProducts`).scrollTo(0,y)
        });
    }

    goToCategory = panel => {
        console.log('RestaurantmenuCompoment - goToCategory  categoryId', panel)
        const listProducts = document.querySelector(`#listProducts`) 
        const scrollPositions = { ...this.state.scrollPositions, [this.state.selectedCategory]: listProducts === null ? 0 : listProducts.scrollTop }
        const y = this.state.scrollPositions[panel] || 0
        
        const category = panel.replace(/[0-9]/g, '')
        document.querySelector(`#${category}`).scrollIntoViewIfNeeded()

        this.setState({
            selectedCategory: panel,
            scrollPositions
        }, () => {
            document.querySelector(`#listProducts`).scrollTo(0,y)
        });
    }
    
    goToProduct = (categoryId, productId) => {
        console.log('RestaurantmenuCompoment - goToProduct categryid', categoryId, 'productId', productId)
        const category = categoryId.replace(/[0-9]/g, '')
        const product = productId.replace(/[0-9]/g, '')
        document.querySelector(`#${category}`).scrollIntoViewIfNeeded()

        this.setState({
            selectedCategory: category,
        }, () => {
            setTimeout(() => {
                document.querySelector(`#listProducts`).querySelector(`#${product}`).scrollIntoViewIfNeeded()
            }, 100)
        });
    }

    handleDeleteOrder = params => {
        console.log('handleDeleteOrder')
        this.props.deleteOrder(params)
    }


    handleDialogGeneratorOrderQR = (show) => {
        return this.setState({
            openGenerateOrderQR: show,
            display: ''
        })
    }

    
    callWaiterFor = (service) => {
        console.log('@RestaurantMenuComponent -> callWaiterFor', service)
        this.props.callWaiterFor(service)
    }



    handleOrderChange = newOrder => {
        let changedOrder = {}
        const { categoryId, productId, quantity } = newOrder

        if (newOrder.ORDER_NOT_SENT.order.categoryId === undefined) {
            let newUnverifiedOrders
            newUnverifiedOrders = this.props.order.filter(o => o.meta.status === orderStates.ORDER_NOT_SENT)[0]
            if (newUnverifiedOrders === undefined) {
                newUnverifiedOrders = { ...newOrder.ORDER_NOT_SENT } 
            }
            changedOrder = {
                ...newUnverifiedOrders,
                order: {
                    ...newUnverifiedOrders.order,
                    [categoryId]: { 
                        ...newUnverifiedOrders.order[categoryId] || {},
                        [productId]: { quantity }
                    }
                }
            }
        } else {
            changedOrder = {
                ...newOrder.ORDER_NOT_SENT,
                order: {
                    ...newOrder.ORDER_NOT_SENT.order,
                    [categoryId]: {
                        ...newOrder.ORDER_NOT_SENT.order[categoryId],
                        [productId]: {
                            ...newOrder.ORDER_NOT_SENT.order[categoryId][productId],
                            quantity
                        }
                    }
                }
            }
        }

        if (quantity === 0) {
            
            delete changedOrder.order[categoryId][productId]
            let nrItems = keys(changedOrder.order[categoryId]).length
            if (nrItems === 0) {
                delete changedOrder.order[categoryId]
            }
        }

        console.log('changedOrder', changedOrder)
        this.props.handleOrderChange(changedOrder)
    }


    listCategories = () => {
        const { classes, order, menu, language } = this.props
        const { selectedCategory } = this.state
        const translate = this.translate

        return menu
            .map(category => {
                console.log('category ->', { category })
                if (category.details.id === 'recommended' && category.items.length > 0) {
                    return (
                        <div id={`${category.details.id.replace(/[0-9]/g, '')}`} key={'recommended'} className={classes.categoryContainer}>
                            <Fab disableFocusRipple disableRipple
                                onClick={this.handleChangeCategory('recommended')}
                                className={classes.btnCategory}
                            >
                                <Avatar className={selectedCategory === 'recommended' ? classes.selectedCategory : classes.category}>
                                    <StarIcon />
                                </Avatar>
                            </Fab>
                            <Typography className={selectedCategory === 'recommended' ? classes.selectedCategoryText : classes.categoryText} align="center">
                                {translate('Recommended')}
                            </Typography>
                        </div>
                    )
                }


                let productsOrdered = 0
                order.forEach(o => { 
                    const categ = o.order[category.details.id]
                    if (categ) {
                        const prods = Object.keys(o.order[category.details.id])
                        prods.forEach(prod => { productsOrdered = productsOrdered + categ[prod].quantity })
                    }
                })

                let categoryName = ''
                try {
                    categoryName = category.details.name[language] || ''
                } catch (error) {
                    console.warn(language, category)
                }

                const { available, availabilities } = category.details
                let opacity = 1

                if (available) {
                    opacity = 1
                } else {
                    console.log({ category, availabilities })
                    const isAvailableNow = isCategoryAvailableNow(availabilities)
                        
                    if (!isAvailableNow) {
                        opacity = .75
                    }
                }

                return (
                    <div id={`${category.details.id.replace(/[0-9]/g, '')}`} key={category.details.id} className={classes.categoryContainer} style={{ opacity }}>
                        <Badge classes={productsOrdered === 0 ? null : { badge: classes.badge }} badgeContent={productsOrdered === 0 ? '' : productsOrdered}>
                            <Fab disableFocusRipple disableRipple
                                onClick={this.handleChangeCategory(category.details.id)}
                                className={classes.btnCategory}
                            >
                                {
                                    category.details.thumbImg 
                                        ? <Avatar className={selectedCategory === category.details.id ? classes.selectedCategory : classes.category} src={category.details.thumbImg} />
                                        : <Avatar className={selectedCategory === category.details.id ? classes.selectedCategory : classes.category}>
                                            <Typography variant="h6" className={selectedCategory === category.details.id ? classes.selectedCategoryIcon : classes.categoryIcon}>
                                                {categoryName[0]}
                                            </Typography>
                                        </Avatar>
                                }
                            </Fab>
                        </Badge>
                        <Typography className={selectedCategory === category.details.id ? classes.selectedCategoryText : classes.categoryText} style={{ opacity }} align="center">
                            {categoryName}
                        </Typography>
                    </div>
                )
            })
    }

    handleDisplayOrder = () => {
        const lastSelectedCategory = this.state.selectedCategory
        this.setState({
            display: this.state.display === ORDER ? '' : ORDER,
            lastSelectedCategory,
            selectedCategory: false,
        })
    }

    listPreparate = (selectedCategory) => {
        if (selectedCategory === null || selectedCategory === false) {
            return null
        }
        const { classes, language, order, menu, currency, accessToDirectOrders } = this.props
        const category = menu.filter(category => category.details.id === selectedCategory)[0]

        if (category === undefined) {
            this.setState({
                selectedCategory: this.props.menu[0].details.id
            })
            return null
        }

        const isThisCategoryAvailableNow = category.details.availabilities === undefined || category.details.availabilities.length === 0 ? true : isCategoryAvailableNow(category.details.availabilities)
        const isThisCategorySpecial = category.details.special
        const categoryPriceRules = category.details.priceRules

        const categoriesNotAvailableNow = menu.filter(c => c.details.id !== 'recommended').map(c => {
            if (c.details.availabilities === undefined || c.details.availabilities.length === 0) {
                return { id: c.details.id, available: true }
            } else {
                return { id: c.details.id, available: isCategoryAvailableNow(c.details.availabilities) }
            }
        }).filter(c => !c.available).map(c => c.id)
        
        
        const isThisAnInfoCategory = INFO_CATEGORIES.includes(category.details.name[language].toUpperCase())

        return <Items
            selectedCategory={selectedCategory}
            categoryPriceRules={categoryPriceRules}
            categoriesNotAvailableNow={categoriesNotAvailableNow}
            isThisCategoryAvailableNow={isThisCategoryAvailableNow}
            categoryAvailabilities={category.details.availabilities}
            isThisCategorySpecial={isThisCategorySpecial}
            isThisAnInfoCategory={isThisAnInfoCategory}
            data={category.items}
            language={language}
            currency={currency}
            classes={classes}
            handleOrderChange={newOrder => this.handleOrderChange(newOrder)}
            order={order}
            accessToDirectOrders={accessToDirectOrders}
        />
    }


    handleNotificationDisplay = () => {
        this.setState({ notificationOpen: true })
    }


    handleNotificationClose = () => {
        this.setState({ notificationOpen: false })
    }

    render() {
        console.log('Menu -> render', 'showBtnSendToWaiter', this.showBtnSendToWaiter)
        const { classes, theme, info, language, menu, order, restaurantId, tableId, logoUrl, currency, 
            accessToDirectOrders, callForWaiter, payWith, orderPaid, waitingOrderConfirmation, multiplePersons 
        } = this.props
        const { selectedCategory, lastSelectedCategory } = this.state

        console.log('lastSelectedCategory', lastSelectedCategory)
        if (menu === undefined) {
            return null
        }

        if (menu === 'LOADING' || !info) {
            return <CircularProgress className={classes.progress} color="secondary" />
        }

        if (menu.length === 0) {
            return <CircularProgress className={classes.progress} color="secondary" />
        }

        const translate = this.translate

        const listCategories = this.listCategories(menu.categories)
        const listPreparate = this.listPreparate(selectedCategory)

        const { openGenerateOrderQR } = this.state


        let haveOrder = order.filter(o => !isEmpty(o.order)).length > 0
        


        const transitionDuration = {
            enter: theme.transitions.duration.enteringScreen,
            exit: theme.transitions.duration.leavingScreen,
        };

        const fab = {
            className: classNames(classes.fab, classes.fabTeal),
        }

        const height = (window.screen.height - window.innerHeight - 56) > 0
            ? 'calc(100vh - 144px - 56px)'
            : 'calc(100vh - 144px)'

        return (
            <div>
                <AppBar className={classes.paperRoot} elevation={16} >
                    <NavBarBinding logoUrl={logoUrl} />
                    <div className={classes.listCategories}>{listCategories}</div>
                </AppBar>


                {HIDE_GENERATE_QR_ORDER || !haveOrder 
                    ? null
                    : <GenerateQROrder
                        open={openGenerateOrderQR || false}
                        onClose={() => this.handleDialogGeneratorOrderQR(false)}
                        order={JSON.stringify({ ...order, restaurantId, tableId })}
                    />
                }





                {haveOrder && selectedCategory === false 
                    ? <div id="orderlistContainer">
                        <OrderList
                            language={language}
                            currency={currency}
                            order={order}
                            menu={this.props.menu}
                            hideOrderList={this.handleDisplayOrder}
                            handleDialogGeneratorOrderQR={(show) => this.handleDialogGeneratorOrderQR(show)}
                            callWaiterFor={service => this.callWaiterFor(service)}
                            showBtnSendToWaiter={this.showBtnSendToWaiter}
                            accessToDirectOrders={accessToDirectOrders}
                            goToCategory={categoryId => this.goToCategory(categoryId)}
                            goToProduct={(categoryId, productId) => this.goToProduct(categoryId, productId)}
                            deleteOrder={params => this.handleDeleteOrder(params)}
                            payWith={payWith}
                            orderPaid={orderPaid}
                            waitingOrderConfirmation={waitingOrderConfirmation}
                            multiplePersons={multiplePersons}
                        />
                    </div>
                    : <div
                        id="listProducts"
                        style={{
                            overflowX: 'hidden',
                            overflowY: 'scroll',
                            WebkitOverflowScrolling: 'touch',
                            height,
                            position: 'fixed',
                            top: '144px',
                            backgroundColor: 'rgba(0,0,0 .15)',
                            width: '100%',
                            paddingBottom: haveOrder ? '64px' : '0px'
                        }}>{listPreparate}</div>
                }


                <div>
                    {
                        (haveOrder && selectedCategory !== false) &&
                        <Zoom
                            key={fab.color}
                            in={!isEmpty(order)}
                            timeout={transitionDuration}
                            style={{
                                transitionDelay: `${!isEmpty(order) ? transitionDuration.exit : 0}ms`,
                                
                            }}
                            unmountOnExit
                        >
                            <Fab
                                variant="extended"
                                className={fab.className}
                                onClick={this.handleDisplayOrder}
                                style={{
                                    zIndex: 99, left: 16, width: '70%'
                                }}
                                >
                                {translate('Order list')}
                            </Fab>
                        </Zoom>
                    }

                    {
                        (callForWaiter && tableId !== STAND_MENU) &&
                        <NotifyWaiter language={language} callWaiterFor={(serviceType) => this.callWaiterFor({ serviceType })} />
                    }

                </div>


                <Snackbar
                    key={this.props.notification && this.props.notification.open}
                    open={this.state.notificationOpen}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    onClose={this.handleNotificationClose}
                    autoHideDuration={4000}
                    message={<span id="message-id">{this.props.notification && this.props.notification.message}</span>}
                />
               
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(RestaurantMenuComponent)

