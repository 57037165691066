import { connect } from 'react-redux'
import * as actions from './../../restaurant_menu/actions'
import NavBarComponent from './../components/NavBarComponent'

const mapStateToProps = (state, ownProps) => {
    console.log('NavBarBinding -> mapStateToProps', {ownProps, state})
    return ({
        logoUrl: state.RESTAURANT.INFO === undefined ? null : state.RESTAURANT.INFO.logoUrl,
        language: state.CLIENT.SELECTED_LANGUAGE,
        languageLabel: (state.RESTAURANT.INFO.languages || []).filter(l => l.code === state.CLIENT.SELECTED_LANGUAGE)[0].label,
        waiterId: ownProps.waiterId,
        feedback: state.RESTAURANT.INFO.feedback,
        review: state.RESTAURANT.INFO.review,
    })
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return ({
        postFeedback: (feedbackForm) => dispatch(actions.postFeedback(feedbackForm)),
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBarComponent) 
