import { getTables } from './waiter/utils'
import { QR_CODE_DATA, getRestaurantInfo as getRestaurantInfoForWaiter } from '../modules/scan/actions'
import Fingerprint2 from 'fingerprintjs2'

export const RESTAURANT_INFO = 'INFO'
export const UPDATE_RESTAURANT_INFO = 'UPDATE_RESTAURANT_INFO'
export const RESTAURANT_ID = 'restaurantId'
export const RESTAURANT_ID_AND_TABLE_ID = 'RESTAURANT_ID_AND_TABLE_ID'
export const RESCAN_CODE = 'RESCAN_CODE'
export const RESET_RESTAURANT_INFO = 'RESET_RESTAURANT_INFO'
export const WAITER_ID = 'WAITER_ID'
export const UID_FINGERPRINT = 'UID_FINGERPRINT'
export const SIGNED_IN_SUCCESFULL = 'SIGNED_IN_SUCCESFULL'
export const SIGNED_IN_ERROR = 'SIGNED_IN_ERROR'


export const getRestaurantInfo = (restaurantUrl) => async (dispatch, getState, { getFirebase, getFirestore }) => {
    console.log('@appActions --> getRestaurantInfo -> restaurantUrl', restaurantUrl)
    const db = getFirestore()

    const refAllLanguages = await db.collection('languages').get()
    const allLanguages = refAllLanguages.docs.map(doc => ({ ...doc.data() }))
    
    let refRestaurant = await db.collection("restaurants")
        .where('url', '==', restaurantUrl)
        .get()
    
    let restaurant = refRestaurant.docs[0] && refRestaurant.docs[0].data()
    
    if (!restaurant) {
        refRestaurant = await db.collection("restaurants")
            .where('id', '==', restaurantUrl)
            .get()
        
        restaurant = refRestaurant.docs[0] && refRestaurant.docs[0].data()
        if (restaurant) {
            window.location.href = window.location.href.replace(restaurantUrl, restaurant.url)
        }
    }


    if (!restaurant) {
        console.warn('No such restaurant!')
        dispatch(rescan())
        return false
    }

    const localStorageCache = JSON.parse(localStorage.getItem('s')) || {};
    console.log({ localStorageCache })
    if (localStorageCache.r && (restaurant.id !== localStorageCache.r && restaurant.url !== localStorageCache.r)) {
        localStorage.removeItem('s')
        localStorage.removeItem('l')
    }
    
    console.log('@appActions - getRestaurantInfo ---->', { restaurant })

    // !!TODO: check if business has the rights to access to the product list for restaurant menu
    // const isEnabledAccessToRestaurantMenu = restaurant?.permissions?.accessToRestaurant !== undefined && restaurant.permissions.accessToRestaurant

    if (!restaurant.active) {
        console.warn('*** firestore Restaurant not available')

        dispatch({
            type: QR_CODE_DATA,
            payload: {}
        })
        
        dispatch(rescan())
        return false
    }
    
    const id = refRestaurant.docs[0].id
    const { url, languages, defaultCurrency, logoUrl, name, tablesConfig, permissions, accessToDirectOrders, callForWaiter, waiterCreateOrders, feedback={}, review={} } = restaurant
    
    const allTables = getTables({ tablesConfig })
    
    const isCallForWaiterPermited = permissions?.callForWaiter !== undefined 
        ? permissions.callForWaiter && callForWaiter
        : callForWaiter

    const isWaiterPermitedToCreateOrders = permissions?.waiterCreateOrders !== undefined
        ? permissions.waiterCreateOrders
        : waiterCreateOrders

    dispatch({
        type: RESTAURANT_INFO,
        payload: { 
            language: languages[0], 
            languages: languages.map(language => ({...allLanguages.filter(l => l.code === language)[0] })), 
            defaultCurrency, 
            logoUrl, 
            name, 
            tablesConfig,
            allTables,
            accessToDirectOrders, 
            callForWaiter: isCallForWaiterPermited,
            waiterCreateOrders: isWaiterPermitedToCreateOrders,
            url,
            feedback,
            review,
        }
    })
    return id
}

export const setRestaurantIdAndTableId = ({ restaurantId, tableId }) => dispatch => {
    
    return dispatch({
        type: RESTAURANT_ID_AND_TABLE_ID,
        payload: { restaurantId, tableId }
    })
}


// let userFingerprint
export const createUidFingerprint = () => async (dispatch, getState, { getFirebase, getFirestore }) => {
    const authResponse = await getFirebase().auth().signInAnonymously();
    const uidFingerprint = authResponse.user.uid;
    console.log('@appActions - createUidFingerprint (signInAnonymously)', { authResponse, uidFingerprint });

    return dispatch({
        type: UID_FINGERPRINT,
        payload: uidFingerprint
    })

    // return Fingerprint2.get(function (components) {
    //     const uidFingerprint = Fingerprint2.x64hash128(components.map(function (pair) { return pair.value }).join(), 31)
    //     return dispatch({
    //         type: UID_FINGERPRINT,
    //         payload: uidFingerprint
    //     })
    // })
}

export const doSignIn = ({ email, password }) => (dispatch, getState, { getFirebase, getFirestore }) => {
    const restaurantId = getState().SCANNER.restaurantId || getState().RESTAURANT.INFO.id
    const restaurantUrl = getState().SCANNER.restaurantUrl || getState().RESTAURANT.INFO.url
    console.log('@appActions -> doSignIn', { state: getState(), email, password, restaurantId, restaurantUrl })
    dispatch({
        type: SIGNED_IN_ERROR
    })
    
    getFirebase()
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(async ({ user }) => {
            console.log(user)
            const waiterId = user.uid
            
            const response = await dispatch(getRestaurantInfoForWaiter({ restaurantId, restaurantUrl, waiterId }))
            console.log('response signIn', response)
            if (!response) {
                return dispatch({
                    type: SIGNED_IN_ERROR,
                    payload: 'Waiter login - error'
                })
            }
            localStorage.setItem('l', JSON.stringify({ e: email, p: password }))
            dispatch({
                type: QR_CODE_DATA,
                payload: { restaurantUrl, restaurantId, waiterId }
            })
        })
        .catch((error) => {
            console.log({ error })
            dispatch({
                type: SIGNED_IN_ERROR,
                payload: error.message
            })
        });
}

export const rescan = () => dispatch => {
    localStorage.removeItem('s')
    localStorage.removeItem('l')
    dispatch({ type: RESCAN_CODE })
    dispatch({ type: RESET_RESTAURANT_INFO })
    
}