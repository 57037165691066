
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import keys from 'lodash/keys'
import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import GridList from '@material-ui/core/GridList'
import Avatar from '@material-ui/core/Avatar'
import Badge from '@material-ui/core/Badge'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { green, orange } from '@material-ui/core/colors';
import { orderStates, tableIs } from '../../../configs/constants';
import { getInfoFrom } from '../utils'

const styles = theme => ({
    root: {
        width: '100%',
        flexGrow: 1,
        maxWidth: 1000,
        margin: '0 auto'
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    tabs: {
        '& .MuiTabs-flexContainer': {
            flexWrap: 'wrap',
            width: '100%',
            justifyContent: 'space-between'
        },
        '& .Mui-selected': {
            backgroundColor: '#f0f0f0',
            borderBottom: '3px solid red',
            zIndex: -1
        },
        '& .MuiTabs-indicator': {
            display: 'none'
        }
    },
    orangeAvatar: {
        minWidth: 68,
        minHeight: 68,
        margin: 15,
        color: '#fff',
        flexDirection: 'column',
        backgroundColor: orange[500],

    },
    anotherWaiterAvatar: {
        minWidth: 70,
        minHeight: 70,
        margin: 15,
        color: '#fff',
        flexDirection: 'column',
        backgroundColor: '#ad728b',
    },
    greenAvatar: {
        minWidth: 70,
        minHeight: 70,
        margin: 15,
        color: '#fff',
        flexDirection: 'column',
        backgroundColor: green[500],

    },
    grayAvatar: {
        minWidth: 70,
        minHeight: 70,
        margin: 15,
        color: '#fff',
        flexDirection: 'column',

    },
    gridList: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 10,
    },
    badgeWaiter: {
        top: 27,
        right: 17,
        fontFamily: 'Oswald',
        fontSize: 12,
        lineHeight: 14,
        color: '#fff',
        backgroundColor: '#444',
        border: '2px solid #444',
        width: '90%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        justifyContent: 'flex-start',
    },
    badgeInfo: {
        top: 20,
        right: 20,
        fontFamily: 'Oswald',
        fontSize: 12,
        lineHeight: 14,
        color: '#ffffff',
        backgroundColor: 'steelblue',
        border: '2px solid #ffffff',
        width: 48
    },

    badgeWantToOrder: {
        top: 52,
        right: 5,
        fontFamily: 'Oswald',
        fontSize: 12,
        lineHeight: 14,
        color: '#ffffff',
        backgroundColor: 'coral',
        border: '2px solid #ffffff',
        width: 48
    },

    badgeOrder: {
        top: 52,
        right: 5,
        fontFamily: 'Oswald',
        fontSize: 12,
        lineHeight: 14,
        color: '#ffffff',
        backgroundColor: 'red',
        border: '2px solid #ffffff',
        width: 48
    },

    badgePay: {
        top: 85,
        right: 20,
        fontFamily: 'Oswald',
        fontSize: 12,
        lineHeight: 14,
        color: '#ffffff',
        backgroundColor: 'black',
        border: '2px solid #ffffff',
        width: 48
    },

    paid: {
        borderColor: '#00ff00',
        borderStyle: 'double',
        borderWidth: 5
    },
    badgePadding: {
        padding: theme.spacing(0, 1),
    }
})





const fixDecimals = amount => {
    let value = `${amount}`.split('.')[0]
    let decimals = `${amount}`.split('.')[1]
    if (value === '0' && decimals !== undefined) {
        decimals = (decimals + '00').substr(0, 3)
    } else {
        if (decimals === undefined) {
            decimals = '00'
        } else {
            decimals = `${decimals}0`.substr(0, 2)
        }
    }
    if (decimals === '00') {
        return `${value}`
    }
    return `${value}.${decimals}`
}

const getTablePaimentStatus = (persons) => {
    let hasToPay = 0
    let paid = 0
    if (Array.isArray(persons)) {
        for (const person of persons) {
            for (const order of (person.orders || [])) {
                for (const categoryId of keys(omit(order, ['id', 'status', 'timestamp', 'state']))) {
                    for (const productId of keys(order[categoryId])) {
                        const quantity = order[categoryId][productId].quantity
                        let price1 = order[categoryId][productId].price1
                        const productTotal = quantity * price1
                        hasToPay = hasToPay + productTotal
                    }
                }
            }
            paid = paid + (parseFloat(person.paid) || 0)
        }
    }

    hasToPay = hasToPay === 0 ? '' : fixDecimals(hasToPay)

    return { hasToPay, paid }
}

class Table extends Component {

    state = {
        infoTimeAgo: '',
        wantToOrderTimeAgo: '',
        orderTimeAgo: '',
        payTimeAgo: '',
        howLong: ''
    }

    getMinutes = (time) => {
        const h = parseInt(time.split(':')[0], 10)
        const m = parseInt(time.split(':')[1], 10)
        const mins = h * 60 + m
        if (mins < 2) {
            return `NOW`
        }
        return `${mins} m`
    }

    getTimes = () => {
        const { INFO, WANT_TO_ORDER, ORDER, PAY, PAY_CASH, PAY_CARD, timestamp } = this.props.tableData

        if (INFO || WANT_TO_ORDER || ORDER || PAY || PAY_CASH || PAY_CARD || timestamp) {

            let infoTimeAgo = '', wantToOrderTimeAgo = '', orderTimeAgo = '', payTimeAgo = '', howLong = ''
            
            if (INFO) { infoTimeAgo = this.getMinutes(new Date(Date.now() - INFO).toGMTString().substr(18, 4)) }
            if (WANT_TO_ORDER) { wantToOrderTimeAgo = this.getMinutes(new Date(Date.now() - WANT_TO_ORDER).toGMTString().substr(18, 4)) }
            if (ORDER) { orderTimeAgo = this.getMinutes(new Date(Date.now() - ORDER).toGMTString().substr(18, 4)) }
            if (PAY) { payTimeAgo = this.getMinutes(new Date(Date.now() - PAY).toGMTString().substr(18, 4)) }
            if (PAY_CASH) { payTimeAgo = this.getMinutes(new Date(Date.now() - PAY_CASH).toGMTString().substr(18, 4)) }
            if (PAY_CARD) { payTimeAgo = this.getMinutes(new Date(Date.now() - PAY_CARD).toGMTString().substr(18, 4)) }

            if (timestamp) { howLong = new Date(Date.now() - new Date(timestamp)).toGMTString().substr(18, 4) }

            if (this.refeshTable !== null) {
                this.setState({ infoTimeAgo, wantToOrderTimeAgo, orderTimeAgo, payTimeAgo, howLong })
            }
        }
    }


    componentWillMount() {
        this.getTimes()
        this.refeshTable = setInterval(() => this.getTimes(), 1000 * 4)
    }

    shouldComponentUpdate(nextProps, nextState) {
        if ((this.props.tableData.status !== nextProps.tableData.status) && (nextProps.tableData.status === tableIs.OPEN)) {
            this.getTimes()
            this.refeshTable = setInterval(() => this.getTimes(), 1000 * 4)
            return true
        } 

        if ((this.props.tableData.status !== nextProps.tableData.status) && (nextProps.tableData.status === tableIs.CLOSED)) {
            this.refeshTable = null
            return true
        }

        return this.refeshTable !== null && (!isEqual(this.props.tableData, nextProps.tableData) || !isEqual(this.state, nextState))
    }

    componentWillUnmount() {
        this.refeshTable = null
    }


    handleClick = ({ restaurantOrderId, tableId }) => {
        console.log('@Table -> handleClick', tableId)
        this.props.handleSelectTable({ restaurantOrderId, tableId })
    }

    render() {
        console.log('@Table -> render', (this.props.tableData.tableId || this.props.tableData), { tableData: this.props.tableData, state: this.state })
        const { classes, accessToDirectOrders, tableData, tableId } = this.props
        console.log(tableId)
		const tableNumber = tableId.split(':')[1]
        const { waiterName, status, INFO, WANT_TO_ORDER, ORDER, PAY, PAY_CASH, PAY_CARD } = tableData
        const tableHasOrder = tableData.tableId
        
        let { infoTimeAgo, wantToOrderTimeAgo, orderTimeAgo, payTimeAgo, howLong } = this.state
        if (status === tableIs.CLOSE) {
            infoTimeAgo = ''
            wantToOrderTimeAgo = ''
            orderTimeAgo = ''
            payTimeAgo = ''
            howLong = ''
        }

        let displayTimeAgo = false
        let orderModifiedByWaiter = false

        if (this.props.tableData !== undefined && Array.isArray(this.props.tableData.persons)) {
            displayTimeAgo = this.props.tableData.persons.filter(p => Array.isArray(p.orders) && p.orders.filter(o => o.status === orderStates.ORDER_UNVERIFIED).length > 0).length > 0
            orderModifiedByWaiter = tableData && tableData.persons.filter(p => (p.orders || []).filter(o => o.state === orderStates.ORDER_MODIFIED_BY_WAITER).length > 0).length > 0
            if (orderModifiedByWaiter) {
                orderTimeAgo=''
            }
        }

        const { hasToPay, paid } = getTablePaimentStatus(tableData.persons)
        const MONEY_ON_TABLE = parseFloat(tableData.moneyOnTable) || 0
        
        const paidStatus =  MONEY_ON_TABLE + paid > 0 && MONEY_ON_TABLE + paid >= (parseFloat(hasToPay) || 0) 

        const ANOTHER_WAITER = (!tableData.waiter && waiterName)

        if (PAY || PAY_CASH || PAY_CARD) {
            const multiplePersons = Array.isArray(tableData.persons) && tableData.persons.length > 1
            let payAllTable = false
            let payWith = null

            if (tableData['PAY-extra'] !== undefined && Array.isArray(tableData['PAY-extra'])) {
                
                payWith =  (tableData['PAY-extra'][0] || {}).payWith
                const isPaingAllTable = tableData['PAY-extra'].filter(extra => extra.payAllTable)[0]
                if (isPaingAllTable) {
                    payWith = isPaingAllTable.payWith
                    payAllTable = true
                } 
            } else {
                if (PAY_CASH) { payWith = 'CASH' }  
                if (PAY_CARD) { payWith = 'CARD' }
            }

            console.log({ PAY, payAllTable, payTimeAgo, payWith, multiplePersons }, 'parseInt(payTimeAgo, 10) < 100', (parseInt(payTimeAgo, 10) < 100))
            if ((!multiplePersons || payAllTable) && payWith && (isNaN(parseInt(payTimeAgo, 10)) || parseInt(payTimeAgo, 10) < 5)) {
                payTimeAgo = payWith
            }
        }
        
        const tableStatus = accessToDirectOrders 
            ? `${tableHasOrder ? (tableData.waiter === '' ?  classes.orangeAvatar : (ANOTHER_WAITER ? classes.anotherWaiterAvatar : classes.greenAvatar)) : classes.grayAvatar} ${paidStatus ? classes.paid : {}}`
            : `${classes.greenAvatar}`

        return (
            <div 
                style={{ width: 130, height: 100 }} 
                onClick={() => ANOTHER_WAITER ? null : this.handleClick({ restaurantOrderId: tableData.id, tableId })}>
                <Badge classes={{ badge: classes.badgeWaiter }} badgeContent={ANOTHER_WAITER ? `${waiterName}` : null}>
                    <Badge classes={{ badge: classes.badgeInfo }} badgeContent={(INFO && infoTimeAgo !== '') ? `${infoTimeAgo}` : null}>
                        <Badge classes={{ badge: classes.badgeOrder }} badgeContent={((ORDER && displayTimeAgo && orderTimeAgo !== '') || orderModifiedByWaiter) ? `${orderTimeAgo}` : null}>
                            <Badge classes={{ badge: classes.badgeWantToOrder }} badgeContent={(WANT_TO_ORDER && wantToOrderTimeAgo !== '') ? `${wantToOrderTimeAgo}` : null}>
                                <Badge classes={{ badge: classes.badgePay }} badgeContent={((PAY || PAY_CASH || PAY_CARD) && payTimeAgo !== '') ? `${payTimeAgo}` : null}>
                                    <Avatar className={tableStatus}>
                                        <Typography variant="h6" style={{ lineHeight: '24px' }}>{tableNumber}</Typography>
                                        { accessToDirectOrders && <Typography variant="body2" style={{ lineHeight: '15px' }}>{howLong}</Typography> }
                                        { accessToDirectOrders && <Typography variant="subtitle2" style={{ lineHeight: '15px' }}>{hasToPay}</Typography> }
                                    </Avatar>
                                </Badge>
                            </Badge>
                        </Badge>
                    </Badge>
                </Badge>
            </div>
        )
    }
}



const ListTables = (props) => {
    const [selectedTabIndex, changeTab] = useState(0)
    const { classes, selectedTable, openOrders, alocatedTables, accessToDirectOrders, selectTable } = props

    console.log('ListTables', { props })
    const handleSelectTable = (table) => {
		console.log('selectTable ->', table)
		selectTable(table)
	}


    function TablesPanel(props) {
        const { areaName, areaTables } = props;
        const tables = areaTables.map(table => {
            const tableData = openOrders.filter(ot => ot.tableId === table)[0] || table
            const tableId = tableData.tableId || table
            return (
                <Table
                    classes={classes}
                    key={`waiterTable_${areaName}:${table}`}
                    tableId={tableId}
                    tableData={tableData}
                    handleSelectTable={handleSelectTable}
                    isSelected={selectedTable === `${table}`}
                    accessToDirectOrders={accessToDirectOrders}
                />
            )
        })

        return (
            <GridList cellHeight={100} className={classes.gridList} style={{ margin: 0 }}>
                {tables}
            </GridList>
        )
    }
    

    const waiterTables = ((alocatedTables === undefined || alocatedTables.length === 0) ? openOrders : alocatedTables)
    

	const handleTabChange = (event, tabIndex) => {
		changeTab(tabIndex);
	};


    const areaNames = Object.keys(waiterTables).map(areaName => {
        let tabLabel = areaName
        const ordersFromWaitersTables = openOrders.filter(({ tableId }) => areaName === getInfoFrom(tableId).areaName && waiterTables[areaName].indexOf(tableId) > -1)

        console.log(areaName, waiterTables, { openOrders, ordersFromWaitersTables }, (ordersFromWaitersTables && ordersFromWaitersTables.length))
        
        if (ordersFromWaitersTables) {
            const newNotifications = ordersFromWaitersTables.filter(order => {
                const { INFO, WANT_TO_ORDER, ORDER, PAY_CASH, PAY_CARD } = order
                return (INFO || WANT_TO_ORDER || ORDER || PAY_CASH || PAY_CARD)
            })

            tabLabel = (
                <Badge key={areaName} className={classes.badgePadding} badgeContent={newNotifications.length}>
                    {areaName}
                </Badge>
            )
        }
    

        return (
            <Tab key={areaName} label={tabLabel} />
        )
    }) 

    const selectedArea = Object.keys(waiterTables)[selectedTabIndex]
    
    return (
        <div className={classes.root}>
            <Tabs className={classes.tabs}
                value={selectedTabIndex} 
                onChange={handleTabChange} 
            >
                {areaNames}
            </Tabs>
            <TablesPanel areaName={selectedArea} areaTables={waiterTables[selectedArea]} />
        </div>
    )
}

ListTables.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ListTables)
