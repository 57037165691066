export default `
    Menu                    = Menú
    Welcome                 = Bienvenida
    Price                   = Precio
    Add                     = Añadir
    Remove                  = Eliminar
    Order list              = Su pedido
    Order                   = Pedido
    Place order             = Generra su pedido
    Tables                  = Las mesas
    Pay                     = Precio
    Recommended             = Recomendado
    Total                   = Total
`

