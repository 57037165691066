export default `
    Menu                    = メニュー
    Welcome                 = 歓迎
    Price                   = 価格
    Add                     = 加えます
    Remove                  = 取り除きます
    Order list              = 注文リスト
    Order                   = オーダー
    Place order             = 注文する
    Tables                  = テーブル
    Pay                     = 支払います
    Recommended             = 推奨
    Total                   = トータル
`

