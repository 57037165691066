import axios from 'axios';
import orderBy from 'lodash/orderBy';
import * as constants from './constants';
import { orderStates, URL_ONLINE_ORDERS } from '../../../../configs/constants';

const {
  ORDER_OPEN,
  ORDER_NEW,
  ORDER_ACCEPTED,
  ORDER_PREPARING,
  ORDER_DELIVERING,
  ORDER_PENDING,
  ORDER_CONFIRMED,
  ORDER_READY_FOR_DELIVER,

  ORDER_MODIFIED_BY_WAITER,
  ORDER_UNVERIFIED,
  ORDER_NOT_SENT,
  ORDER_DELIVERED,
  ORDER_CANCELED_CUSTOMER_REQUEST,
  ORDER_CANCELED_LONG_WAITED,
  ORDER_CANCELED_ORDER_BY_MISTAKE,
  ORDER_CANCELED_OUT_OF_STOCK,
  ORDER_CANCELED,
  ORDER_CLOSED
} = orderStates;

export const ONLINE_ORDERS = `${constants.MODULE_NAME}/ONLINE_ORDERS`;

export const bindToOnlineOrders = () => async (dispatch, getState, { getFirebase, getFirestore }) => {
  console.log('@Dispatcher -> actions -> bindToOnlineOrders');

  const restaurantId = getState().SCANNER.restaurantId;
  console.log({ restaurantId });

  const refOnlineOrders = getFirestore().collection(`restaurants/${restaurantId}/onlineOrders`)
    .where('orderDetails.status', 'in', 
      [
        ORDER_OPEN,
        ORDER_NEW,
        ORDER_ACCEPTED,
        ORDER_PREPARING,
        ORDER_READY_FOR_DELIVER,
        ORDER_DELIVERING,
      ]
    );

  refOnlineOrders.onSnapshot({ includeMetadataChanges: true }, (snapshotOnlineOrders) => {
    const onlineOrders = orderBy(
      snapshotOnlineOrders.docs.map((doc) => {
        const docData = doc.data();
        console.log(doc.id, docData)
        try {
          return {
            id: doc.id,
            ...docData,
            timestamp: docData.orderDetails.createdAt.toMillis(),
          };
        } catch(err) {
          console.warn({ docId: doc.id, docData, err })
          return {
            id: doc.id,
            ...docData,
            timestamp: `${docData.orderDetails.createdAt}`
          }
        }
      }),
      ['timestamp'],
      ['desc']
    );
    console.log('getOpenedOrders', { onlineOrders, snapshotOnlineOrders: snapshotOnlineOrders.docs });

    dispatch({
      type: ONLINE_ORDERS,
      payload: onlineOrders,
    });
  });
};


export const setOrderState = ({ orderDocId, newOrderState }) => (dispatch, getState, { getFirebase, getFirestore }) => {
  console.group('@Dispatcher - actions - setOrderState');
  const restaurantId = getState().SCANNER.restaurantId;

  const payload = { 
    orderDetails: newOrderState
  }
  
  let skipEmail = [ORDER_ACCEPTED, ORDER_PREPARING, ORDER_DELIVERING].includes(newOrderState.status) ? '' : '?skipEmail=true'
  if (newOrderState.status === ORDER_PREPARING && newOrderState.delay) {
    skipEmail = ''
  }

  axios.patch(`${URL_ONLINE_ORDERS}/${restaurantId}/${orderDocId}${skipEmail}`, payload);
}
