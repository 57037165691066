import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';

import Scan from './modules/Scan';
import Customer from './modules/Customer';
import Waiter from './modules/Waiter';
import Login from './modules/Login';

const Error = () => {
  return <Redirect to="/" path="/" />;
};

const App = () => {
  console.log('===============================');
  console.log('<App>');
  console.log({ store });
  console.log('</App>');
  console.log('===============================');

  return (
    <Provider store={store}>
      <HashRouter>
        <Switch>
          <Route exact key="scan" path={'/'} component={Scan} />
          {/*<Route exact key="menu" path={'/:restaurantUrl/menu'} component={Customer} />*/}
          <Route exact key="customer" path={'/:restaurantUrl/:tableId'} component={Customer} />
          <Route exact key="login" path={'/login'} component={Login} />
          <Route exact key="waiter" path={'/:restaurantUrl'} component={Waiter} />
          <Route key="notfound" component={Error} />
        </Switch>
      </HashRouter>
    </Provider>
  );
};

export default App;
