import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ReadOrderQR from './ReadOrderQR';
import { Redirect } from 'react-router';
import Typography from '@material-ui/core/Typography';
// import agileHubLogo from '../../../assets/images/agilehub64.png'

const styles = (theme) => ({
  progress: {
    margin: '33% auto',
    display: 'block',
  },
  flex: {
    flex: 1,
  },
  productOf: {
    fontFamily: `'Oswald', sans-serif`,
    fontSize: 'larger',
    color: 'rgba(0,0,0, .5)',
  },
  link: {
    color: 'rgba(0,0,0, .33)',
    fontFamily: `'Oswald', sans-serif`,
    fontSize: 'small',
  },
});

const ScannerComponent = (props) => {
  useEffect(() => {
    if (props.s !== null) {
      props.handleQRCodeData({ restaurantId: props.s.r, waiterId: props.s.w });
    }
  }, []);

  console.log('ScannerComponent', { props });
  const { restaurantUrl, tableId, waiterId, order, classes } = props;

  if (order !== undefined || (restaurantUrl !== undefined && waiterId !== undefined)) {
    return <Redirect to={`/${restaurantUrl}`} push={true} />;
  }

  if (restaurantUrl !== undefined && tableId !== undefined) {
    return <Redirect to={`/${restaurantUrl}/${tableId}`} push={true} />;
  }

  
  if (restaurantUrl !== undefined && waiterId === undefined && tableId === undefined) {
    return <Redirect to={`/login`} push={true} />;
  }

  return (
    <div>
      <ReadOrderQR handleQRCodeData={(qrCodeData) => props.handleQRCodeData(qrCodeData)} />

      {/* <div style={{ position: 'fixed', bottom: 24, left: 0, right: 0, opacity: .85, pointerEvents: 'none' }}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography className={classes.productOf}>{`PRODUCT OF AGILEHUB`}</Typography>
                    <Typography className={classes.link}>{`www.agilehub.ro`}</Typography>
                </div>
            </div> */}
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(ScannerComponent);
