export default `
    Menu                    = Menü
    Welcome                 = Karşılama
    Price                   = Fiyat
    Add                     = Eklemek
    Remove                  = Kaldırmak
    Order list              = Sipariş listesi
    Order                   = Sipariş
    Place order             = Sipariş vermek
    Tables                  = Tablolar
    Pay                     = Ödeme
    Recommended             = Tavsiye
    Total                   = Toplam
`

