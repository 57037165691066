import LANGUAGES from '../configs/languages'

export default (text, toLanguage) => {
    const dictionar = LANGUAGES.filter((d) => d.language === toLanguage)[0]
    if (dictionar !== undefined && dictionar.translated !== null) {
        let startIndex = dictionar.translated.indexOf(text)
        if (startIndex !== -1) {
            let indexKey = startIndex + text.length
            let str = dictionar.translated.substring(indexKey)

            if (str.substr(0, str.indexOf('= ')).trim().length === 0) {
                return str.substring(str.indexOf('= ') + 2, str.indexOf('\n')).replace(new RegExp('{', 'g'), '${')
            }

            while (true) {
                startIndex = str.indexOf(text)
                if (startIndex !== -1) {
                    indexKey = startIndex + text.length
                    str = str.substring(indexKey)
                    if (str.substr(0, str.indexOf('= ')).trim().length === 0) {
                        return str.substring(str.indexOf('= ') + 2, str.indexOf('\n')).replace(new RegExp('{', 'g'), '${')
                    }
                } else {
                    return text
                }
            }

        }

        return text
    }
    return text
}
