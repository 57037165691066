import { connect } from 'react-redux'
import WaiterComponent from './../components/WaiterComponent'
import * as actions from './../actions'
import * as constants from './../constants'

const mapStateToProps = (state, ownProps) => {
    console.log('WaiterBinding -> mapStateToProps', state, ownProps)
    return ({
        logoUrl: state.RESTAURANT.INFO === undefined ? null : state.RESTAURANT.INFO.logoUrl,
        language: state.RESTAURANT.INFO === undefined ? null : state.RESTAURANT.INFO.language,
        currency: state.RESTAURANT.INFO === undefined ? null : state.RESTAURANT.INFO.defaultCurrency.toUpperCase(),
        allTables: state.RESTAURANT.INFO === undefined ? null : state.RESTAURANT.INFO.allTables,
        selectedTable: state[constants.MODULE_NAME][actions.SELECTED_TABLE],
        menu: state[constants.MODULE_NAME][actions.MENU],
        scannedData: state.SCANNER,
        orders: state[constants.MODULE_NAME][actions.ORDERS] || { ...state.SCANNER.order },
        lastSelectedCategory: state[constants.MODULE_NAME][actions.LAST_SELECTED_CATEGORY],
        openOrders: state[constants.MODULE_NAME][actions.OPEN_ORDERS],
        waiterInfo: state[constants.MODULE_NAME][actions.WAITER_INFO],
        restaurantOrderId: state[constants.MODULE_NAME][actions.RESTAURANT_MENU].restaurantOrderId, 
        personId: state[constants.MODULE_NAME][actions.RESTAURANT_MENU].personId || state[constants.MODULE_NAME][actions.DISPLAY_ORDER].personId, 
        remainsToPay: state[constants.MODULE_NAME][actions.DISPLAY_ORDER].remainsToPay, 
        display: state[constants.MODULE_NAME][actions.RESTAURANT_MENU].display,
        displayOrder: state[constants.MODULE_NAME][actions.DISPLAY_ORDER].display,
        feedbacks: state[constants.MODULE_NAME][actions.FEEDBACKS] || [],
        waiterCreateOrders: state.RESTAURANT.INFO === undefined ? null : state.RESTAURANT.INFO.waiterCreateOrders,
        accessToDirectOrders: state.RESTAURANT.INFO === undefined ? null : (state.RESTAURANT.INFO.accessToDirectOrders && state.RESTAURANT.INFO.waiterCreateOrders),
    })
}

const mapDispatchToProps = (dispatch, ownProps) => {
    console.log('WaiterBinding -> mapDispatchToProps ', ownProps)

    return ({
        getWaiterLanguage: () => dispatch(actions.getWaiterLanguage()),
        selectTable: ({ restaurantOrderId, tableId}) => dispatch(actions.selectTable({ restaurantOrderId, tableId })),
        getMenu: () => dispatch(actions.getMenu()),
        handleOrderChange: (newOrder) => dispatch(actions.handleOrderChange(newOrder)),
        handleQRCodeData: (qrCodeData) => dispatch(actions.handleQRCodeData(qrCodeData)),
        clearTable: (selectedTable) => dispatch(actions.clearTable(selectedTable)),
        lastCategoryChange: (selectedCategory) => dispatch(actions.lastCategoryChange(selectedCategory)),
        
        clearTableNotifications: ({ restaurantOrderId, tableId}) => dispatch(actions.clearTableNotifications({ restaurantOrderId, tableId })),
        waiterLogout: () => dispatch(actions.waiterLogout()),
        
        getOpenedOrders: () => dispatch(actions.getOpenedOrders()),
        getSelectedTableOrders: selectedTableOrder => dispatch(actions.getSelectedTableOrders(selectedTableOrder)),
        
        setOrderStatus: ({ restaurantOrderId, personId, personOrderId, status }) => dispatch(actions.setOrderStatus({ restaurantOrderId, personId, personOrderId, status })),
        restaurantMenu: ({ personId, display }) => dispatch(actions.restaurantMenu({ personId, display })),
        displayOrder: ({ personId, display, remainsToPay }) => dispatch(actions.displayOrder({ personId, display, remainsToPay })),
        addNewPersonAtTable: ({ restaurantOrderId, personId }) => dispatch(actions.addNewPersonAtTable({ restaurantOrderId, personId })),
        
        doPayment: ({ restaurantOrderId, personId, amount }) =>  dispatch(actions.doPayment({ restaurantOrderId, personId, amount })),
        closeTable: (params) =>  dispatch(actions.closeTable(params)),
        removePerson: ({ restaurantOrderId, personId }) =>  dispatch(actions.removePerson({ restaurantOrderId, personId })),
        clearAndBlockPerson: ({ restaurantOrderId, personId }) =>  dispatch(actions.clearAndBlockPerson({ restaurantOrderId, personId })),
        clearUnverifiedOrder: ({ restaurantOrderId, personId }) =>  dispatch(actions.clearUnverifiedOrder({ restaurantOrderId, personId })),

        getLiveFeedbacks: () => dispatch(actions.getLiveFeedbacks()),

        saveAssigningTablesChanges: (assignedTables) => dispatch(actions.saveAssigningTablesChanges(assignedTables)),
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(WaiterComponent) 
