import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import CircularProgress from '@material-ui/core/CircularProgress';
import OrderStatesTabs from './OrderStatesTabs';
import { orderStates } from '../../../../../configs/constants';
import OrderCard from './OrderCard';
import DialogView from './DialogView';
import AlertDialogView from './AlertDialogView';
import translateAction from '../../../../../util/translate'
import red from '@material-ui/core/colors/red';


const useStyles = makeStyles({
  progress: {
    margin: '33% auto', display: 'block'
  },
  noOrders: {
    textAlign: 'center',
    marginTop: '25vh'
  }
});

let LAST_OPEN_ORDERS = null
let LAST_INDEX_TAB = null


const { 
  ORDER_OPEN,
  ORDER_ACCEPTED, 
  ORDER_DECLINED, 
  ORDER_PREPARING, 
  ORDER_READY_FOR_DELIVER, 
  ORDER_DELIVERING,
  ORDER_CANCELED
} = orderStates;


const tabsPositions = [
  ORDER_OPEN, 
  ORDER_ACCEPTED, 
  ORDER_PREPARING, 
  ORDER_READY_FOR_DELIVER, 
  ORDER_DELIVERING, 
];

const ONE_MINUTE = 60 * 1000

const millisFrom = delay => {
  return parseInt(delay) * ONE_MINUTE
}

const calculateDelays = orders => orders.map(order => {
  const { delay, deliveryDate } = order.orderDetails
  console.log('@calculateDelays --->', { id:order.id,  order, delay, deliveryDate })
  let timestamp
    try {
      timestamp = (deliveryDate.toMillis() + millisFrom(delay || 0)) 
    } catch(err) {
      const createdAt = order.id
      console.warn('@calculateDelays --->', { id: order.id, order })
      return { ...order, createdAt }
    }
    return { ...order, timestamp }
})



const ListOrders = ({ style, openDialog, translate, selectedTab, orders, setOrderState, updateDeliveryDate }) => {
  console.log('@ListOrders', { selectedTab, orders, setOrderState })

  return (
    <List style={style}>
      {orders.map((order) => 
        <OrderCard 
          translate={translate}
          openDialog={openDialog}
          key={`id_${order.id}`} 
          selectedTab={selectedTab} 
          order={order}
          setOrderState={(newOrderState) => setOrderState({ orderDocId: order.id, newOrderState })}
        />
      )}
    </List>
  )
} 


const DispathcerView = (props) => {
  const classes = useStyles();
  
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [alertDialogData, setAlertDialogData] = useState({});
  const [selectedTab, setSelectedTab] = useState(null);
  const [onlineOrdersGrouped, setOnlineOrdersGrouped] = useState(null);
  const { language, onlineOrders=[], setOrderState } = props;

  useEffect(() => {
    const groupOnlineOrders = async () => {
      
      const timeStampsErrors = onlineOrders.filter(o => typeof(o.timestamp) !== 'number')
      if (timeStampsErrors.length > 0) {
        console.warn({ timeStampsErrors })
      }

      let onlineOrdersData = groupBy(onlineOrders.filter(o => typeof(o.timestamp) === 'number'), 'orderDetails.status');
      
      console.log({ onlineOrdersData_ORDER_OPEN :onlineOrdersData[ORDER_OPEN] })
      if (onlineOrdersData[ORDER_OPEN]) {
        onlineOrdersData = { ...onlineOrdersData, [ORDER_OPEN]: orderBy(onlineOrdersData[ORDER_OPEN], ['timestamp'], ['asc']) }
          const CURRENT_OPEN_ORDERS = onlineOrdersData[ORDER_OPEN].map(order => order.id)
          console.log({ LAST_OPEN_ORDERS, CURRENT_OPEN_ORDERS })

          for (let i=0; i < CURRENT_OPEN_ORDERS.length; i++) {
            if (!LAST_OPEN_ORDERS || !LAST_OPEN_ORDERS.includes(CURRENT_OPEN_ORDERS[i])) {
              LAST_OPEN_ORDERS = [ ...(LAST_OPEN_ORDERS || []), CURRENT_OPEN_ORDERS[i] ]
              await document.getElementById('audio').play()
              setTimeout(() => {
                if (document.getElementById(ORDER_OPEN)) {
                  document.getElementById(ORDER_OPEN).style.color = red[500]
                }
              }, 100)

              handleOpenAlertDialog({
                dialogContentText: translate('New order!'),
                textFieldLabel: '',
                dialogCancel: null,
                handleDialogOk: () => {
                  setSelectedTab(ORDER_OPEN)
                  document.getElementById('audio').pause()
                }
              })

              break
            }
          }
      }

      if (onlineOrdersData[ORDER_PREPARING]) {
        let ordered = orderBy(calculateDelays(onlineOrdersData[ORDER_PREPARING]), ['timestamp'], ['asc'])
        console.log(ordered.map(o => o.timestamp))
        onlineOrdersData = { ...onlineOrdersData, [ORDER_PREPARING]: ordered }
      }

      if (onlineOrdersData[ORDER_DELIVERING]) {
        onlineOrdersData = { ...onlineOrdersData, [ORDER_DELIVERING]: orderBy(calculateDelays(onlineOrdersData[ORDER_DELIVERING]), ['timestamp'], ['asc']) }
      }
      
      setOnlineOrdersGrouped(onlineOrdersData);
    };

    groupOnlineOrders();
  }, [onlineOrders]);


  const translate = text => {
    return translateAction(text, language)
  }

  const handleOpenDialog = dialogData => {
    console.log('@open dialog ---->',{ dialogData })
    setOpenDialog(true)
    setDialogData(dialogData)
  }
  
  const handleDialogClose = (obs) => {
    if (typeof(obs) === 'string') {
      console.log('@handleDialogClose has obs? ', { obs })
      setOrderState({
        orderDocId: dialogData.order.id,
        newOrderState: { status: ORDER_CANCELED, [`${ORDER_CANCELED}-OBS`]: obs }
      })
    }

    setOpenDialog(false)
    setDialogData({})
  }
  
  const handleOpenAlertDialog = alertDialogData => {
    console.log('@open alert dialog ---->',{ alertDialogData })
    setOpenAlertDialog(true)
    setAlertDialogData(alertDialogData)
  }

  const handleAlertDialogClose = () => {
    setOpenAlertDialog(false)
    setAlertDialogData({})
  }


  const handleTabChanged = (selectedTab) => {
    
    if (!selectedTab && LAST_INDEX_TAB > 0) {
      LAST_INDEX_TAB = LAST_INDEX_TAB - 1;
      setSelectedTab(availableOrderStates[LAST_INDEX_TAB]);
    } else {
      LAST_INDEX_TAB = availableOrderStates.indexOf(selectedTab)
      setSelectedTab(selectedTab);
    }
  };


  if (onlineOrdersGrouped === null) {
    return <CircularProgress className={classes.progress} color="secondary" />
  }

  const tabs = Object.keys(onlineOrdersGrouped);
  let availableOrderStates = [];
  for (const tab of tabsPositions) {
    if (tabs.includes(tab)) {
      availableOrderStates.push(tab);
    }
  }


  if (!availableOrderStates.length) {
    return (
      <div>
        <Typography variant="h5" className={classes.noOrders}>No orders</Typography>
      </div>
    )
  }

  if (!selectedTab && availableOrderStates[0] && LAST_INDEX_TAB === null) {
    handleTabChanged(availableOrderStates[0])
    return null
  } 


  if (selectedTab && !onlineOrdersGrouped[selectedTab]) { 
    handleTabChanged(availableOrderStates[LAST_INDEX_TAB])
  }

  if (!selectedTab && availableOrderStates[0] && LAST_INDEX_TAB > -1) {
    handleTabChanged(availableOrderStates[LAST_INDEX_TAB])
    return null
  }

  if (selectedTab === ORDER_OPEN) {
    if (document.getElementById(ORDER_OPEN)) {
      document.getElementById(ORDER_OPEN).style.color = ''
    }
  }

  console.log('@DispatcherView', { props, selectedTab, LAST_INDEX_TAB, availableOrderStates, onlineOrdersGrouped })
  return (
    <div>
      <OrderStatesTabs 
        translate={(text) => translate(text)}
        availableOrderStates={availableOrderStates} 
        handleTabChanged={(orderState) => handleTabChanged(orderState)} 
        selectedTabIndex={availableOrderStates.indexOf(selectedTab)}
        onlineOrdersGrouped={onlineOrdersGrouped}
      />

      <ListOrders 
        style={{ maxWidth: 700, margin: '0 auto' }}
        translate={(text) => translate(text)}
        selectedTab={selectedTab} 
        orders={onlineOrdersGrouped[selectedTab] || []} 
        setOrderState={setOrderState}
        openDialog={(dialogData) => handleOpenDialog(dialogData)}
      />

      {openDialog &&
        <DialogView 
          dialogData={dialogData} 
          handleDialogClose={(obs) => handleDialogClose(obs)}
        />
      }
      
      {openAlertDialog &&
        <AlertDialogView 
          dialogData={alertDialogData} 
          handleAlertDialogClose={handleAlertDialogClose}
        />
      }
    </div>
  );
};


export default DispathcerView;