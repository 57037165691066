import React, { useState } from 'react'
import translateAction from '../../../util/translate'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import FeedbackIcon from '@material-ui/icons/FeedbackOutlined'
import InstagramIcon from '../../../assets/images/ic_instagram.png'
import FacebookIcon from '../../../assets/images/ic_facebook.png'
import GoogleIcon from '../../../assets/images/ic_google.png'
import TripadvisorIcon from '../../../assets/images/ic_tripadvisor.png'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import MenuActions from '@material-ui/core/Menu'
import Fade from '@material-ui/core/Fade'
import ChooseLanguageBinding from '../../choose_language/bindings/ChooseLanguageBinding'
import Feedback from '../../feedback/Feedback'

import { LanguageRow } from '../../choose_language/components/ChooseLanguageComponent'
import MenuItem from '@material-ui/core/MenuItem'


const styles = theme => ({
    root: {
        ...theme.typography.button,
        padding: theme.spacing(1),
        fontSize: '.7rem',
        flexGrow: 1
    }
})

const MenuOptions = ({ classes, languages, language, postFeedback, feedback, review }) => {
    const [values, setValues] = useState({})
    const { anchorEl, languagesAnchorEl, feedbackAnchorEl } = values

    const openMenuView = Boolean(anchorEl)
    const openLanguagesView = Boolean(languagesAnchorEl)
    const openFeedbackView = Boolean(feedbackAnchorEl)


    const handle = name => event => {
        if (name === 'close') {
            return setValues({})
        }

        let val = event ? event.currentTarget : null
        if (val === null) {
            return setValues({ ...values, anchorEl: null, [name]: null })
        }

        setValues({ ...values, [name]: val })
    }


    const handleReview = url => event => {
        console.log('Open review page to ', url)
        const win = window.open(url, '_blank')
        win.focus()
        return setValues({})
    }

    const hasFedbackQuestionInCurrentLanguage = Array.isArray(feedback.questions) && feedback.questions.some(question => question[language.code])
    console.log('MenuOptions', { feedback, review, hasFedbackQuestionInCurrentLanguage, language })
    return (
        <div>
            <div>
                <IconButton style={{ zIndex: 2 }} aria-controls="simple-menu" aria-haspopup="true" onClick={handle('anchorEl')}>
                    <MoreVertIcon />
                </IconButton>

                <MenuActions id="simple-menu" anchorEl={anchorEl} open={openMenuView} onClose={handle('close')}>
                    <div>
                        <LanguageRow classes={classes} language={language} aria-owns={openLanguagesView ? 'languages-view' : null} aria-haspopup="true" onClick={handle('languagesAnchorEl')} />
                    </div>

                    {
                        feedback.activateFeedback && hasFedbackQuestionInCurrentLanguage &&
                        <Divider />
                    }

                    {
                        feedback.activateFeedback && hasFedbackQuestionInCurrentLanguage &&
                        <MenuItem aria-owns={openFeedbackView ? 'feedback-view' : null} aria-haspopup="true" onClick={handle('feedbackAnchorEl')}>
                            <FeedbackIcon style={{ paddingRight: '6px' }} />
                            <Typography className={classes.root}>{`${translateAction('Feedback', language)}`}</Typography>
                        </MenuItem>
                    }

                    
                    {
                        review.activateReviewFB && (!!review.facebookUrl || !!review.googleUrl || !!review.instagramUrl || !!review.tripAdviserUrl) &&
                        <Divider />
                    }

                    {
                        review.activateReviewFB && !!review.facebookUrl &&
                        <MenuItem onClick={handleReview(review.facebookUrl)}>
                            <img src={FacebookIcon} style={{ width: '24px', position: 'relative', left: '-3px' }} alt="" />
                            <Typography className={classes.root}>Facebook</Typography>
                        </MenuItem>
                    }

                    {
                        review.activateReviewGoogle && !!review.googleUrl &&
                        <MenuItem onClick={handleReview(review.googleUrl)}>
                            <img src={GoogleIcon} style={{ width: '24px', position: 'relative', left: '-3px' }} alt="" />
                            <Typography className={classes.root}>Google</Typography>
                        </MenuItem>
                    }

                    {
                        review.activateReviewInstagram && !!review.instagramUrl &&
                        <MenuItem onClick={handleReview(review.instagramUrl)}>
                            <img src={InstagramIcon} style={{ width: '24px', position: 'relative', left: '-3px' }} alt="" />
                            <Typography className={classes.root}>Instagram</Typography>
                        </MenuItem>
                    }

                    {
                        review.activateReviewTripAdviser && !!review.tripAdviserUrl &&
                        <MenuItem onClick={handleReview(review.tripAdviserUrl)}>
                            <img src={TripadvisorIcon} style={{ width: '24px', position: 'relative', left: '-3px' }} alt="" />
                            <Typography className={classes.root}>Tripadvisor</Typography>
                        </MenuItem>
                    }

                </MenuActions>

                <div>
                    <MenuActions id="languages-view" anchorEl={languagesAnchorEl} TransitionComponent={Fade} open={openLanguagesView} onClose={handle('close')}>
                        <div>
                            <ChooseLanguageBinding closeLanguageOptions={handle('languagesAnchorEl')} />
                        </div>
                    </MenuActions>
                </div>

                <div>
                    <MenuActions id="feedback-view" anchorEl={feedbackAnchorEl} TransitionComponent={Fade} open={openFeedbackView}>
                        <div>
                            <Feedback 
                                language={language.code} 
                                questions={feedback.questions}
                                postFeedback={feedbackForm => postFeedback(feedbackForm)} 
                                onClose={handle('feedbackAnchorEl')} 
                            />
                        </div>
                    </MenuActions>
                </div>
            </div>
        </div>
    )
}


const NavBarComponent = (props) => {

    const { logoUrl, classes, language, languageLabel, postFeedback, feedback, review } = props

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                height: '56px',
                padding: '0px 0px 0px 18px',
                alignItems: 'center',
                background: '#fff'
            }}
        >
            <img
                style={{
                    height: '40px',
                    flex: 1,
                    objectFit: 'contain',
                    objectPosition: 'left',
                    verticalAlign: 'middle'
                }}
                src={logoUrl}
                alt=""
            />
            
            <MenuOptions 
                classes={classes} 
                language={{ code: language, label: languageLabel }} 
                feedback={feedback} 
                review={review} 
                postFeedback={feedbackForm => postFeedback(feedbackForm)} 
            />
        </div>
    )
}

export default withStyles(styles)(NavBarComponent)
