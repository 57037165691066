import React from 'react'
import { TextField, Divider, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core'
import Slide from '@material-ui/core/Slide'
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied'
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied'
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied'
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined'
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied'
import Rating from '@material-ui/lab/Rating'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import translateAction from '../../util/translate'
import { useState } from 'react'
import { FEEDBACK_TYPE } from '../../configs/constants'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})



const smiliesIcons = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon />,
        label: 'Very Dissatisfied'
    },
    2: {
        icon: <SentimentDissatisfiedIcon />,
        label: 'Dissatisfied'
    },
    3: {
        icon: <SentimentSatisfiedIcon />,
        label: 'Neutral'
    },
    4: {
        icon: <SentimentSatisfiedAltIcon />,
        label: 'Satisfied'
    },
    5: {
        icon: <SentimentVerySatisfiedIcon />,
        label: 'Very Satisfied'
    }
}


function SmiliesIconContainer(props) {
    const { value, ...other } = props
    return (
        <span {...other}>
            {smiliesIcons[value].icon}
        </span>
    )
}


SmiliesIconContainer.propTypes = {
    value: PropTypes.number.isRequired
}



export default function Feedback(props) {
    const { language, questions, onClose, postFeedback } = props
    console.log('Feedback', { language, questions, onClose, props })

    const [feedbackForm, setFeedbackAnswers] = useState(questions)


    const translate = text => {
        return translateAction(text, language)
    }
    

    const handleSend = e => {
        console.log('Feedback -> handleSend', feedbackForm)
        postFeedback({ feedbackForm })
        onClose()
    }

   

    const closeFeedbackView = () => {
        onClose()
    }

    const handleFeedbackChange = e => {
        const { name: feedbackIndex, value } = e.target
        const updatedAnswers = feedbackForm.map((feedback, index) => {
            if (index === parseInt(feedbackIndex, 10)) {
                return ({ ...feedback, answer: value })
            }
            return feedback
        })
        console.log('Feedback -> updatedAnswers', {updatedAnswers})
        setFeedbackAnswers(updatedAnswers)
    }

    const feedbackFormView = feedbackForm.map((feedback, index) => (
        <div key={`f-${index}`} style={{ padding: 10 }}>
            
            <Typography component="legend">{feedback[language]}</Typography>
            
            {
                feedback.type === FEEDBACK_TYPE.RATING &&
                <div style={{ paddingTop: 10 }}>
                    <Rating name={`${index}`} defaultValue={3} getLabelText={value => smiliesIcons[value].label} 
                        IconContainerComponent={SmiliesIconContainer} 
                        onChange={handleFeedbackChange}
                    />
                </div>
            }

            {
                feedback.type === FEEDBACK_TYPE.TEXT &&
                <div style={{ paddingTop: 10 }}>
                    <TextField
                        name={`${index}`}
                        fullWidth
                        style={{ backgroundColor: 'rgba(0,0,0, .1)', borderRadius: '8px', padding: '0px 8px' }}
                        InputProps={{ disableUnderline: true }}
                        rows={8}
                        rowsMax={12}
                        multiline
                        onBlur={handleFeedbackChange}
                    />
                </div>
            }
        </div>
    ))



    return (
        <Dialog open TransitionComponent={Transition} fullScreen style={{ margin: '5%', borderRadius: 4 }}>
            <DialogTitle>{`${translate('Your feedback matters')}`}</DialogTitle>

            <Divider />

            <DialogContent id="feedbackContentView">
                {feedbackFormView}
            </DialogContent>

            <DialogActions>
                <Button onClick={closeFeedbackView} color="primary" autoFocus>
                    {`${translate('CANCEL')}`}
                </Button>

                <Button onClick={handleSend} color="primary" autoFocus>
                    {`${translate('SEND')}`}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
