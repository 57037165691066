import uniqueId from 'lodash/uniqueId'
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import translateAction from '../../../util/translate'
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import getWeekDays from '../../../util/weekdays'
import { orderStates } from '../../../configs/constants'

import { LazyLoadImage } from 'react-lazy-load-image-component';
import placeholderImg from '../../../assets/images/placeholder_200.png'
import styles from './styles'

const fixDecimals = amount => {
    let value = `${amount}`.split('.')[0]
    let decimals = `${amount}`.split('.')[1]
    if (value === '0' && decimals !== undefined) {
        decimals = (decimals + '00').substr(0, 3)
    } else {
        if (decimals === undefined) {
            decimals = '00'
        } else {
            decimals = `${decimals}0`.substr(0, 2)
        }
    }
    if (decimals === '00') {
        return `${value}`
    }
    return `${value}.${decimals}`
}


class Item extends Component {

    state = { expanded: this.props.isThisCategorySpecial || false, order: {} };


    handleExpandClick = () => {
        this.setState({ expanded: !this.state.expanded });
    };


    translate = (text) => {
        return translateAction(text, this.props.language)
    }

    
    onAdd = item => event => {
        const newOrderPortions = (this.props.newOrderPortions || 0) + 1
        this.props.setOrder({ ...item, quantity: newOrderPortions })

        event.stopPropagation()
    }

    onRemove = item => event => {
        const newOrderPortions = (this.props.newOrderPortions || 0) - 1
        this.props.setOrder({ ...item, quantity: newOrderPortions })

        event.stopPropagation()
    }

    getLastPrice = (product, categoryPriceRules) => {

        let lastPrice = product.price

        if (categoryPriceRules !== undefined && categoryPriceRules.length > 0) {
            const categoryPriceRule = categoryPriceRules.filter(cpr => new Date(cpr.startDate) <= Date.now() && new Date(cpr.endDate) >= Date.now())[0]
            if (categoryPriceRule !== undefined) {
                if (categoryPriceRule.type === 'percent') {
                    lastPrice = product.price * (1 - (categoryPriceRule.value / 100))
                }
                if (categoryPriceRule.type === 'value') {
                    lastPrice = product.price - categoryPriceRule.value
                }
            }
        } else if (product.ranges !== undefined) {
            const specialOffer = product.ranges
                .filter(r => r.days.includes(new Date().getDay() || 7))
                .filter(r =>
                    (
                        (new Date().setHours(r.startTime.split(':')[0], r.startTime.split(':')[1]) <= Date.now())
                        &&
                        (Date.now() <= new Date().setHours(r.endTime.split(':')[0], r.endTime.split(':')[1]))
                    )
                )
            lastPrice = specialOffer[specialOffer.length - 1] === undefined ? product.price : specialOffer[specialOffer.length - 1].price
        }

        if (lastPrice % 1 !== 0) {
            return lastPrice.toFixed(2)
        }
        return lastPrice
    }

    checkIfIsInTimeRange = product => {
        if (product.ranges === undefined) {
            return false
        }
        const specialOffer = product.ranges
            .filter(r => r.days.includes(new Date().getDay() || 7))
            .filter(r => 
                (
                    (new Date().setHours(r.startTime.split(':')[0], r.startTime.split(':')[1]) <= Date.now()) 
                        &&
                    (Date.now() <= new Date().setHours(r.endTime.split(':')[0], r.endTime.split(':')[1]))
                )
            )
        
        return specialOffer.length > 0
    }

    render() {
        console.log('*** item -> ', this.props)
        const translate = this.translate
        const { classes, orderedPortions, language, currency, isThisCategoryAvailableNow, isThisCategorySpecial, categoryPriceRules } = this.props;
        const { available, area, id, thumbImg, name, price, weight } = this.props.item

        const lastPrice = this.getLastPrice(this.props.item, categoryPriceRules)

        const description = this.props.item.description[language] === undefined ? '' : this.props.item.description[language]

        const productId = id.replace(/[0-9]/g, '')

        let isInTimeRange = true
        let timeRangesForThisSpecialCategory = []
        if (isThisCategorySpecial) {
            isInTimeRange = this.checkIfIsInTimeRange(this.props.item)
            timeRangesForThisSpecialCategory = (this.props.item.ranges || []).map(range => {
                const weekdays = getWeekDays(language)
                const days = range.days.map(dayNumber => weekdays[dayNumber])
                return (
                    <Typography  key={uniqueId()} className={classes.contentText} style={{ fontSize: 12, paddingBottom: 4 }}>{`[${days}] [${range.startTime} - ${range.endTime}] - ${range.price} ${currency}`}</Typography>
                )
            })
        }
        
        console.log({ isThisCategorySpecial, isInTimeRange, lastPrice })

        return (
            <div id={productId}>
                <ListItem style={{ padding: '4px 10px' }}>
                    <Card className={classes.card} onClick={this.handleExpandClick}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: 150 }}>
                            {available === false &&
                                <div>
                                    <Typography className={classes.recommended} >{translate('Not available')}</Typography>
                                </div>
                            }
                            <LazyLoadImage
                                wrapperClassName={classes.cover}
                                height={130}
                                width={130} 
                                placeholderSrc={placeholderImg}
                                src={thumbImg || placeholderImg}
                                loading="lazy"
                            />
                            <CardContent className={classes.details} style={{ padding: 10 }}>
                                <div className={classes.content}>
                                    <Typography className={classes.contentText}>{`${name[language]} ${weight || ''}`}</Typography>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        {!isThisCategorySpecial && price !== lastPrice && <Typography className={classNames(classes.contentText, classes.biggerPrice)} variant="caption" color="textSecondary">{`${price} ${currency}`}</Typography>}
                                        {(isThisCategorySpecial && !isInTimeRange) ? null :
                                            <Typography
                                                className={(price !== lastPrice) || (isThisCategorySpecial && isInTimeRange)
                                                    ? classNames(classes.contentText, classes.price, classes.bestPrice)
                                                    : classNames(classes.contentText, classes.price)
                                                } variant="caption" color="textSecondary">{`${lastPrice} ${currency}`}</Typography>
                                        }
                                    </div>
                                </div>


                                <div className={classes.controls} style={{ justifyContent: orderedPortions === 0 ? 'center' : 'space-between' }} >
                                    {
                                        orderedPortions === 0 ? null
                                            : (
                                                <IconButton className={classes.btn} aria-label={`${translate('Remove')}`} onClick={this.onRemove({ product: { area, productId: id, price1: lastPrice } })} style={{ touchAction: 'none' }}>
                                                    <RemoveCircleOutlineIcon />
                                                </IconButton>
                                            )
                                    }

                                    {
                                        orderedPortions === 0 ? null
                                            : (
                                                <Typography className={classes.orderedPortions} onClick={this.onAdd({ product: { area, productId: id, price1: lastPrice } })}>{orderedPortions}</Typography>
                                            )
                                    }

                                    <IconButton className={classes.btn} aria-label={`${translate('Add')}`} onClick={this.onAdd({ product: { area, productId: id, price1: lastPrice } })} style={{touchAction: 'none', visibility: (available && ((isThisCategoryAvailableNow && !isThisCategorySpecial) || (isThisCategorySpecial && isInTimeRange))) ? 'visible' : 'hidden'}}>
                                        <AddCircleOutlineIcon />
                                    </IconButton>
                                    
                                </div>


                                <div className={classes.contentPay}>
                                    {
                                        orderedPortions === 0 ? null
                                            : (
                                                <div className={classes.contentPay}>
                                                    <div style={{ width: '50px', height: '1px', backgroundColor: '#888', marginBottom: '4px' }} />
                                                    <Typography className={classes.pay}>
                                                        {`${translate('Pay')}: ${fixDecimals(lastPrice * orderedPortions)} ${currency}`}
                                                    </Typography>
                                                </div>
                                            )
                                    }
                                </div>
                                {(description.length > 0 || timeRangesForThisSpecialCategory.length > 0) &&
                                    <CardActions className={classes.actions} disableSpacing>
                                        <IconButton
                                            className={classNames(classes.expand, {
                                                [classes.expandOpen]: this.state.expanded,
                                            })}
                                            onClick={this.handleExpandClick}
                                            aria-expanded={this.state.expanded}
                                            aria-label="Show more"
                                        >
                                            <ExpandMoreIcon />
                                        </IconButton>
                                    </CardActions>
                                }

                            </CardContent>
                        </div>

                        {(description.length > 0 || timeRangesForThisSpecialCategory.length > 0) &&
                            <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                                <CardContent className={classes.infoText}>
                                    <div style={{ whiteSpace: 'pre-wrap', lineHeight: 1 }} dangerouslySetInnerHTML={{ __html: description }} />
                                    {timeRangesForThisSpecialCategory.length > 0 
                                        && <div style={{ 
                                            paddingTop: '8px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start'}}
                                        >{timeRangesForThisSpecialCategory}</div>
                                    }
                                </CardContent>
                            </Collapse>
                        }


                    </Card>
                </ListItem>
            </div>
        );
    }
}




class Items extends Component {

    state = {
        order: {}
    }

    componentWillMount() {
        console.log('Items -> CompoenentWillMount', this.props)
    }

    translate = (text) => {
        return translateAction(text, this.props.language)
    }

    setOrder = (item) => {
        console.log('**** set order', item)
        this.props.handleOrderChange(item)
    }

    listItems = (data) => {
        const { classes, orders, selectedCategory, isThisCategoryAvailableNow, isThisCategorySpecial, categoriesNotAvailableNow, categoryPriceRules } = this.props

        return data.map(prep => {
            let orderedPortions = 0
            let newOrderPortions = 0
            let ORDER_MODIFIED_BY_WAITER = { status: orderStates.ORDER_MODIFIED_BY_WAITER }

            orders.forEach(order => { 
                const categ = order[prep.categoryId]
                if (categ) {
                    const prod = order[prep.categoryId][prep.id]
                    if (prod) {
                        orderedPortions = orderedPortions + prod.quantity  
                        if (order.status === orderStates.ORDER_MODIFIED_BY_WAITER || order.status === orderStates.ORDER_UNVERIFIED) {
                            newOrderPortions = newOrderPortions + prod.quantity
                            ORDER_MODIFIED_BY_WAITER = { ...order }
                        }
                    }
                }
            })


            if (selectedCategory === 'recommended' && categoriesNotAvailableNow.includes(prep.categoryId)) {
                return null
            }
            
            return (
                <Item
                    key={prep.id}
                    item={{ ...prep }}
                    classes={classes}
                    language={this.props.language}
                    currency={this.props.currency}
                    setOrder={(order) => this.setOrder({ ...ORDER_MODIFIED_BY_WAITER, ...order, categoryId: prep.categoryId })}
                    orderedPortions={orderedPortions}
                    newOrderPortions={newOrderPortions}
                    isRecommendedSelected={selectedCategory === 'recommended'}
                    isThisCategoryAvailableNow={isThisCategoryAvailableNow}
                    isThisCategorySpecial={isThisCategorySpecial}
                    categoryPriceRules={categoryPriceRules}
                />
            )
        })

    }

    render() {
        const { data, categoryAvailabilities, classes, language } = this.props
        console.log(this.props, this.state)

        if (data === undefined) {
            return null
        }

        let cardAvailabilities = null
        if (categoryAvailabilities) {
            cardAvailabilities = categoryAvailabilities.map(range => {
                const weekdays = getWeekDays(language)
                const days = range.days.map(dayNumber => weekdays[dayNumber])

                return (
                    <Card key={uniqueId()} className={classes.cardCategoryAvailabilities}>
                        <CardHeader style={{ padding: 0, background: 'teal' }}
                            title={
                                <div style={{ textAlign: 'center', color: 'white', padding: 5 }}>
                                    <Typography variant="body2" style={{ textAlign: 'center', color: 'white' }}>{`${days}`}</Typography>
                                    <Typography variant="body2" style={{ textAlign: 'center', color: 'white' }}>{`${range.startTime} - ${range.endTime}`}</Typography>
                                </div>
                            }
                        />
                    </Card>
                )
            })
        }

        const listItems = this.listItems(data)

        return (
            <List id="listProducts" style={{ maxWidth: 700, margin: '0 auto' }}>
                {cardAvailabilities}
                {listItems}
            </List>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Items)
