import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import keys from 'lodash/keys'
import * as actions from './actions'
import { orderStates } from '../../configs/constants'

const INITIAL_STATE = {
    [actions.ORDERS]: [],
    [actions.OPEN_ORDERS]: [],
    [actions.RESTAURANT_MENU]: {},
    [actions.DISPLAY_ORDER]: {},
}


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case actions.LAST_SELECTED_CATEGORY:
        case actions.LANGUAGE:
        case actions.WAITER_INFO:
        case actions.MENU:
        case actions.RESTAURANT_MENU: 
        case actions.DISPLAY_ORDER: 
        case actions.FEEDBACKS: 
        {
            return {
                ...state,
                [action.type]: action.payload
            }
        }

        case actions.SELECTED_TABLE: 
        {
            if (action.payload === state[action.type]) {

                if (isEmpty(state[actions.ORDERS][action.payload])) {
                    return {
                        ...state,
                        [action.type]: '',
                        [actions.ORDERS]: omit(state[actions.ORDERS], [action.payload])
                    }
                }

                return {
                    ...state,
                    [action.type]: '',
                    [actions.LAST_SELECTED_CATEGORY]: null
                }
            }
            return {
                ...state,
                [action.type]: action.payload
            }
        }


        case actions.ORDERS_QR_CODE:
        {

            const stateSelectedTable = isEmpty(state[actions.SELECTED_TABLE]) ? action.payload.tableId : state[actions.SELECTED_TABLE]
            const currentOrder = state[actions.ORDERS] === undefined ? {} : state[actions.ORDERS][stateSelectedTable] || {}
            if (stateSelectedTable === undefined 
                || (
                    (
                        (action.payload.order === undefined && isEmpty(action.payload)) || 
                        (action.payload.order !== undefined && (isEmpty(action.payload.order) || !isEmpty(currentOrder)))
                    ) && !isEmpty(currentOrder))
                ) {
                return actions.selectTable !== undefined ? { ...state, [actions.SELECTED_TABLE]: stateSelectedTable } : { ...state }
            }

            if (action.type === actions.ORDERS_QR_CODE) {
                return {
                    ...state,
                    [actions.ORDERS]: {
                        ...state[actions.ORDERS],
                        [stateSelectedTable]: action.payload.order
                    },
                    [actions.SELECTED_TABLE]: stateSelectedTable,
                    scannedData: {}
                }
            }

            let tableOrder = { ...currentOrder }
            for (const categ of keys(action.payload)) {
                tableOrder = { ...tableOrder, [categ]: { ...currentOrder[categ], ...action.payload[categ] } }
            }

            for (const categ of keys(tableOrder)) {
                for (const item of keys(tableOrder[categ])) {
                    let cant = tableOrder[categ][item]
                    if (cant === 0) {
                        delete tableOrder[categ][item]
                    }
                }
                let nrItems = keys(tableOrder[categ]).length
                if (nrItems === 0) {
                    delete tableOrder[categ]
                }
            }

            return {
                ...state,
                [actions.ORDERS]: {
                    ...state[actions.ORDERS],
                    [stateSelectedTable]: tableOrder
                }
            }
        }


        case actions.ORDERS: 
        {
            const { 
                ordersId, personId, orders, 
                restaurantId, waiter, tableId 
            } = action.payload
            

            if (ordersId === null) {
                const newOrder = state[actions.OPEN_ORDERS].filter(soo => soo.id === undefined)[0] || { id: null, restaurantId, waiter, tableId, status: orderStates.ORDER_UNVERIFIED }
                const newPerson = newOrder.persons === undefined || newOrder.persons.filter(p => p.id === personId)[0] === undefined

                
                return {
                    ...state,
                    [actions.OPEN_ORDERS]: [
                        ...state[actions.OPEN_ORDERS].filter(soo => soo.id),
                        {
                            ...newOrder,
                            persons: newPerson 
                                ? [ 
                                    ...(newOrder.persons || []), 
                                    { id: personId, orders } 
                                ]
                                : [ 
                                    newOrder.persons.map(p => {
                                        if (p.id !== personId) {return p }
                                        return { ...p, orders }
                                    }) 
                                ]
                        }
                    ]    
                }
            }

            return {
                ...state,
                [actions.OPEN_ORDERS]: 
                    state[actions.OPEN_ORDERS].map(soo => {
                        if(soo.id !== ordersId) { return soo }
                        return {
                            ...soo,
                            persons: soo.persons.map(p => {
                                if (p.id !== personId) { return p }
                                return { 
                                    ...p, orders 
                                }
                            })
                        }
                    })
            }
        }

        

        case actions.OPEN_ORDERS:
        {
            const s = [ ...state[actions.OPEN_ORDERS] ]
            const ordersModifiedByWaiter = s.filter(so => so.persons && so.persons.filter(p => (p.orders || []).filter(o => o.state === orderStates.ORDER_MODIFIED_BY_WAITER).length > 0).length > 0)
            
            const ordersWaiterIds = ordersModifiedByWaiter.map(ow => ow.id)
            let newOpenedOrdersState = [ ...action.payload.filter(ap => !ordersWaiterIds.includes(ap.id)) ]

            const ordersWithSameIds =  [ ...action.payload.filter(ap => ordersWaiterIds.includes(ap.id)) ]

            for (const waiterOrder of ordersModifiedByWaiter) {
                const payloadOrder = ordersWithSameIds.filter(o => o.id === waiterOrder.id)[0] || {}
                const wPerson = waiterOrder.persons.filter(p => p.orders.filter(o => o.state === orderStates.ORDER_MODIFIED_BY_WAITER).length > 0)[0]
                
                newOpenedOrdersState = [
                    ...newOpenedOrdersState,
                    {
                        ...payloadOrder,
                        persons: [
                            ...(payloadOrder.persons || []).map(person => {
                                if (person.id !== wPerson.id) { return person }
                                return { 
                                    ...wPerson, 
                                }
                            })
    
                        ]
                    }    
                ]
            }

            return {
                ...state,
                [action.type]: [ ...newOpenedOrdersState ]
            }
        }    
        
        
        case actions.ORDER_CONFIRMED: 
        {
            const { personId, tableId } = action.payload

            const newOpenedOrdersState = state[actions.OPEN_ORDERS].map(so => {
                if (so.tableId !== tableId) { 
                    return so 
                }

                if (so.persons.filter(p => p.orders.filter(o => o.state === orderStates.ORDER_MODIFIED_BY_WAITER)).length === 0) {
                    return so
                }
                
                return {
                    ...so,
                    persons: so.persons.map(p => {
                        if (p.id !== personId) { return p }
                        return {
                            ...p,
                            orders: p.orders.map(o => {
                                if (!o.state) { 
                                    return o 
                                }
                                
                                if (o.state === orderStates.ORDER_MODIFIED_BY_WAITER) {
                                    return { 
                                        ...omit(o, ['state'])
                                    }
                                }

                                return o
                            }) 
                        }
                    })
                }
            })
            
            
            return {
                ...state,
                [actions.OPEN_ORDERS]: newOpenedOrdersState
            }
        }

        case actions.CLEAR_TABLE: {
            return {
                ...state,
                [actions.ORDERS]: omit(state[actions.ORDERS], [action.payload])
            }
        }


        case actions.ADD_NEW_PERSON_AT_TABLE: 
        {
            const { restaurantOrderId, personId } = action.payload
            return {
                ...state,
                [actions.OPEN_ORDERS]: state[actions.OPEN_ORDERS].map(oo => {
                    if (oo.id !== restaurantOrderId ) { return oo }

                    return ({
                        ...oo,
                        persons: [
                            ...(oo.persons || []),
                            { id: personId, orders: [] }
                        ]
                    })

                })
            }
        }

        case actions.CLEAR_UNVERIFIED_ORDER: 
        {
            const { restaurantOrderId, personId } = action.payload
            
            return {
                ...state,
                [actions.OPEN_ORDERS]: state[actions.OPEN_ORDERS].map(oo => {
                    if (oo.id !== restaurantOrderId ) { return oo }
                    return ({
                        ...oo,
                        persons: 
                        [
                            ...(oo.persons || []).map(person => {
                                if (person.id !== personId) { return person }
                                return {
                                    ...person,
                                    orders: [ ...person.orders.filter(order => order.status !== orderStates.ORDER_UNVERIFIED) ]
                                }
                            }),
                        ]
                    })

                })
            }
        }

        default:
            return state
    }
}
