import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import CircularProgress from '@material-ui/core/CircularProgress';
import CssBaseline from '@material-ui/core/CssBaseline';
import { connect } from 'react-redux'
import * as actions from './appActions'

import WaiterBinding from './waiter/bindings/WaiterBinding'
const restaurantBell = require('../assets/audio/restaurant-bell.mp3')

class Waiter extends Component {
    state = {
        loading: true
    }

    initialData = async () => {
        let restaurantUrl = this.props.match.isExact && this.props.match.params.restaurantUrl;
        const tableId = this.props.match.isExact && this.props.match.params.tableId;
        console.log('@Waiter - initialData -->', { props: this.props, restaurantUrl, tableId });
        if (restaurantUrl) {
            const restaurantId = await this.props.getRestaurantInfo(restaurantUrl);
            this.props.setRestaurantIdAndTableId({ restaurantId, tableId })
        } else {
            this.setState({ loading: false })
        }
    }

    componentWillMount() {
        this.initialData()
    }

    handleQRCodeScanner = () => {
        this.setState({
            showQRCodeScanner: !this.state.showQRCodeScanner || false
        })
    }



    render() {
        console.log('waiter render', this.props)

        const { RESTAURANT_NAME, RESTAURANT_ID, WAITER_ID, TABLE_ID } = this.props
        const tableId = this.props.match.isExact && this.props.match.params.tableId;

        if (this.state.loading && !RESTAURANT_ID){
            if (RESTAURANT_ID === false) {
                return <Redirect to="/" path="/" />
            }
            
            return <CircularProgress style={{ margin: '33% auto', display: 'block' }} color="secondary" />
        }

        
        
        if (RESTAURANT_ID && !WAITER_ID && (!TABLE_ID || !tableId)) {
            return <Redirect to="/login" path="/login" />
        }
        

        if (!RESTAURANT_ID || !WAITER_ID) {
            return <Redirect to="/" path="/" />
        }
        
        return (
            <div> 
                <CssBaseline />
                <Helmet>
                <title>{`Orderix - ${RESTAURANT_NAME}`}</title>
                    <meta name="description" content="Orderix" />
                </Helmet>
                <audio id="audio" src={restaurantBell} loop type="audio/mp3" />
                <WaiterBinding showQRCodeScanner={this.state.showQRCodeScanner} handleQRCodeScanner={this.handleQRCodeScanner} />
            </div>
        )
    }
}

const mapStateToProps = state => {
    console.log('app bindings state', state)
    return ({
        RESTAURANT_NAME: ((state.RESTAURANT[actions.RESTAURANT_INFO] && state.RESTAURANT[actions.RESTAURANT_INFO].name) || 'Orderix'), 
        RESTAURANT_ID: state.SCANNER.restaurantId,
        WAITER_ID: state.SCANNER.waiterId,
        TABLE_ID: state.SCANNER.tableId,
        logoUrl: state.RESTAURANT[actions.RESTAURANT_INFO] === undefined ? null : state.RESTAURANT[actions.RESTAURANT_INFO].logoUrl
    })
    
}

const mapDispatchToProps = (dispatch) => ({
    getRestaurantInfo: (restaurantUrl) => dispatch(actions.getRestaurantInfo(restaurantUrl)),
    setRestaurantIdAndTableId: ({ restaurantId }) => dispatch(actions.setRestaurantIdAndTableId({ restaurantId })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Waiter)
