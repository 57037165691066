import keys from 'lodash/keys'
import omit from 'lodash/omit'
import React, { Component } from 'react'
import Items from './Items'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Fab from '@material-ui/core/Fab'
import CircularProgress from '@material-ui/core/CircularProgress'
import Avatar from '@material-ui/core/Avatar'
import Badge from '@material-ui/core/Badge'
import translateAction from '../../../util/translate'
import StarIcon from '@material-ui/icons/Star'
import { Paper } from '@material-ui/core';
import { orderStates, productStates } from '../../../configs/constants'
import styles from './styles'
import { isCategoryAvailableNow } from '../../../util/available'

const ORDER = 'ORDER'

class Menu extends Component {

    state = {
        selectedCategory: null,
        order: {},
        scrollTop: 0,
        scrollPositions: {},
    }


    componentWillMount() {
        console.log('Menu -> CompoenentWillMount', this.props)
        const { menu, lastSelectedCategory, goToCategory } = this.props
        const selectedCategory = goToCategory !== undefined ? goToCategory : lastSelectedCategory

        this.setState({
            selectedCategory: selectedCategory !== undefined && selectedCategory !== null
                ? selectedCategory
                : menu[0].details.id
        })
    }


    componentDidUpdate() {
        window.history.pushState(null, null, window.location.href)
        window.onpopstate = (wind) => {
            wind.preventDefault()
            if (this.state.openGenerateOrderQR) {
                console.log('onBack -> close generated qrCode')
                this.setState({
                    openGenerateOrderQR: false
                },
                    () => window.history.go(1)
                )
            } else {
                console.log('onBack -> close a category or orderlist')
                this.setState({
                    display: '',
                    selectedCategory: this.state.display === ORDER ? this.state.selectedCategory : ''
                },
                    () => window.history.go(1)
                )
            }
        }
    }

    componentDidMount() {
        const selectedCategory = this.state.selectedCategory
        if (selectedCategory !== undefined && selectedCategory !== null) {
            try {
                document.querySelector(`#${selectedCategory.replace(/[0-9]/g, '')}`).scrollIntoViewIfNeeded()
            } catch (error) {
                console.warn(error)
            }
        }

        if (this.props.goToProduct !== undefined) {
            const product = this.props.goToProduct.replace(/[0-9]/g, '')
            document.querySelector(`#listProducts`).querySelector(`#${product}`).scrollIntoViewIfNeeded()
        }
    }

    translate = (text) => {
        return translateAction(text, this.props.language)
    }


    getStyles = () => {
        const styles = {
        }
        return styles
    }


    goToCategory = panel => {
        console.log('WaiterCompoment - goToCategory  categoryId', panel)
        const listProducts = document.querySelector(`#listProducts`) 
        const scrollPositions = { ...this.state.scrollPositions, [this.state.selectedCategory]: listProducts === null ? 0 : listProducts.scrollTop }
        const y = this.state.scrollPositions[panel] || 0
        
        const category = panel.replace(/[0-9]/g, '')
        document.querySelector(`#${category}`).scrollIntoViewIfNeeded()

        this.setState({
            selectedCategory: panel,
            scrollPositions
        }, () => {
            document.querySelector(`#listProducts`).scrollTo(0,y)
        });
    }
    
    goToProduct = (categoryId, productId) => {
        console.log('WaiterCompoment - goToProduct', categoryId, 'productId', productId)
        const category = categoryId.replace(/[0-9]/g, '')
        const product = productId.replace(/[0-9]/g, '')
        console.log('WaiterCompoment - goToProduct ', category, 'product', product)
        document.querySelector(`#${category}`).scrollIntoViewIfNeeded()

        this.setState({
            selectedCategory: category,
        }, () => {
            setTimeout(() => {
                document.querySelector(`#listProducts`).querySelector(`#${product}`).scrollIntoViewIfNeeded()
            }, 100)
        });
    }


    handleChangeCategory = panel => () => {
        this.setState({
            selectedCategory: panel,
        },
            this.props.lastCategoryChange(panel)
        )
    }

    handleOrderChange = newOrder => {
        console.log('newOrder', newOrder)
        let sOrders = this.props.orders
        
        let newOrderState = {}

        const haveUnverifiedOffer = sOrders.filter(order => order.status === orderStates.ORDER_UNVERIFIED)[0]

        if (newOrder.quantity === 0) {
            sOrders = [
                ...sOrders.map(so => {
                    if (!so) { return undefined }
                    if (so.state !== 'ORDER_MODIFIED_BY_WAITER') { 
                        return so 
                    }

                    const moreProducts = keys(so[newOrder.categoryId]).length > 1
                    if (moreProducts) {
                        delete so[newOrder.categoryId][newOrder.product.productId]
                        return { ...so }
                    }

                    if (keys(omit(delete so[newOrder.categoryId], ['status', 'state', 'timestamp'])).length === 0) {
                        return undefined
                    }
                    return { ...so }
                })
            ].filter(so => so !== undefined)
        } 

        if (haveUnverifiedOffer) {
            newOrderState = [
                ...sOrders.map(order => {
                    if (newOrder.quantity !== 0 && order.status === orderStates.ORDER_UNVERIFIED) {
                        return ({
                            ...order,
                            state: orderStates.ORDER_MODIFIED_BY_WAITER,
                            [newOrder.categoryId]: {
                                ...(order[newOrder.categoryId] || {}),
                                [newOrder.product.productId]: {
                                    ...newOrder.product,
                                    status: productStates.PENDING,
                                    quantity: newOrder.quantity
                                }
                            }
                        })
                    }
                    return order
                })
            ]

        } else {
            newOrderState = [...sOrders, {
                [newOrder.categoryId]: {
                    [newOrder.product.productId]: {
                        ...newOrder.product,
                        status: productStates.PENDING,
                        quantity: newOrder.quantity
                    }
                },
                status: orderStates.ORDER_UNVERIFIED,
                state: orderStates.ORDER_MODIFIED_BY_WAITER,
                timestamp: new Date()
            }]
        }

        this.props.handleOrderChange({ 
            personId: this.props.personId,
            ordersId: this.props.restaurantOrderId, 
            orders: newOrderState
        })
    }



    listCategories = () => {
        const { classes, orders, menu, language } = this.props
        const { selectedCategory } = this.state
        const translate = this.translate

        return menu
            .map(category => {
                if (category.details.id === 'recommended' && category.items.length > 0) {
                    return (
                        <div id={`${category.details.id.replace(/[0-9]/g, '')}`} key={'recommended'} className={classes.categoryContainer}>
                            <Fab disableFocusRipple disableRipple
                                onClick={this.handleChangeCategory('recommended')}
                                className={classes.btnCategory}
                            >
                                <Avatar className={selectedCategory === 'recommended' ? classes.selectedCategory : classes.category}>
                                    <StarIcon />
                                </Avatar>
                            </Fab>
                            <Typography className={selectedCategory === 'recommended' ? classes.selectedCategoryText : classes.categoryText} align="center">
                                {translate('Recommended')}
                            </Typography>
                        </div>
                    )
                }

                let productsOrdered = 0
                orders.forEach(order => {
                    const categ = order[category.details.id]
                    if (categ) {
                        const prods = Object.keys(order[category.details.id])
                        prods.forEach(prod => { productsOrdered = productsOrdered + categ[prod].quantity })
                    }
                })

                let categoryName = ''
                try {
                    categoryName = category.details.name[language] || ''
                } catch (error) {
                    console.warn(language, category)
                }

                const { available, availabilities } = category.details
                let opacity = 1

                if (available) {
                    opacity = 1
                } else {
                    console.log({ category, availabilities })
                    const isAvailableNow = isCategoryAvailableNow(availabilities)
                        
                    if (!isAvailableNow) {
                        opacity = .75
                    }
                }

                return (
                    <div id={`${category.details.id.replace(/[0-9]/g, '')}`} key={category.details.id} className={classes.categoryContainer} style={{ opacity }}>
                        <Badge classes={productsOrdered === 0 ? null : { badge: classes.badge }} badgeContent={productsOrdered === 0 ? '' : productsOrdered}>
                            <Fab disableFocusRipple disableRipple
                                onClick={this.handleChangeCategory(category.details.id)}
                                className={classes.btnCategory}
                            >
                                {
                                    category.details.thumbImg 
                                        ? <Avatar className={selectedCategory === category.details.id ? classes.selectedCategory : classes.category} src={category.details.thumbImg} />
                                        : <Avatar className={selectedCategory === category.details.id ? classes.selectedCategory : classes.category}>
                                            <Typography variant="h6" className={selectedCategory === category.details.id ? classes.selectedCategoryIcon : classes.categoryIcon}>
                                                {categoryName[0]}
                                            </Typography>
                                        </Avatar>
                                }
                                
                            </Fab>
                        </Badge>
                        <Typography className={selectedCategory === category.details.id ? classes.selectedCategoryText : classes.categoryText} style={{ opacity }} align="center">
                            {categoryName}
                        </Typography>
                    </div>
                )
            })
    }

    handleDisplayOrder = () => {
        this.setState({
            display: this.state.display === ORDER ? '' : ORDER
        })
    }

    listPreparate = (selectedCategory) => {
        if (selectedCategory === null || selectedCategory === false) {
            return null
        }
        const { classes, language, orders, menu, currency } = this.props
        const category = menu.filter(category => category.details.id === selectedCategory)[0]

        if (category === undefined) {
            this.setState({
                selectedCategory: this.props.menu[0].details.id
            })
            return null
        }

        const isThisCategoryAvailableNow = category.details.availabilities === undefined || category.details.availabilities.length === 0 ? true : isCategoryAvailableNow(category.details.availabilities)
        const isThisCategorySpecial = category.details.special
        const categoryPriceRules = category.details.priceRules

        const categoriesNotAvailableNow = menu.filter(c => c.details.id !== 'recommended').map(c => {
            if (c.details.availabilities === undefined || c.details.availabilities.length === 0) {
                return { id: c.details.id, available: true }
            } else {
                return { id: c.details.id, available: isCategoryAvailableNow(c.details.availabilities) }
            }
        }).filter(c => !c.available).map(c => c.id)

        return <Items
            selectedCategory={selectedCategory}
            categoryPriceRules={categoryPriceRules}
            categoriesNotAvailableNow={categoriesNotAvailableNow}
            isThisCategoryAvailableNow={isThisCategoryAvailableNow}
            categoryAvailabilities={category.details.availabilities}
            isThisCategorySpecial={isThisCategorySpecial}
            data={category.items}
            language={language}
            currency={currency}
            classes={classes}
            handleOrderChange={newOrder => this.handleOrderChange(newOrder)}
            orders={orders}
        />
    }


    render() {
        console.log('Menu -> render', this.props, this.state)
        const { classes, menu } = this.props
        const { selectedCategory } = this.state

        if (menu === undefined) {
            return null
        }

        if (menu === 'LOADING') {
            return <CircularProgress className={classes.progress} color="secondary" />
        }

        const listCategories = this.listCategories(menu.categories)
        const listPreparate = this.listPreparate(selectedCategory)

        const appBarHeight = window.document.getElementsByTagName('header')[0].offsetHeight

        const height = (window.screen.height - window.innerHeight - appBarHeight) > 0
            ? `calc(100vh - 144px - ${appBarHeight}px)`
            : `calc(100vh - 144px)`


        return (
            <div>
                <Paper id="containerListCategories" className={classes.listCategories} elevation={16} style={{ top: `${appBarHeight}px` }}>
                    { listCategories }
                </Paper>

                <div className={classes.containerList} style={{ height }}>
                    {listPreparate}
                </div>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Menu)

