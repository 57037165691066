import * as actions from './actions'


const INITIAL_STATE = { }


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actions.SET_LANGUAGE: {
            return {
                ...state,
                SELECTED_LANGUAGE: action.payload
            }
        }

        case actions.RESET_LANGUAGE: {
            return INITIAL_STATE
        }

        default:
            return state
    }
}
