import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';
import { orderStates } from '../../../../../configs/constants';
import { OPEN_ORDERS } from '../../../actions';

const { 
  ORDER_OPEN,
  ORDER_ACCEPTED, 
  ORDER_DECLINED, 
  ORDER_PREPARING, 
  ORDER_READY_FOR_DELIVER, 
  ORDER_DELIVERING
} = orderStates;



const useStyles =  makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  badgePadding: {
    padding: theme.spacing(0, 1),
  },
}));




export default function OrderStatesTabs(props) {
  const classes = useStyles();
  const { translate, availableOrderStates = [], handleTabChanged, selectedTabIndex, onlineOrdersGrouped } = props;
  const [value, setValue] = useState(selectedTabIndex);

  useEffect(() => {
    if (value !== selectedTabIndex) {
      setValue(selectedTabIndex)
    }
  }, [selectedTabIndex]);


  const handleChange = (event, newValue) => {
    setValue(newValue);
    handleTabChanged(availableOrderStates[newValue]);
  };

  if (value > 0 && value === availableOrderStates.length) {
    setTimeout(() => setValue(value - 1), 0)
    return null
  }

  console.log('@OrderStatesTabs', { ...props, value })
  return (
    <Paper className={classes.root}>
      <Tabs 
        indicatorColor="primary" textColor="primary" variant="scrollable"
        value={value} 
        onChange={handleChange} 
      >
        {
          availableOrderStates.map(orderState => {
            const label = [ORDER_ACCEPTED, ORDER_OPEN, ORDER_PREPARING, ORDER_DELIVERING].includes(orderState)
              ? <Badge className={classes.badgePadding} badgeContent={onlineOrdersGrouped[orderState].length}>
                {translate(orderState)}
              </Badge>
              : translate(orderState)

            return <Tab key={orderState} id={orderState} label={label}/>
          })
        }
      </Tabs>
    </Paper>
  );
}
