import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import CssBaseline from '@material-ui/core/CssBaseline';
import ScannerBinding from './scan/bindings/ScannerBinding';
import Button from '@material-ui/core/Button';
import { rescan } from './appActions'
import store from '../redux/store';

const Scan = (props) => {
  const [state, setState] = useState({
    showQRCodeScanner: undefined,
  });

  const handleQRCodeScanner = () => {
    store.dispatch(rescan())
    setState({
      showQRCodeScanner: !state.showQRCodeScanner || false,
    });
  };

  const { showQRCodeScanner } = state ;


  let localStorageCache;
  try {
    localStorageCache = JSON.parse(localStorage.getItem('s'));
  } catch (err) {}

  console.log('@Scan ', { props })


  return (
    <div>
      <CssBaseline />
      <Helmet>
        <title>Orderix</title>
        <meta name="description" content="Orderix" />
      </Helmet>

      {!showQRCodeScanner && !localStorageCache ? (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '25%' }}>
          <Button type="submit" size="large" variant="contained" color="primary" style={{ padding: '1rem' }} onClick={handleQRCodeScanner}>
            Scan Restaurant QRCode
          </Button>
        </div>
      ) : (
        <ScannerBinding s={localStorageCache} />
      )}
    </div>
  );
};


export default Scan;
