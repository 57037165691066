import * as actions from './actions'
import { WAITER_LOGOUT } from '../waiter/actions';
import { RESCAN_CODE, RESTAURANT_ID_AND_TABLE_ID } from '../appActions'

const INITIAL_STATE = {}


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case actions.QR_CODE_DATA:
        case RESTAURANT_ID_AND_TABLE_ID:
            {
                return {
                    ...state,
                    ...action.payload 
                }
            }
            
        case WAITER_LOGOUT: {
            return {
                ...state, 
                waiterId: undefined
            }
        }

        case RESCAN_CODE: 
        {
            return INITIAL_STATE
        }

        default:
            return state
    }
}
