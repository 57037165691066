
export const APP_NAME = 'RESTAURANT_APP';
export const API_ROOT_URL = 'https://us-central1-agilehubrestaurantapp.cloudfunctions.net/api';

const GCM_API_URL = 'https://europe-west3-agilehubrestaurantdev.cloudfunctions.net/api';
export const URL_NOTIFY_WAITER = `${GCM_API_URL}/notify-waiter`;
export const URL_GET_USER = `${GCM_API_URL}/get-user`;
export const URL_UPDATE_USER = `${GCM_API_URL}/update-user`;

const API_APP_FUNCTIONS = 'https://europe-west2-agilehubrestaurantapp.cloudfunctions.net/api'
export const URL_ONLINE_ORDERS = `${API_APP_FUNCTIONS}/api/online-orders`;
export const URL_SEND_EMAILS = `${API_APP_FUNCTIONS}/api/emails`;



// const tableIs = {
//     OPEN: 'OPEN',
//     CLOSED: 'CLOSED'
// }

export const waiterServices = {
    INFO: 'INFO',
    WANT_TO_ORDER: 'WANT_TO_ORDER', 
    ORDER: 'ORDER',
    PAY: 'PAY',
    PAY_CARD: 'PAY_CARD',
    PAY_CASH: 'PAY_CASH',
    FEEDBACK: 'FEEDBACK'
}

export const orderStates = {
    ORDER_NOT_SENT: 'ORDER NOT SENT TO WAITER',
    ORDER_UNVERIFIED: 'ORDER UNVERIFIED',
    ORDER_CONFIRMED: 'CONFIRMED',
    ORDER_MODIFIED_BY_WAITER: 'ORDER_MODIFIED_BY_WAITER',
    ORDER_DELIVERED: 'DELIVERED',
    ORDER_DELIVERING: 'DELIVERING',
    ORDER_CANCELED_CUSTOMER_REQUEST: 'ORDER CANCELED - CUSTOMER REQUEST',
    ORDER_CANCELED_LONG_WAITED: 'ORDER CANCELED - LONG WAITED',
    ORDER_CANCELED_ORDER_BY_MISTAKE: 'ORDER CANCELED - ORDER BY MISTAKE',
    ORDER_CANCELED_OUT_OF_STOCK: 'ORDER CANCELED - OUT OF STOCK',
    ORDER_NEW: 'NEW',
    ORDER_PENDING: 'PENDING',
    ORDER_PREPARING: 'PREPARING',
    ORDER_READY_FOR_DELIVER: 'READY FOR DELIVER',
    ORDER_CANCELED: 'CANCELED',
    ORDER_DECLINED: 'DECLINED',
    ORDER_DECLINE: 'DECLINE',
    ORDER_ACCEPT: 'ACCEPT',
    ORDER_ACCEPTED: 'ACCEPTED',
    ORDER_OPEN: 'OPEN',
    ORDER_CLOSED: 'CLOSED',
}

export const productStates = {
    PENDING: 'PENDING',
    PROCESSING: 'PROCESSING',
    READY_TO_BE_DELIVERED: 'READY TO BE DELIVERED',
    DELIVERED: 'DELIVERED',
    CANCELED: 'CANCELED',
}

export const tableIs = {
    OPEN: 'OPEN',
    CLOSED: 'CLOSED'
}

export const STAND_MENU = 'menu'

export const userRoles = {
    WAITER: 'WAITER',
    DISPATCHER: 'DISPATCHER'
}


export const FEEDBACK_TYPE = {
    RATING: 'RATING',
    TEXT: 'TEXT'
}