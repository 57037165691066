import axios from 'axios'
import { URL_CATEGORIES, URL_ITEMS } from './constants'

export const getCategories = ({ restaurantId, language }) => {
    return axios.get(URL_CATEGORIES
        .replace(':restaurantId', restaurantId)
        .replace(':lang', language)
    )
}

export const getItems = ({ restaurantId, language, categoryId }) => {
    return axios.get(URL_ITEMS
        .replace(':restaurantId', restaurantId)
        .replace(':lang', language)
        .replace(':categoryId', categoryId)
    )
}
