import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog({ handleAlertDialogClose, dialogData }) {
  const {
    dialogTitle, dialogContentText, handleDialogOk, dialogCancel
  } = dialogData


  const handleButtonOk = () => {
    handleAlertDialogClose()
    handleDialogOk()
  }

  const handleDialogClose = () => {
    handleAlertDialogClose()
  }

  return (
    <Dialog
      open={true} 
      aria-labelledby="alert-dialog-title"
    >
    {dialogTitle && <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>}
      <DialogContent>
        {dialogContentText && <DialogContentText>
          {dialogContentText}
        </DialogContentText>
        }
      </DialogContent>
      <DialogActions>
        {dialogCancel && <Button onClick={handleDialogClose} color="primary">
          {dialogCancel}
        </Button>}
        <Button onClick={handleButtonOk} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}