import DE from './language-DE'
import ES from './language-ES'
import FR from './language-FR'
import GR from './language-GR'
import HU from './language-HU'
import IT from './language-IT'
import JP from './language-JP'
import RO from './language-RO'
import RU from './language-RU'
import TR from './language-TR'

export default [
    { language: 'en', translated: null, label: 'English' },
    { language: 'de', translated: DE, label: 'Deutsch' },
    { language: 'es', translated: ES, label: 'Español' },
    { language: 'fr', translated: FR, label: 'Français' },
    { language: 'gr', translated: GR, label: 'Ελληνικά' },
    { language: 'hu', translated: HU, label: 'Magyar' },
    { language: 'it', translated: IT, label: 'Italiano' },
    { language: 'jp', translated: JP, label: '日本の' },
    { language: 'ro', translated: RO, label: 'Romana' },
    { language: 'ru', translated: RU, label: 'Русский' },
    { language: 'tr', translated: TR, label: 'Türk' },
]

