import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import * as actions from './appActions'
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles'
import { doSignIn, rescan, getRestaurantInfo } from './appActions'


const styles = theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(11),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    btnBack: {
        width: 64, height: 64, color: 'rgba(0,0,0, .2)', position: 'absolute', top: 0, left: 0
    },
    progress: {
        margin: '33% auto', display: 'block'
    },
    signinLoading: {
        display: 'block', 
        margin: '20px auto 0px auto'
    },
    infoAboutLastUpdate: {
        color: 'rgba(0,0,0, .15)',
        display: 'block',
        paddingTop: '10%',
        textAlign: 'right'
    }
})


const Message = ({ message }) => {
    console.log('@Login -> Message', message)
    if (message) {
        return (
            <Typography variant="body2" color="textSecondary" align="center">
                {`${message}`}
            </Typography>
        )
    }
    return null
}




class Login extends Component {
    
    state = {
        loading: true,
        signin: false
    }

    initialData = async () => {
        const previousScan = JSON.parse(localStorage.getItem('s')) || {}
        console.log({ previousScan, props: this.props })

        if (this.props.match && this.props.match.path === '/login') {
            const restaurantUrl = previousScan && previousScan.r
            if (restaurantUrl) {
                const restaurantId = await this.props.getRestaurantInfo(restaurantUrl);
                const previousLogin = JSON.parse(localStorage.getItem('l'))
                if (restaurantId && previousLogin) {
                    this.props.doSignIn({ email: previousLogin.e, password: previousLogin.p })    
                }
            } else {
                this.setState({ loading: false })
            }
        }
    }

    componentWillMount() {
        if (!this.props.restaurantId && !this.props.restaurantUrl) {
            this.initialData()
        } 
    }

    

    handleSignIn = event => {
        event.preventDefault()
        console.log('form action signin')
        const email = document.forms[0].elements.email.value
        const password = document.forms[0].elements.password.value
        console.log({ email, password })
        this.setState({ signin: true }, () => this.props.doSignIn({ email, password }))
        
    }

    rescan = () => {
        localStorage.removeItem('s')
        this.props.rescan()
        window.location.hash = '/'
        window.location.reload()
    }
    

    render() {
        const { classes, logoUrl, RESTAURANT_NAME, restaurantUrl, restaurantId, waiterId,  SIGNED_IN_ERROR } = this.props

        console.log('===============================')
        console.log('<Login render>')
        console.log({ ...this.props })
        console.log('</Login>')
        console.log('===============================')

        const previousLogin = JSON.parse(localStorage.getItem('l')) || {}

        if (this.state.loading && !this.props.restaurantId && !this.props.restaurantUrl) {
            return <CircularProgress className={classes.progress} color="secondary" />
        }

        if (!restaurantUrl && !restaurantId) {
            console.log('@Login -> redirect /')
            return <Redirect to="/" path="/" />
        }

        if ((restaurantUrl || restaurantId) && waiterId) {
            return <Redirect to={`/${restaurantUrl || restaurantId}`} path={`/${restaurantUrl || restaurantId}/${waiterId}`} push={true} />;
        }

        
        return (
            <Container component="main" maxWidth="xs">

                <CssBaseline />
                <Helmet>
                    <title>{`Orderix - ${RESTAURANT_NAME}`}</title>
                    <meta name="description" content="Orderix" />
                </Helmet>

                <Button className={classes.btnBack} onClick={this.rescan}>
                    <ArrowBack />
                </Button>
                
                <div className={classes.paper}>
                    <div style={{ height: 120, width: '90%' }}>
                        { logoUrl &&
                            <img style={{ width: '100%', maxHeight: 120, objectFit: 'contain'  }} src={logoUrl} alt="" />
                        }
                    </div>
                    <form className={classes.form}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            defaultValue={previousLogin.e || ""}
                            autoComplete="email"
                            type="email"
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            defaultValue={previousLogin.p || ""}
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        
                        <Button
                            type="submit"
                            fullWidth
                            size="large"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={this.handleSignIn}
                        >Sign In</Button>
                        
                        {this.state.signin && !SIGNED_IN_ERROR && <CircularProgress className={classes.signinLoading} color="secondary" />}
                    </form>
                </div>
                
                <Box mt={5}>
                    <Message message={SIGNED_IN_ERROR} />
                    <Typography variant="caption" className={classes.infoAboutLastUpdate}>{`05.06.2023`}</Typography>
                </Box>

            </Container>
        )
    }
}

const mapStateToProps = state => {

    console.log('LOGIN -> app bindings state', {state})
    return ({
        RESTAURANT_NAME: ((state.RESTAURANT[actions.RESTAURANT_INFO] && state.RESTAURANT[actions.RESTAURANT_INFO].name) || 'Orderix'), 
        restaurantUrl: ((state.RESTAURANT[actions.RESTAURANT_INFO] && state.RESTAURANT[actions.RESTAURANT_INFO].url) || state.SCANNER.restaurantUrl),
        restaurantId: ((state.RESTAURANT[actions.RESTAURANT_INFO] && state.RESTAURANT[actions.RESTAURANT_INFO].id) || state.SCANNER.restaurantId),
        logoUrl: state.RESTAURANT[actions.RESTAURANT_INFO] === undefined ? null : state.RESTAURANT[actions.RESTAURANT_INFO].logoUrl,
        waiterId: state.SCANNER.waiterId,
        tableId: state.SCANNER.tableId,
        SIGNED_IN_ERROR: state.RESTAURANT.SIGNED_IN_ERROR,
        passOverLogin: (state.RESTAURANT.INFO !== undefined && state.SCANNER.waiterId !== undefined)
    })

}

const mapDispatchToProps = (dispatch) => ({
    getRestaurantInfo: (restaurantUrl) => dispatch(getRestaurantInfo(restaurantUrl)),
    doSignIn: (params) => dispatch(doSignIn(params)),
    rescan: (params) => dispatch(rescan())
})

export default connect(mapStateToProps, mapDispatchToProps)((withStyles(styles, { withTheme: true }))(Login))
