export const ogPrefix = "thumb@og_";

export const getFileExtension = (filePath: string) => {
  return filePath.split('.').pop();
}

export const smallImageURL = (imgSrc: string, smallImgPrefix = "thumb@220_") => {
  let smallImageURL = "";
  if (!!imgSrc) {
    const imgParts = imgSrc.split("/");
    const decodedImagePathParts = decodeURIComponent(
      imgParts[imgParts.length - 1]
    ).split("/");
    const decodedImageNameParts = decodedImagePathParts[
      decodedImagePathParts.length - 1
    ].split("?");

    let imageName = decodedImageNameParts[0];
    if(smallImgPrefix === ogPrefix) {
      const ext = getFileExtension(imageName);
      imageName = imageName.replace(`.${ext}`, '.png');
    }
    
    decodedImagePathParts[
      decodedImagePathParts.length - 1
    ] = `${smallImgPrefix}${imageName}`;
    imgParts[imgParts.length - 1] =
      encodeURIComponent(decodedImagePathParts.join("/")) +
      `?${decodedImageNameParts[1]}`;
    smallImageURL = imgParts.join("/");
  }

  return smallImageURL;
};