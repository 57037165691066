/* eslint-disable react/no-multi-comp */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import QRCode from 'qrcode.react'

const styles = {
  dContent: {
    padding: '8px',
    paddingTop: '8px !important'
  },
  qrContainer: {
    height: '65vw',
    width: '65vw',
    display: 'flex'
  }
};

class GenerateQROrder extends React.Component {
  handleClose = () => {
    console.log('Close GenerateQROrder dialog')
    this.props.onClose();
  };

  handleListItemClick = value => {
    console.log('click on', value)
  };

  render() {
    const { classes, order, ...other } = this.props;
    console.log('GenerateQROrder component render', this.props)

    return (
      <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" {...other}>
        <DialogContent className={classes.dContent}>
          <QRCode style={styles.qrContainer} value={order} size={256} level="H" renderAs="svg" />
        </DialogContent>
      </Dialog>
    );
  }
}

GenerateQROrder.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  selectedValue: PropTypes.string,
};

export default withStyles(styles)(GenerateQROrder);
