import omit from 'lodash/omit';
import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import Avatar from '@material-ui/core/Avatar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { green } from '@material-ui/core/colors';

const styles = (theme) => ({
  root: {
    width: '100%',
    flexGrow: 1,
    maxWidth: 1000,
    margin: '0 auto',
  },
  tabs: {
      '& .MuiTabs-flexContainer': {
          flexWrap: 'wrap',
          width: '100%',
          justifyContent: 'space-between'
      },
      '& .Mui-selected': {
          backgroundColor: '#f0f0f0',
          borderBottom: '3px solid red',
          zIndex: -1
      },
      '& .MuiTabs-indicator': {
          display: 'none'
      }
  },
  greenAvatar: {
      minWidth: 70,
      minHeight: 70,
      margin: 15,
      color: '#fff',
      flexDirection: 'column',
      backgroundColor: green[500],
  },
  grayAvatar: {
      minWidth: 70,
      minHeight: 70,
      margin: 15,
      color: '#888',
      flexDirection: 'column',
  },
  gridList: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 10,
      overflowY: 'inherit'
  },

});

const AssignedTables = (props) => {
  
  const [selectedTabIndex, changeTab] = useState(0);

  const { classes, allTables, alocatedTables, updateAssignedTablesStatus } = props;
  const selectedArea = Object.keys(allTables)[selectedTabIndex]

  console.log({ allTables, alocatedTables, selectedArea });

  if (!allTables) return null;

  const checkTableIfAssigned = ({ areaName, table }) => alocatedTables[areaName]?.find(assignedTable => assignedTable === table)

  const handleChangeTableStatus = ({ areaName, table }) => event => {
    
    const isTableAssigned = checkTableIfAssigned({ areaName, table })
    if (!isTableAssigned) {
      return updateAssignedTablesStatus({ ...alocatedTables, [areaName]: [ ...(alocatedTables[areaName] || []), table] })
    }

    const newTablesAreaStatus = (alocatedTables[areaName] || []).filter(assignedTable => assignedTable !== table)
    if (newTablesAreaStatus.length > 0) {
      return updateAssignedTablesStatus({ ...alocatedTables, [areaName]: newTablesAreaStatus })
    }

    return updateAssignedTablesStatus({ ...omit(alocatedTables, [ areaName ]) })
  };

  const handleTabChange = (event, tabIndex) => {
    changeTab(tabIndex);
  };

  function TablesPanel(props) {
    const { areaName, areaTables } = props;
    const tables = areaTables.map((table) => {
      const tableId = table
      const tableNumber = tableId.split(':')[1]

      const tableStatus = checkTableIfAssigned({ areaName, table }) ? `${classes.greenAvatar}` : `${classes.grayAvatar}`

      return (
        <div key={`table_${table}`} style={{ width: 130, height: 100 }} onClick={handleChangeTableStatus({ areaName, table })}>
          <Avatar className={tableStatus}>
            <Typography variant="h6" style={{ lineHeight: '24px' }}>{tableNumber}</Typography>
          </Avatar>
        </div>
      )
    });

    return (
      <GridList cellHeight={100} className={classes.gridList} style={{ margin: 0 }}>
        {tables}
      </GridList>
    );
  }


  const areaNames = Object.keys(allTables).map((areaName) => <Tab key={areaName} label={areaName} />);

  return (
    <div className={classes.root}>
      <Tabs className={classes.tabs} value={selectedTabIndex} onChange={handleTabChange}>
        {areaNames}
      </Tabs>
      <TablesPanel areaName={selectedArea} areaTables={allTables[selectedArea]} />
    </div>
  );
};

export default withStyles(styles)(AssignedTables);
