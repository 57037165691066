import { connect } from 'react-redux'
import RestaurantMenuComponent from './../components/RestaurantMenuComponent'

import * as actions from './../actions'
import * as constants from './../constants'

const mapStateToProps = state => {
    console.log('Menu bindings state', state)
    return ({
        info: state.RESTAURANT.INFO,
        logoUrl: state.RESTAURANT.INFO === undefined ? null : state.RESTAURANT.INFO.logoUrl,
        language: state.CLIENT.SELECTED_LANGUAGE,
        currency: state.RESTAURANT.INFO === undefined ? null : state.RESTAURANT.INFO.defaultCurrency.toUpperCase(),
        accessToDirectOrders: state.RESTAURANT.INFO === undefined ? false : (state.RESTAURANT.INFO.accessToDirectOrders && state.RESTAURANT.INFO.waiterCreateOrders),
        callForWaiter: state.RESTAURANT.INFO.callForWaiter,
        menu: Array.isArray(state[constants.MODULE_NAME][actions.MENU]) ? state[constants.MODULE_NAME][actions.MENU] : [],
        order: state[constants.MODULE_NAME][actions.ORDER],
        payWith: state[constants.MODULE_NAME][actions.PAY_WITH],
        orderPaid: state[constants.MODULE_NAME][actions.ORDER_PAID],
        waitingOrderConfirmation: state[constants.MODULE_NAME].waitingOrderConfirmation,
        multiplePersons: state[constants.MODULE_NAME].multiplePersons,
        restaurantId: state.SCANNER.restaurantId,
        notification: state[constants.MODULE_NAME][actions.NOTIFICATION_SENT_TO_WAITER],
        tableId: state.SCANNER.tableId,
    })
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    getMenu: () => dispatch(actions.getMenu()),
    getCurrentOrder: () => dispatch(actions.getCurrentOrder()),
    handleOrderChange: (newOrder) => dispatch(actions.handleOrderChange(newOrder)),
    callWaiterFor: (service) => dispatch(actions.callWaiterFor(service)),
    deleteOrder: (params) => dispatch(actions.deleteOrder(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantMenuComponent) 

