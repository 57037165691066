import keys from 'lodash/keys'

export const fixDecimals = amount => {
    let value = `${amount}`.split('.')[0]
    let decimals = `${amount}`.split('.')[1]
    if (value === '0' && decimals !== undefined) {
        decimals = (decimals + '00').substr(0, 3)
    } else {
        if (decimals === undefined) {
            decimals = '00'
        } else {
            decimals = `${decimals}0`.substr(0, 2)
        }
    }
    if (decimals === '00') {
        return `${value}`
    }
    return `${value}.${decimals}`
}

export const getLastPrice = product => {
    if (product.ranges === undefined) {
        return product.price
    }
    const specialOffer = product.ranges
        .filter(r => r.days.includes(new Date().getDay() || 7))
        .filter(r => 
            (
                (new Date().setHours(r.startTime.split(':')[0], r.startTime.split(':')[1]) <= Date.now()) 
                    &&
                (Date.now() <= new Date().setHours(r.endTime.split(':')[0], r.endTime.split(':')[1]))
            )
        )
    const lastPrice = specialOffer[specialOffer.length-1] === undefined ? product.price : specialOffer[specialOffer.length-1].price
    return lastPrice
}


export const getTotalToPay = (menu, order) => {
    let TOTAL = 0
    for (const categoryId of keys(order)) {
        for (const productId of keys(order[categoryId])) {
            const product = menu.find(c => c.details.id === categoryId).items.find(p => p.id === productId)
            const unitPrice = getLastPrice(product)
            const quantity = order[categoryId][productId]
            const productTotal = quantity * unitPrice
            TOTAL = TOTAL + productTotal
        }
    }
    return fixDecimals(TOTAL)
}

