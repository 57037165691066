import teal from '@material-ui/core/colors/teal'
import green from '@material-ui/core/colors/green'
import red from '@material-ui/core/colors/red'

export default theme => ({


    //TABLE DETAILS
    progress: {
        margin: '33% auto', 
        display: 'block'
    },
    toolbar: {
        flex: '1',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
        background: '#fff',
        color: 'rgba(0,0,0, .5)',
    },
    logo: {
        height: '40px',
        flex: 1,
        objectFit: 'contain',
        objectPosition: 'left',
        verticalAlign: 'middle',
    },
    flex: {
        flex: 1,
    },
    root: {
        width: '100%',
        paddingBottom: 80
    },


    greenAvatar: {
        width: 42,
        height: 42,
        marginRight: 15,
        color: '#fff',
        backgroundColor: green[500],
    },
    grayAvatar: {
        maxWidth: 56,
        margin: 15,
        color: '#fff',

    },
    gridList: {
        display: 'flex',
        justifyContent: 'space-around'
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    fabGreen: {
        color: theme.palette.common.white,
        backgroundColor: green[500],
    },
    fabTeal: {
        color: theme.palette.common.white,
        backgroundColor: teal[500],
        '&:hover': {
            backgroundColor: teal[600],
        },
    },
    fabTealAddPerson: {
        color: theme.palette.common.white,
        fontSize: 'larger',
        backgroundColor: teal[500],
        '&:hover': {
            backgroundColor: teal[600],
        },
        position: 'fixed',
        right: theme.spacing(2),
        bottom: theme.spacing(2)
    },
    fabPay: {
        color: theme.palette.common.white,
        backgroundColor: '#333',
        '&:hover': {
            backgroundColor: '#333',
        },
        width: 36,
        height: 36
    },
    clearTable: {
        color: theme.palette.common.white,
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[600],
        },
        width: 36,
        height: 36
    },
    container: {
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        overflowX: 'hidden',
        overflowY: 'scroll',
        WebkitOverflowScrolling: 'touch',
    },

    tdCategoryName: {
        color: '#5F5F5F',
        fontSize: 18, //era 14
        fontWeight: 'bold',
        textAlign: 'center',
        fontFamily: `'Oswald', sans-serif`,
        paddingBottom: '6px',
        textTransform: 'uppercase'
    },
    tdItem: {
        fontSize: 13, //era 12
        lineHeight: 1.25
    },

    tdContent: {
        marginTop: 56,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0, .05)'
    },

    cardPerson: {
        maxWidth: 700,
        margin: '8px auto'
    },
    cardContent: {
        padding: theme.spacing(0, 1),
    },


    containerPersons: {
        flex: 1,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        backgroundColor: 'pink'
    },
    containerPayment: {
        flex: 1,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        backgroundColor: 'palegoldenrod'
    },
    containerOther: {
        flex: 1,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        backgroundColor: 'burlywood'
    },
    




    // Menu
    productsOrdered: {
        fontSize: theme.typography.pxToRem(15),
        flex: 1,
        textAlign: 'right'
    },

    listCategories: {
        position: 'relative',
        zIndex: 2,
        display: 'flex',
        overflowX: 'scroll',
        overflowY: 'hidden',
        WebkitOverflowScrolling: 'touch',
        height: '90px',
        paddingTop: '4px',
        paddingBottom: '8px'
    },


    categoryContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 'max-content',
    },

    badge: {
        top: 10,
        right: 10,
        fontFamily: 'Oswald',
        fontSize: 12,
        color: '#ffffff',
        backgroundColor: '#000000',
        border: '2px solid #ffffff',
    },

    btnCategory: {
        margin: '4px 8px',
        width: '42px',
        height: '42px'
    },
    category: {
        width: '42px',
        height: '42px',
        color: 'rgba(42,46,54,0.7)',
        backgroundColor: '#d1d1d1',
    },
    selectedCategory: {
        width: '42px',
        height: '42px',
        backgroundColor: '#F1204C',
    },

    categoryIcon: {
        color: 'rgba(42,46,54, 0.7)',
    },

    selectedCategoryIcon: {
        color: '#ffffff'
    },

    categoryText: {
        fontSize: 'small',
        color: '#5F5F5F',
        fontFamily: `'Oswald', sans-serif`,
        width: '100px',
        overflow: 'hidden',
        maxHeight: '31px',
        lineHeight: 1.2,
        margin: '0px 4px',
    },
    selectedCategoryText: {
        fontSize: 'small',
        color: '#F1204C',
        fontFamily: `'Oswald', sans-serif`,
        maxHeight: '31px',
        lineHeight: 1.2,
        width: '100px',
        overflow: 'hidden',
        margin: '0px 4px',
    },


    productOf: {
        fontFamily: `'Oswald', sans-serif`,
        fontSize: 'larger',
        color: 'rgba(0,0,0, .5)'
    },
    link: {
        color: 'rgba(0,0,0, .33)',
        fontFamily: `'Oswald', sans-serif`,
        fontSize: 'small'
    },

    containerList: {
        position: 'fixed',
        top: '144px',
        backgroundColor: 'rgba(0,0,0 .15)',
        width: '100%',
        overflowX: 'hidden',
        overflowY: 'scroll',
        WebkitOverflowScrolling: 'touch'
    },

    categoryName: {
        color: '#5F5F5F',
        fontSize: 18,
        fontWeight: 'bold',
        textAlign: 'center',
        fontFamily: `'Oswald', sans-serif`,
        paddingBottom: '6px',
        textTransform: 'uppercase',

    },
    item: {
        fontSize: '13px',
        lineHeight: 1.25
    },



    // Items
        card: {
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
        },
        details: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            padding: '10px 4px'
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        recommended: {
            color: 'pink',
            fontFamily: `Oswald', sans-serif`,
            position: 'absolute',
            top: '13px',
            left: '2px',
            transform: 'rotate(-20deg)',
            fontSize: 'larger',
        },
        contentText: {
            fontFamily: `'Oswald', sans-serif`,
            color: '#5f5f5f',
            textAlign: 'center',
            fontSize: '14px',
            lineHeight: 1.15,
        },
        biggerPrice: {
            textDecoration: 'line-through',
            fontSize: '12px',
            opacity: .65,
            paddingRight: 6,
            fontWeight: 'bold',
        },
        price: {
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: 1.75,
        },
        bestPrice: {
        },
        contentPay: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        pay: {
            fontFamily: `'Oswald', sans-serif`,
            color: '#999999',
            fontSize: 'small',
        },
        orderedPortions: {
            fontFamily: `'Oswald', sans-serif`,
            fontSize: 30,
            fontWeight: 'bold',
            color: '#F1204C'
        },
        btn: {
            color: '#ddd',
            touchAction: 'none',
            padding: '8px'
        },
        infoText: {
            fontFamily: `'Oswald', sans-serif`,
            color: '#5f5f5f',
            fontSize: '12px',
            paddingBottom: `${theme.spacing(1)}px !important`,
            paddingTop: '0px !important',
            paddingLeft: '10px',
            paddingRight: '10px',
        },
        cover: {
            width: 130,
            height: 130,
            backgroundSize: 'cover',
            margin: 10
        },
        controls: {
            display: 'flex',
            width: '70%',
            margin: '0 auto'
        },
        actions: {
            display: 'flex',
            position: 'absolute',
            top: '100px',
            right: '0px',
        },
        expand: {
            color: 'rgba(0,0,0, .2)',
            transform: 'rotate(0deg)',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
            marginLeft: 'auto',
            [theme.breakpoints.up('sm')]: {
                marginRight: -8,
            },
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        cardCategoryAvailabilities: {
            margin: theme.spacing(1)
        },

    
    
    
    // ORDER LIST

    fabPayOrderPerson: {
        color: theme.palette.common.white,
        backgroundColor: '#333',
        '&:hover': {
            backgroundColor: '#333',
        },
        width: 36,
        height: 36,
        position: 'fixed',
        right: theme.spacing(2),
        top: 10,
        zIndex: 1101
    },
    sku: {
        fontWeight: 'bold',
        fontSize: '15px',
        textTransform: 'capitalize',
        padding: '0px 6px',
        color: '#fff',
        backgroundColor: '#444',
        verticalAlign: 'middle'
    },
    orderedTimestamp: {
        marginTop: 6,
        backgroundColor: 'rgba(0,0,0, .1)',
        color: '#222',
        fontSize: 11,
        textAlign: 'center',
        fontFamily: `'Oswald', sans-serif`,
        opacity: .75,
    },

    marginDialogTitle: {
        padding: theme.spacing(2,3,2,3)
    },

    marginRest: {
        marginTop: theme.spacing(2)
    },

    green: {
        color: green[500]
    },

    red: {
        color: red[500]
    },

    
    
    
})
