import { combineReducers } from 'redux'
import * as MODULE from '../modules/index.js'
import appReducer from '../modules/appReducer'
import choose_language from './../modules/choose_language/index.js'
import restaurant_menu from './../modules/restaurant_menu/index.js'
import waiter from './../modules/waiter/index.js'
import scanner from './../modules/scan/index.js'
import dispatcher from './../modules/waiter/components/dispatcher/index'


export default combineReducers({
    RESTAURANT: appReducer,
    SCANNER: scanner.reducer,
    [MODULE.CHOOSE_LANGUAGE]: choose_language.reducer,
    [MODULE.MENU]: restaurant_menu.reducer,
    [MODULE.WAITER]: waiter.reducer,
    [MODULE.DISPATCHER]: dispatcher.reducer
})
