import { connect } from 'react-redux'
import ScannerComponent from './../components/ScannerComponent'
import * as actions from './../actions'
import * as constants from './../constants'

const mapStateToProps = (state, ownProps) => {
    return ({ 
        order: state[constants.MODULE_NAME].order,
        restaurantUrl: state.RESTAURANT.INFO && state.RESTAURANT.INFO.url,
        waiterId: state.WAITER['WAITER/INFO'] && state.WAITER['WAITER/INFO'].waiterId,
        tableId: state[constants.MODULE_NAME].tableId,
    })
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return ({
        handleQRCodeData: (qrCodeData) => dispatch(actions.handleQRCodeData(qrCodeData)),
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(ScannerComponent) 
