import keys from 'lodash/keys'
import omit from 'lodash/omit'
import flatten from 'lodash/flatten'
import uniqueId from 'lodash/uniqueId'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import translateAction from '../../../util/translate'
import Fab from '@material-ui/core/Fab';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import AttachMoney from '@material-ui/icons/AttachMoney';
import classNames from 'classnames';
import Payment from './Payment'
import { fixDecimals } from '../../../util/restaurant'

import { orderStates } from '../../../configs/constants'
import { Paper } from '@material-ui/core';
import styles from './styles'

const ORDER = 'ORDER'



class OrderList extends Component {

    state = {
        openClearTable: false,
    }


    componentDidUpdate() {
        window.history.pushState(null, null, window.location.href)
        if (this.state.display === ORDER) {
            document.getElementById('orderlist').scrollTo(0, document.getElementById('orderlist').scrollHeight)
        }
    }


    componentWillUnmount() {
        window.onpopstate = () => { }
    }


    translate = (text) => {
        return translateAction(text, this.props.language)
    }


    handleClose = () => {
        this.props.displayOrder({ display: false })
    };



    getLastPrice = product => {
        if (product.ranges === undefined) {
            return product.price
        }
        const specialOffer = product.ranges
            .filter(r => r.days.includes(new Date().getDay() || 7))
            .filter(r =>
                (
                    (new Date().setHours(r.startTime.split(':')[0], r.startTime.split(':')[1]) <= Date.now())
                    &&
                    (Date.now() <= new Date().setHours(r.endTime.split(':')[0], r.endTime.split(':')[1]))
                )
            )
        const lastPrice = specialOffer[specialOffer.length - 1] === undefined ? product.price : specialOffer[specialOffer.length - 1].price
        return lastPrice
    }

    goToCategory = categoryId => event => {
        console.log('onCLick goToCategory', categoryId)
        this.props.displayMenu({ categoryId })
        event.stopPropagation()
    }

    goToProduct = (categoryId, productId) => event => {
        console.log('onCLick goToCategory', categoryId, productId)
        this.props.displayMenu({ categoryId, productId })
        event.stopPropagation()
    }


    getOrderList = order => {

        const { classes, menu, currency, language } = this.props
        console.log('orderList', order)

        this.TOTAL_TO_PAY = 0

        let orderList = []
        for (const orderRequest of order.filter(o => o.status !== orderStates.ORDER_UNVERIFIED)) {

            const orderedTimestamp = (orderRequest.timestamp.seconds) === undefined ? orderRequest.timestamp : new Date(orderRequest.timestamp.seconds * 1000)

            orderList = [
                ...orderList,
                <div key={orderedTimestamp} style={{ width: '100%' }}>
                    <Typography className={classes.orderedTimestamp}>{`${orderedTimestamp.toString().substr(4, 17)}`}</Typography>
                    {
                        keys(omit(orderRequest, ['id', 'status', 'timestamp', 'state'])).map(categoryId => {
                            const categoryName = menu.find(c => c.details.id === categoryId).details.name[language]
                            console.log('@getOrderList -> categoryName', categoryName, orderRequest)
                            return (
                                <div key={categoryId} style={{ paddingTop: 12, paddingBottom: 12, backgroundColor: 'transparent' }}>
                                    <Typography
                                        className={classes.categoryName} style={{ opacity: .8, background: 'transparent' }}
                                        onClick={this.goToCategory(categoryId)}
                                    >{`${categoryName}`}</Typography>


                                    {keys(orderRequest[categoryId]).map(productId => {
                                        const product = menu.find(c => c.details.id === categoryId).items.find(p => p.id === productId)
                                        
                                        const productName = product.name[language]
                                        const sku = (product.sku === undefined || product.sku === null) ? '' : product.sku
                                        const productWeight = (product.weight === undefined || product.weight === null || product.weight === '' || product.weight === '0' || product.weight === 0) ? '' : product.weight
                                        let price1
                                        const quantity = orderRequest[categoryId][productId].quantity

                                        let confirmedPrice1
                                        let confirmedQuanity
                                        let removedList = []
                                        let removedMore = quantity

                                        if (orderRequest[categoryId][productId].quantity < 0) {
                                            // scade din comanda facuta
                                            const lastConfirmedOrders = [...order].reverse().filter(o => o.state !== orderStates.ORDER_MODIFIED_BY_WAITER)
                                            for (let i = 0; i < lastConfirmedOrders.length; i++) {
                                                const confirmedOrder = lastConfirmedOrders[i]

                                                if (confirmedOrder[categoryId] && confirmedOrder[categoryId][productId]) {

                                                    confirmedPrice1 = confirmedOrder[categoryId][productId].price1
                                                    confirmedQuanity = confirmedOrder[categoryId][productId].quantity

                                                    if (confirmedQuanity > 0) {
                                                        removedList = [
                                                            ...removedList,
                                                            {
                                                                quantity: confirmedQuanity < Math.abs(removedMore) ? -confirmedQuanity : removedMore,
                                                                price1: confirmedPrice1
                                                            }
                                                        ]
                                                        console.log(removedList)
                                                        removedMore = removedMore + ((confirmedOrder[categoryId] && confirmedOrder[categoryId][productId].quantity) || 0)
                                                    }


                                                    if (removedMore === 0) {
                                                        break
                                                    }
                                                }


                                            }
                                        } else {
                                            price1 = orderRequest[categoryId][productId].price1
                                        }



                                        if (removedList.length > 0) {
                                            return (
                                                <div
                                                    key={`productId-${categoryId}-${productId}`} style={{ paddingBottom: '6px', textAlign: 'center' }}
                                                    onClick={this.goToProduct(categoryId, productId)}
                                                >
                                                    <Typography className={classes.item}>{`${productName} ${productWeight}`}</Typography>
                                                    {
                                                        removedList.map((p, index) => {
                                                            const productTotal = p.quantity * p.price1
                                                            this.TOTAL_TO_PAY = this.TOTAL_TO_PAY + productTotal
                                                            return (
                                                                <Typography key={index} className={classes.item}>{`${p.quantity} x ${p.price1} ${currency} = ${fixDecimals(productTotal)} ${currency}`}</Typography>
                                                            )
                                                        })
                                                    }
                                                    {sku !== '' && <Typography className={classes.sku} component="span">{`${sku} ${quantity}`}</Typography>}

                                                </div>
                                            )
                                        }


                                        const productTotal = quantity * price1
                                        this.TOTAL_TO_PAY = this.TOTAL_TO_PAY + productTotal

                                        return (
                                            <div
                                                key={`productId-${categoryId}-${productId}`}
                                                style={{ paddingBottom: '6px', opacity: .5, background: 'transparent' }}
                                                onClick={this.goToProduct(categoryId, productId)}
                                            >
                                                <Typography className={classes.item}>{`${productName} ${productWeight}`}</Typography>
                                                <Typography className={classes.item}>{`${quantity} x ${price1} ${currency} = ${productTotal} ${currency}`}</Typography>
                                                {sku !== '' && <Typography className={classes.sku} component="span">{`${sku} ${quantity}`}</Typography>}
                                            </div>
                                        )
                                    })}

                                </div>
                            )
                        })
                    }
                </div>
            ]
        }

        return orderList
    }


    getFullOrderList = order => {

        const { classes, menu, currency, language } = this.props
        console.log('@getFullOrderList -> order', order)

        
        this.TOTAL_TO_PAY = 0

        let fullOrders = {}
        const flatenOrders = flatten(order.persons.map(o => o.orders.filter(o => o.status !== orderStates.ORDER_UNVERIFIED))).map(orderRequest => omit(orderRequest, ['id', 'status', 'timestamp', 'state']))
        
        for (const orderRequest of flatenOrders) {
            for (const categoryId of keys(orderRequest)) {
                for (const productId of keys(orderRequest[categoryId])) {
                    const savedProduct = fullOrders[categoryId] && fullOrders[categoryId][productId]
                    const currentProduct = orderRequest[categoryId][productId]

                    let newProduct
                    if (savedProduct === undefined) {
                        //nu exista salvat
                        newProduct = {
                            [productId]: {
                                pq: [{
                                    price1: currentProduct.price1,
                                    quantity: currentProduct.quantity
                                }]
                            }
                        }
                    } else {
                        if (savedProduct[productId].pq && (savedProduct[productId].pq || []).filter(p => p.price1 === currentProduct.price1)[0]) {
                            //exista produs cu acelasi pret
                            newProduct = {
                                ...savedProduct,
                                [productId]: {
                                    pq: (savedProduct[productId].pq || []).map(p => {
                                        if (p.price1 === currentProduct.price1) {
                                            return {
                                                ...p,
                                                quantity: p.quantity + currentProduct.quantity
                                            }
                                        }
                                        return p
                                    })
                                }
                            }
                            
                        } else {
                            //exista acelasi produs dar cu pret diferit 
                            newProduct = {
                                ...savedProduct,
                                [productId]: {
                                    pq: [
                                        ...((savedProduct[productId] || {}).pq || []),
                                        {
                                            price1: currentProduct.price1,
                                            quantity: currentProduct.quantity
                                        }
                                    ]
                                }
                            }
                        }
                    }
                    fullOrders = {
                        ...fullOrders,
                        [categoryId]: {
                            ...fullOrders[categoryId],
                            [productId]: newProduct
                        }
                    }
                }
            }
        }

        console.log('fullOrders', fullOrders)

        let orderList = []

        for (const categoryId of keys(fullOrders)) {
            const categoryName = menu.find(c => c.details.id === categoryId).details.name[language]

            let productsList = []
            for (const productId of keys(fullOrders[categoryId])) {
                const currentOrder = fullOrders[categoryId][productId]
                console.log('cuurentOrder', currentOrder)

                const product = menu.find(c => c.details.id === categoryId).items.find(p => p.id === productId)
                const productName = product.name[language]
                // const sku = (product.sku === undefined || product.sku === null) ? '' : product.sku
                const productWeight = (product.weight === undefined || product.weight === null || product.weight === '' || product.weight === '0' || product.weight === 0) ? '' : product.weight

                productsList = [
                    ...productsList,
                    <div key={`productId-${categoryId}-${productId}`} style={{ paddingBottom: 6, opacity: .5, background: 'transparent' }}>
                        <Typography className={classes.item}>{`${productName} ${productWeight}`}</Typography>
                        <div>
                            {
                                currentOrder[productId].pq.map(pq => {
                                    const productTotal = pq.quantity * pq.price1
                                    this.TOTAL_TO_PAY = this.TOTAL_TO_PAY + productTotal
                                    return (
                                        <div key={pq.price1}>
                                            <Typography className={classes.item}>{`${pq.quantity} x ${pq.price1} ${currency} = ${productTotal} ${currency}`}</Typography>
                                            {/* {sku !== '' && <Typography className={classes.sku} component="span">{`${sku} ${pq.quantity}`}</Typography>} */}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                ]

            }

            orderList = [
                ...orderList,

                <div key={uniqueId()} style={{ width: '100%' }}>
                    <div key={categoryId} style={{ paddingTop: 12, paddingBottom: 12, backgroundColor: 'transparent' }}>
                        <Typography className={classes.categoryName} style={{ opacity: .8, background: 'transparent' }}>
                            {`${categoryName}`}
                        </Typography>

                        {productsList}

                    </div>
                </div>
            ]
        }


        this.MONEY_ON_TABLE = parseFloat(order.moneyOnTable) || 0
        this.PAID = 0 
        for (const person of order.persons) {
            this.PAID = this.PAID + (parseFloat(person.paid) || 0)
        }

        return orderList
    }


    clearTable = () => {
        console.log('**** clearTable', this.props.selectedTable)
        this.handleCloseClearTableDialog()
    }


    handleOpenClearTableDialog = () => {
        this.setState({ openClearTable: true });
    };

    handleCloseClearTableDialog = () => {
        this.setState({ openClearTable: false });
    };


    doPayment = amount => {
        const { restaurantOrderId, personId } = this.props
        this.props.doPayment({ restaurantOrderId, personId, amount })
        this.clearTable()
        this.props.handleClose()
    }

    render() {
        console.log('OrderList -> render props', this.props)
        console.log('OrderList -> render state', this.state)

        const translate = this.translate
        const { classes, orders, currency, paid } = this.props

        let remainsToPay = this.props.remainsToPay

        let orderList
        let PAID
        if (Array.isArray(orders)) {
            orderList = this.getOrderList(orders)
            PAID = parseFloat(paid) - parseFloat(this.TOTAL_TO_PAY)
        } else {
            orderList = this.getFullOrderList(orders)
            PAID = (this.MONEY_ON_TABLE + this.PAID) - parseFloat(this.TOTAL_TO_PAY)
            if (PAID < 0) {
                remainsToPay = Math.abs(PAID)
            }
        }

        const TOTAL = fixDecimals(this.TOTAL_TO_PAY)

        const fab = {
            color: 'inherit',
            className: classNames(classes.fabPayOrderPerson),
            icon: <AttachMoney />,
        }

        console.log('OrderList -> Render ', orderList)

        console.log({ TOTAL_TO_PAY:parseFloat(this.TOTAL_TO_PAY), PAID, remainsToPay })
        return (
            <div className={classes.root}>
                <Paper>

                    {
                        (parseFloat(this.TOTAL_TO_PAY) > 1 && remainsToPay && parseInt(Math.abs(remainsToPay), 10) > 0) &&
                        <Fab
                            className={fab.className}
                            color={fab.color}
                            onClick={this.handleOpenClearTableDialog}
                        >
                            {fab.icon}
                        </Fab>
                    }

                    <div
                        style={{
                            height: 'calc(100% - 56px)',
                            background: 'aliceblue',
                            position: 'fixed',
                            bottom: '0',
                            width: '100%',
                            padding: 0
                        }}>
                        <List
                            id="orderlist"
                            className={classes.container}>
                            {orderList}

                            {
                                orderList.length > 0 && (
                                    <div style={{ marginBottom: '48px' }}>
                                        <div style={{ padding: '8px 0px' }}>{'- - - - - - - - - - - - - - - - - - - -'}</div>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                            
                                            <div style={{ display: 'flex', height: 28 }}>    
                                                <Typography className={`${classes.categoryName} ${(PAID >= 0 || parseInt(remainsToPay, 10) === 0) ? classes.green : {}}`} style={{ minWidth: 70, textAlign: 'left' }}>
                                                    {`${translate('Total').toUpperCase()}`}
                                                </Typography>
                                                <Typography className={`${classes.categoryName} ${(PAID >= 0 || parseInt(remainsToPay, 10) === 0) ? classes.green : {}}`} style={{ flex: 1, textAlign: 'right' }}>
                                                    {`${TOTAL} ${currency}`}
                                                </Typography>
                                            </div>

                                            {
                                                parseInt(Math.abs(remainsToPay), 10) > 0 &&
                                                <div style={{ display: 'flex', height: 28 }}>
                                                    <Typography className={`${classes.categoryName} ${(PAID >= 0 || parseInt(remainsToPay, 10) === 0) ? classes.green : {}}`} style={{ minWidth: 70, textAlign: 'left' }}>
                                                        {`${translate('Paid').toUpperCase()}`}
                                                    </Typography>

                                                    <Typography className={`${classes.categoryName} ${classes.green}`} style={{ flex: 1, textAlign: 'right' }}>
                                                        {`${fixDecimals(parseFloat(this.TOTAL_TO_PAY) - Math.abs(PAID))} ${currency}`}
                                                    </Typography>
                                                </div>
                                            }

                                            {
                                                parseInt(Math.abs(remainsToPay), 10) > 0 &&
                                                <div style={{ display: 'flex', height: 28 }}>
                                                    <Typography className={`${classes.categoryName} ${(PAID >= 0 || parseInt(remainsToPay, 10) === 0) ? classes.green : {}}`} style={{ minWidth: 70, textAlign: 'left' }}>
                                                        {`${translate('Must pay').toUpperCase()}`}
                                                    </Typography>

                                                    <Typography className={`${classes.categoryName} ${classes.red}`} style={{ flex: 1, textAlign: 'right' }}>
                                                        {`${fixDecimals(remainsToPay)} ${currency}`}
                                                    </Typography>
                                                </div>
                                            }
                                            
                                        </div>
                                    </div>
                                )
                            }
                        </List>

                        {remainsToPay &&
                            <Payment props={{
                                translate: this.translate,
                                clearTable: this.clearTable,
                                doPayment: amount => this.doPayment(amount),
                                
                                TOTAL_TO_PAY: fixDecimals(remainsToPay),
                                openClearTable: this.state.openClearTable,
                                currency
                            }} />
                        }


                    </div>

                </Paper>
            </div>
        )
    }
}

OrderList.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(OrderList)
