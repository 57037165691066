import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Notifications from '@material-ui/icons/Notifications';
import Info from '@material-ui/icons/InfoOutlined';
import Payment from '@material-ui/icons/Payment';
import Money from '@material-ui/icons/MoneyOutlined';
import SpeakerNotesOutlined from '@material-ui/icons/SpeakerNotesOutlined';
import teal from '@material-ui/core/colors/teal';
import { waiterServices } from '../../../configs/constants';
import translateAction from '../../../util/translate';
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from '@material-ui/core/Fab';

const useStyles = makeStyles((theme) =>
  createStyles({
    fab: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      width: 48,
      height: 48,
      color: theme.palette.common.white,
      fontSize: 'larger',
      backgroundColor: teal[500],
      '&:hover': {
          backgroundColor: teal[600],
      },
    },
    dialog: {
      width: '70%'
    },
    dialogTitle: { 
      fontFamily: `'Oswald', sans-serif`,
      fontSize: 22,
    },
    dialogContentButton: { 
      fontFamily: `'Oswald', sans-serif`, 
      width: 180, 
      display: 'flex', 
      justifyContent: 'space-between', 
      color: '#666',
      fontSize: 17,
    },
    dialogActionsButton: {
      fontFamily: `'Oswald', sans-serif`, 
      color: teal[500]
    }
  })
);

export default function SpeedDials(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const translate = (text) => {
    return translateAction(text, props.language);
  };

  const handleClick = (serviceType) => {
    console.log(serviceType);
    props.callWaiterFor(serviceType);
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const actions = [
    { icon: <Info />, serviceType: waiterServices.INFO },
    { icon: <SpeakerNotesOutlined />, serviceType: waiterServices.WANT_TO_ORDER },
    { icon: <Money />, serviceType: waiterServices.PAY_CASH },
    { icon: <Payment />, serviceType: waiterServices.PAY_CARD },
  ];


  return (
    <div>
      <Fab className={classes.fab} onClick={handleOpen}>
        <Notifications />
      </Fab>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle disableTypography className={classes.dialogTitle}>{translate(`Call for waiter`)}</DialogTitle>
        <DialogContent>
          {actions.map((action) => (
            <Button
              className={classes.dialogContentButton}
              key={translate(action.serviceType)}
              onClick={() => handleClick(action.serviceType)}
              endIcon={action.icon}
            >{translate(action.serviceType.replace('/_/g', ' '))}</Button>
          ))}
        </DialogContent>
        <DialogActions>
          <Button className={classes.dialogActionsButton} onClick={handleClose}>
            {translate(`Later`)}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
