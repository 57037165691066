export default `
    Menu                    = Μενού
    Welcome                 = Καλωσόρισμα
    Price                   = Τιμή
    Add                     = Προσθέστε
    Remove                  = Αφαίρεση
    Order list              = Λίστα παραγγελιών
    Order                   = Παραγγελία
    Place order             = Παραγγελία
    Tables                  = Πίνακες
    Pay                     = Πληρωμή
    Recommended             = Συνιστάται
    Total                   = Σύνολο
`

