/* eslint-disable react/no-multi-comp */
import omit from 'lodash/omit'
import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

import QrReader from 'react-qr-reader'


const styles = {
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
};

class ReadOrderQR extends Component {

    constructor(props) {
        super(props)
        this.state = {
            delay: 300,
            result: 'No result',
        }
        this.handleScan = this.handleScan.bind(this)
    }

    componentWillMount() {
        const isAndroid = /Android/i.test(navigator.userAgent)
        if (isAndroid) {
            this.fixQRCodeScanner()
        }
    }

    fixQRCodeScanner = async () => {

        const old = function (constraints) {
            // First get ahold of the legacy getUserMedia, if present
            var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.getUserMedia;
            // Some browsers just don't implement it - return a rejected promise with an error
            // to keep a consistent interface
            if (!getUserMedia) {
                return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
            }
            // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
            return new Promise(function (resolve, reject) {
                getUserMedia.call(navigator, constraints, resolve, reject);
            });
        }

        try {
            navigator.mediaDevices.getUserMedia = async (params) => {
                const devices = await navigator.mediaDevices.enumerateDevices()
                for (const device of devices) {
                    if (
                        device.kind === 'videoinput' &&
                        (device.label.toLowerCase().indexOf('usb') !== -1 || device.label.toLowerCase().indexOf('webcam') !== -1)
                    ) {
                        return old({ video: true })
                    }
                }
                return old({ video: { facingMode: { exact: "environment" } } })
            }
        } catch (err) {
            return old({ video: { facingMode: { exact: "environment" } } })
        }

    }

    handleScan(qrCodeData) {
        if (qrCodeData) {

            console.log('Scanned QR code ->', qrCodeData)

            try {
                const order = JSON.parse(qrCodeData)
                console.log('have order ->', order)
                this.props.handleQRCodeData({ 
                    order: { ...omit(order, ['restaurantId', 'tableId']) }, 
                    restaurantId: order.restaurantId, 
                    tableId: order.tableId 
                })
            } catch (err) { }

            try {
                let restaurantUrl
                let tableId
                
                restaurantUrl = qrCodeData.split('/#/')[1].split('/')[0]
                tableId = qrCodeData.split(`${restaurantUrl}/`)[1]
                console.log('@ReadOrderQR - handleScan', { restaurantUrl, tableId })

                this.props.handleQRCodeData({ restaurantUrl, tableId })
            } catch (err) { 
                const redirectUrl = prompt('Redirect ?', qrCodeData) 
                if (redirectUrl) {
                    return window.location.replace(redirectUrl)
                }
            }
        }
    }

    handleError(err) {
        console.error(err)
    }


    handleListItemClick = value => {
        console.log('click on', value)
    };

    render() {

        console.log('@ReadOrderQR component render', this.props)

        return (
            <div>
                <QrReader
                    delay={this.state.delay}
                    onError={this.handleError}
                    onScan={this.handleScan}
                    style={{ width: '100%' }}
                />
            </div>

        );
    }
}

ReadOrderQR.propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    selectedValue: PropTypes.string,
};

export default withStyles(styles)(ReadOrderQR);
