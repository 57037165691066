import axios from 'axios'
import orderBy from 'lodash/orderBy'
import groupBy from 'lodash/groupBy'
import keys from 'lodash/keys'
import omit from 'lodash/omit'
import * as api from './api'
import * as constants from './constants'
import { RESTAURANT_ID } from '../appActions'
import { orderStates, tableIs, URL_UPDATE_USER } from '../../configs/constants';
import { fixDecimals } from '../../util/restaurant';
import { smallImageURL } from './../../util/smallImageURL'
import { adaptorAssignedTables, getTables } from './utils'
import { isCategoryAvailableNow } from './../../util/available'

export const WAITER_LOGOUT = `${constants.MODULE_NAME}/LOGOUT`
export const MENU = `${constants.MODULE_NAME}/MENU`
export const WAITER_INFO = `${constants.MODULE_NAME}/INFO`
export const SELECTED_TABLE = `${constants.MODULE_NAME}/SELECTED_TABLE`
export const ORDERS = `${constants.MODULE_NAME}/ORDERS`
export const ORDERS_QR_CODE = `${constants.MODULE_NAME}/ORDERS_QR_CODE`
export const CLEAR_TABLE = `${constants.MODULE_NAME}/CLEAR_TABLE`
export const OPEN_ORDERS = `${constants.MODULE_NAME}/OPEN_ORDERS`
export const ADD_NEW_PERSON_AT_TABLE = `${constants.MODULE_NAME}/ADD_NEW_PERSON_AT_TABLE`

export const LANGUAGE = `${constants.MODULE_NAME}/LANGUAGE`
export const LAST_SELECTED_CATEGORY = `${constants.MODULE_NAME}/LAST_SELECTED_CATEGORY`

export const RESTAURANT_MENU = `${constants.MODULE_NAME}/RESTAURANT_MENU`
export const DISPLAY_ORDER = `${constants.MODULE_NAME}/DISPLAY_ORDER`
export const ORDER_CONFIRMED = `${constants.MODULE_NAME}/ORDER_CONFIRMED`
export const CLEAR_UNVERIFIED_ORDER = `${constants.MODULE_NAME}/CLEAR_UNVERIFIED_ORDER`

export const FEEDBACKS = `${constants.MODULE_NAME}/FEEDBACKS`

const applySortOrder = (items, sortOrder) => {
    if (!(sortOrder && sortOrder.length)) {
        return items;
    }

    return items.slice()
        .sort((a, b) => sortOrder.indexOf(a.id) - sortOrder.indexOf(b.id))
}


export const waiterLogout = () => (dispatch, getState, { getFirebase, getFirestore }) => {
    let s = {}
    try {
        s = JSON.parse(localStorage.getItem('s'))
        if (s && s.w) {
            axios.post(URL_UPDATE_USER, { 
                uid: s.w, 
                updatedData:  { 
                    isWaiterLoggedIn: false 
                }
            })
        }
    } catch (err) { }
    
    localStorage.removeItem('l')
    if (s && s.r) {
        localStorage.setItem('s', JSON.stringify({ r: s.r }))
    }

    dispatch({
        type: WAITER_LOGOUT
    })
}

export const getWaiterLanguage = () => async (dispatch, getState) => {
    const restaurantId = getState().SCANNER[RESTAURANT_ID]

    const response = await api.getLanguages(restaurantId)
    // TODO default language 
    const defaultLanguage = response.data.languages[0]
    const defaultLanguageCode = defaultLanguage !== undefined ? defaultLanguage.code : response.data[0].code

    return dispatch({
        type: LANGUAGE,
        payload: defaultLanguageCode,
    })
}

export const getMenu = () => async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({
        type: MENU,
        payload: 'LOADING'
    })
    const restaurantId = getState().SCANNER[RESTAURANT_ID]

    const db = getFirestore()

    let docRef = db.collection("restaurants").doc(restaurantId)
    docRef.get().then(function (doc) {
        console.log('*** firestore', { doc })
        if (doc.exists) {
            const restaurant = doc.data()
            console.log('*** firestore 1-> Restaurant data:', { restaurant })
            if (restaurant.active) {
                const refCategories = db.collection(`categories`).where('restaurantId', '==', restaurantId)
                console.log('*** firestore 2-> refCategories', { refCategories })
                refCategories.onSnapshot({ includeMetadataChanges: true }, snapshotCategorii => {
                    const categories = applySortOrder(snapshotCategorii.docs
                        .filter(doc => doc.data().available || (!doc.data().available && doc.data().availabilities !== undefined && doc.data().availabilities.length > 0) )
                        .map(doc => {
                            const thumbImg = smallImageURL(doc.data().imageSrc, "thumb@256_");
                            return ({ id: doc.id, ...doc.data(), thumbImg })
                        })
                        , restaurant.categoriesOrder)

                    console.log('ordered', { categories })
                    const categoryIds = categories.map(categ => categ.id)


                    const refProducts = db.collection(`products`).where('restaurantId', '==', restaurantId)
                    console.log('*** firestore 3-> refProducts', { refProducts })

                    refProducts.onSnapshot({ includeMetadataChanges: true }, snapshotProducts => {
                        const productsGroupedByCategory = groupBy(
                            snapshotProducts.docs
                                .filter(doc => categoryIds.includes(doc.data().categoryId))
                                .map(doc => {
                                    const thumbImg = smallImageURL(doc.data().imageSrc, "thumb@256_");
                                    return ({ id: doc.id, ...doc.data(), thumbImg, area: 'KITCHEN' })
                                })
                            , 'categoryId')
                        
                        let products = {}
                        Object.keys(productsGroupedByCategory).forEach(categoryId => {
                            const { productsOrder = null} = categories.find(category => category.id === categoryId)
                            console.log({productsOrder})
                            products = {
                                ...products, 
                                [categoryId]: applySortOrder(productsGroupedByCategory[categoryId], productsOrder) 
                            }
                        })


                        console.log('*** firestore 4', { categories })
                        console.log('*** firestore 5', { products: groupBy(products, 'categoryId') })

                        const menu = categories.map(category => {
                            return ({
                                details: { ...category },
                                items: products[category.id] === undefined ? [] : [...products[category.id]]
                            })
                        }).filter(m => m.items.length > 0)

                        console.log('*** firestore 6', { menu })

                        const recommendedProducts = orderBy(snapshotProducts.docs
                            .filter(doc => categoryIds.includes(doc.data().categoryId))
                            .filter(doc => {
                                const isThisCategoryAvailable = isCategoryAvailableNow(categories.find(({ id }) => id === doc.data().categoryId)?.availabilities)
                                return isThisCategoryAvailable && doc.data().recommended && doc.data().available
                            })
                            .map(doc => {
                                const thumbImg = smallImageURL(doc.data().imageSrc, "thumb@256_");
                                return ({ 
                                    id: doc.id, 
                                    categoryPosition: categories.filter(c => c.id === doc.data().categoryId)[0].position, 
                                    ...doc.data(), 
                                    thumbImg,
                                    area: 'KITCHEN' 
                                })
                            })
                            , 'categoryPosition')



                        if (recommendedProducts.length > 0) {
                            const recommended = {
                                details: {
                                    id: 'recommended',
                                    name: ''
                                },
                                items: [...recommendedProducts]
                            }
                            return dispatch({
                                type: MENU,
                                payload: [{ ...recommended }, ...menu]

                            })
                        }

                        return dispatch({
                            type: MENU,
                            payload: [...menu]

                        })
                    })
                })


            } else {
                console.log('*** firestore Restaurant not available')
            }
        } else {
            console.log('*** firestore Restaurant not exists in our db!');
        }
    }).catch(function (error) {
        console.log('*** firestore -> Error getting document:', error);
    });
}


export const selectTable = ({ restaurantOrderId, tableId }) => async (dispatch, getState, { getFirebase, getFirestore }) => {
    const restaurantId = getState().SCANNER['restaurantId']
    console.log({ restaurantId, restaurantOrderId, tableId })

    if (restaurantOrderId) {
        const db = getFirestore()
        const refTableOrders = db.collection(`restaurants/${restaurantId}/horecaOrders`).doc(restaurantOrderId)

        refTableOrders.update({ 
            INFO: getFirebase().firestore.FieldValue.delete(), 
            WANT_TO_ORDER: getFirebase().firestore.FieldValue.delete() 
        })
    }

    dispatch({
        type: SELECTED_TABLE,
        payload: `${tableId}`
    })
}


export const clearTableNotifications = ({ restaurantOrderId, tableId }) => async (dispatch, getState, { getFirebase, getFirestore }) => {
    const restaurantId = getState().SCANNER['restaurantId']
    console.log('@clearTableNotifications -->', { restaurantId, restaurantOrderId, tableId })

    if (restaurantOrderId) {
        const db = getFirestore()
        const refTableOrders = db.collection(`restaurants/${restaurantId}/horecaOrders`).doc(restaurantOrderId)

        refTableOrders.update({ 
            INFO: getFirebase().firestore.FieldValue.delete(),
            WANT_TO_ORDER: getFirebase().firestore.FieldValue.delete(),
            PAY: getFirebase().firestore.FieldValue.delete(), 
            'PAY-extra': getFirebase().firestore.FieldValue.delete(),
            PAY_CARD: getFirebase().firestore.FieldValue.delete(), 
            PAY_CASH: getFirebase().firestore.FieldValue.delete()
        })
    }
}


export const handleOrderChange = (newOrder) => (dispatch, getState) => {
    if (newOrder.ordersId === null) {
        const currency = getState().RESTAURANT.INFO.defaultCurrency
        const restaurantId = getState().SCANNER.restaurantId
        const waiter = getState().SCANNER.waiterId
        const tableId = getState().WAITER[SELECTED_TABLE]

        return dispatch({
            type: ORDERS,
            payload: { ...newOrder, currency, restaurantId, waiter, tableId }
        })
    }

    dispatch({
        type: ORDERS,
        payload: newOrder
    })
}


export const handleQRCodeData = (qrCodeData) => (dispatch, getState) => {
    const { restaurantId, restaurantUrl } = getState().SCANNER

    if (restaurantUrl !== qrCodeData.restaurantId && restaurantId !== qrCodeData.restaurantId) {
        return
    }

    dispatch({
        type: ORDERS_QR_CODE,
        payload: { ...qrCodeData, restaurantId }
    })
}


export const clearTable = (selectedTable) => dispatch => {
    dispatch({
        type: CLEAR_TABLE,
        payload: selectedTable
    })
}


export const lastCategoryChange = (selectedCategory) => dispatch => {
    dispatch({
        type: LAST_SELECTED_CATEGORY,
        payload: selectedCategory
    })
}


export const getOpenedOrders = () => (dispatch, getState, { getFirebase, getFirestore }) => {
    console.log('@waiter -> actions -> getOpenedOrders')
    const restaurantId = getState().SCANNER[RESTAURANT_ID]
    const waiterId = getState().SCANNER.waiterId
    const db = getFirestore()

    const refOpenOrders = db.collection(`restaurants/${restaurantId}/horecaOrders`)
        .where('restaurantId', '==', restaurantId)
        .where('status', '==', 'OPEN')

    console.log('*** firestore -> tables who needs a waiter', { refOpenOrders })
    refOpenOrders.onSnapshot({ includeMetadataChanges: true }, snapshotOrders => {
        const openOrders = orderBy(
            snapshotOrders.docs
            // .filter(doc => doc.data().waiter === waiterId || doc.data().waiter === '')
            .map(doc => {
                const tableHasAnotherWaiter = doc.data().waiter && doc.data().waiter !== '' && doc.data().waiter !== waiterId                 
                if (tableHasAnotherWaiter) {
                    return ({
                        id: doc.id,
                        tableId: doc.data().tableId,
                        waiterName: doc.data().waiterName
                    })
                }
                return ({
                    id: doc.id,
                    ...doc.data(),
                    timestamp: doc.data().timestamp.toMillis()
                })
            }),
            'tableId'
        )
        console.log('getOpenedOrders', { openOrders, snapshotOrders:snapshotOrders.docs })
        dispatch({
            type: OPEN_ORDERS,
            payload: openOrders
        })
    })

}


export const getSelectedTableOrders = selectedTableOrder => async (dispatch, getState, { getFirebase, getFirestore }) => {
    console.log('@waiter -> actions -> getSelectedTableOrders', selectedTableOrder)
    dispatch({
        type: ORDERS,
        payload: selectedTableOrder
    })
}


export const setOrderStatus = ({ restaurantOrderId, personId, personOrderId, status }) => async (dispatch, getState, { getFirebase, getFirestore }) => {
    console.log('@waiter -> actions -> setOrderStatus', { restaurantOrderId, personId, personOrderId, status })

    const currency = getState().RESTAURANT.INFO.defaultCurrency
    const restaurantId = getState().SCANNER.restaurantId
    const waiterId = getState().SCANNER.waiterId
    const waiterName = getState().WAITER[WAITER_INFO].name
    const tableId = getState().WAITER[SELECTED_TABLE]

    if (status === orderStates.ORDER_CONFIRMED) {

        dispatch({
            type: ORDER_CONFIRMED,
            payload: { personId, personOrderId, tableId }
        })
    }

    const db = getFirestore()

    const waiterStateOrder = getState().WAITER[OPEN_ORDERS].filter(o => o.id === restaurantOrderId)[0]
    const waiterStatePersonOrder = waiterStateOrder.persons.filter(p => p.id === personId)[0]

    const hasToPay = (orders) => {
        let TOTAL = 0
        for (const orderRequest of orders) {
            for (const categoryId of keys(omit(orderRequest, ['id', 'status', 'timestamp', 'state']))) {
                for (const productId of keys(orderRequest[categoryId])) {
                    const quantity = orderRequest[categoryId][productId].quantity
                    let price1 = orderRequest[categoryId][productId].price1
                    const productTotal = quantity * price1
                    TOTAL = TOTAL + productTotal
                }
            }
        }
        console.log('hasToPay', { orders, TOTAL })
        return parseFloat(fixDecimals(TOTAL))
    }

    let TABLE_TOTAL = 0
    for (const person of waiterStateOrder.persons) {
        TABLE_TOTAL = TABLE_TOTAL + hasToPay(person.orders)
    }

    if (restaurantOrderId === null) {
        const total = hasToPay(waiterStatePersonOrder.orders)

        return db.collection(`restaurants/${restaurantId}/horecaOrders`).add({
            restaurantId,
            waiter: waiterId || '',
            waiterName: waiterName || '',
            tableId,
            currency,
            timestamp: getFirebase().firestore.Timestamp.fromDate(new Date()),
            status: tableIs.OPEN,

            persons: [{
                id: personId,
                hasToPay: `${total} ${currency.toUpperCase()}`,
                orders: waiterStatePersonOrder.orders.map(po => {
                    if (po.id !== undefined) { return ({ ...po, status }) }
                    return ({ ...po, id: Date.now(), status })
                })
            }],
            total: TABLE_TOTAL
        })
    }


    const refTableOrders = db.collection(`restaurants/${restaurantId}/horecaOrders`).doc(restaurantOrderId)
    const tableOrdersData = await db.collection(`restaurants/${restaurantId}/horecaOrders`).doc(restaurantOrderId).get()
    const tableOrders = tableOrdersData.data()
    console.log('tableOrders', tableOrders)

    if (tableOrders !== undefined) {
        
        refTableOrders.update({ ORDER: getFirebase().firestore.FieldValue.delete() })
        
        const personHasToPay = hasToPay(waiterStatePersonOrder.orders)

        const isNewPersonOnTheTable = (tableOrders.persons || []).filter(p => p.id === personId)[0] === undefined
        if (isNewPersonOnTheTable) {
            
            
            return refTableOrders.update({
                waiter: waiterId || '',
                waiterName: waiterName || '',
                persons: [...(tableOrders.persons || []), {
                    id: personId,
                    hasToPay: `${personHasToPay} ${currency.toUpperCase()}`,
                    orders: waiterStatePersonOrder.orders.map(data => {
                        const po = { ...omit(data, ['state']) }
                        if (po.id !== undefined) { return ({ ...po, status }) }
                        return ({ ...po, id: Date.now(), status })
                    })
                }],
                total: TABLE_TOTAL
            })
        }


        return refTableOrders.update({
            waiter: waiterId || '',
            waiterName: waiterName || '',
            persons: tableOrders.persons.map(person => {
                if (person.id !== personId) { return person }
                return ({
                    ...person,
                    hasToPay: `${personHasToPay} ${currency.toUpperCase()}`,
                    orders: waiterStatePersonOrder.orders.map(data => {
                        const po = { ...omit(data, ['state']) }
                        if (po.id !== undefined) { return ({ ...po, status }) }
                        return ({ ...po, id: Date.now(), status })
                    })
                })
            }),
            total: TABLE_TOTAL
        })
    }


}


export const restaurantMenu = ({ personId, display = true }) => async (dispatch, getState, { getFirebase, getFirestore }) => {
    const restaurantMenu = { personId, display }
    console.log('@waiter -> actions -> restaurantMenu', restaurantMenu)
    dispatch({
        type: RESTAURANT_MENU,
        payload: restaurantMenu
    })
}


export const displayOrder = ({ personId, display = true, remainsToPay }) => async (dispatch, getState, { getFirebase, getFirestore }) => {
    const displayOrder = { personId, display, remainsToPay }
    console.log('@waiter -> actions -> displayOrder', displayOrder)
    dispatch({
        type: DISPLAY_ORDER,
        payload: displayOrder
    })
}



export const addNewPersonAtTable = ({ restaurantOrderId, personId }) => async (dispatch, getState, { getFirebase, getFirestore }) => {
    console.log('@waiter -> actions -> addNewPersonAtTable', restaurantOrderId, personId)

    if (restaurantOrderId) {
        return dispatch({
            type: ADD_NEW_PERSON_AT_TABLE,
            payload: { restaurantOrderId, personId }
        })
    }

    const restaurantId = getState().SCANNER.restaurantId
    const waiter = getState().SCANNER.waiterId
    const currency = getState().RESTAURANT.INFO.defaultCurrency
    const tableId = getState().WAITER[SELECTED_TABLE]

    const db = getFirestore()

    db.collection(`restaurants/${restaurantId}/horecaOrders`).add({
        restaurantId,
        waiter,
        tableId,
        currency,
        timestamp: getFirebase().firestore.Timestamp.fromDate(new Date()),
        status: tableIs.OPEN,

        persons: [{
            id: personId,
            orders: []
        }]
    })

}

//
//  AICI E VARIANTA IN CARE SE PRIMESC BANI LA MASA SAU CINEVA PLATESTE MAI MULT SI SE IMPART BANII
//
// export const doPayment = ({ restaurantOrderId, personId, amount }) => async (dispatch, getState, { getFirebase, getFirestore }) => {
//     console.log('@waiter -> actions -> doPayment', { restaurantOrderId, personId, amount })
//     const currency = getState().RESTAURANT.INFO.defaultCurrency

//     const db = getFirestore()

//     const refTableOrders = db.collection(`restaurants/${restaurantId}/horecaOrders`).doc(restaurantOrderId)
//     const tableOrdersData = await db.collection(`restaurants/${restaurantId}/horecaOrders`).doc(restaurantOrderId).get()
//     const tableOrders = tableOrdersData.data()
    
//     const remainedExtraPays = (tableOrders['PAY-extra'] || []).filter(pay => pay.personId !== personId)
//     if (remainedExtraPays.length === 0) {
//         refTableOrders.update({ PAY: getFirebase().firestore.FieldValue.delete(), 'PAY-extra': getFirebase().firestore.FieldValue.delete() })
//     } else {
//         refTableOrders.update({ 'PAY-extra': remainedExtraPays })
//     }

//     let MONEY_ON_TABLE = parseFloat(tableOrders.moneyOnTable) || 0

//     if (personId) {
//         return refTableOrders.update({
//             persons: tableOrders.persons.map(person => {
//                 if (person.id !== personId) { return person }

//                 const hasToPay = parseFloat(person.hasToPay) || 0
//                 let paid = (parseFloat(person.paid) || 0) + parseFloat(amount)

//                 if (paid - hasToPay > 0) {
//                     MONEY_ON_TABLE = MONEY_ON_TABLE + (paid - hasToPay)
//                     paid = hasToPay
//                 }

//                 return ({
//                     ...person,
//                     paid: `${paid} ${currency.toUpperCase()}`,
//                 })
//             }),
//             moneyOnTable: `${MONEY_ON_TABLE} ${currency.toUpperCase()}`
//         })

//         // if (MONEY_ON_TABLE === 0) {
//         //     refTableOrders.update({ moneyOnTable: getFirebase().firestore.FieldValue.delete() })
//         // } 
//     }

//     console.log('MONEY_ON_TABLE', MONEY_ON_TABLE, parseFloat(amount), (MONEY_ON_TABLE + parseFloat(amount)))

//     MONEY_ON_TABLE = (MONEY_ON_TABLE + parseFloat(amount))
//     return refTableOrders.update({
//         moneyOnTable: `${MONEY_ON_TABLE} ${currency.toUpperCase()}`
//     })

// }



export const doPayment = ({ restaurantOrderId, personId, amount }) => async (dispatch, getState, { getFirebase, getFirestore }) => {
    const restaurantId = getState().SCANNER['restaurantId']

    console.log('@waiter -> actions -> doPayment', { restaurantId, restaurantOrderId, personId, amount })
    const currency = getState().RESTAURANT.INFO.defaultCurrency

    const db = getFirestore()

    const refTableOrders = db.collection(`restaurants/${restaurantId}/horecaOrders`).doc(restaurantOrderId)
    const tableOrdersData = await db.collection(`restaurants/${restaurantId}/horecaOrders`).doc(restaurantOrderId).get()
    const tableOrders = tableOrdersData.data()
    
    const remainedExtraPays = (tableOrders['PAY-extra'] || []).filter(pay => pay.personId !== personId)
    if (remainedExtraPays.length === 0) {
        refTableOrders.update({ 
            PAY: getFirebase().firestore.FieldValue.delete(), 
            'PAY-extra': getFirebase().firestore.FieldValue.delete(),
            PAY_CARD: getFirebase().firestore.FieldValue.delete(), 
            PAY_CASH: getFirebase().firestore.FieldValue.delete()
        })
    } else {
        refTableOrders.update({ 'PAY-extra': remainedExtraPays })
    }


    if (personId) {
        // SE ACHITA INTEGRAL PENTRU UNA DIN PERSOANELE DE LA MASA
        return refTableOrders.update({
            persons: tableOrders.persons.map(person => {
                if (person.id !== personId) { return person }

                const hasToPay = parseFloat(person.hasToPay) || 0
                let paid = (parseFloat(person.paid) || 0) + parseFloat(amount)

                if (paid - hasToPay > 0) {
                    paid = hasToPay
                }

                return ({
                    ...person,
                    paid: `${paid} ${currency.toUpperCase()}`,
                })
            })
        })
    }

    // IN CAZUL ACESTA SE ACHITA INTREAGA COMANDA DE LA MASA
    return refTableOrders.update({
        PAY: getFirebase().firestore.FieldValue.delete(), 
        'PAY-extra': getFirebase().firestore.FieldValue.delete(),
        persons: tableOrders.persons.map(person => {
            const hasToPay = parseFloat(person.hasToPay) || 0
            return ({
                ...person,
                paid: `${hasToPay} ${currency.toUpperCase()}`,
            })
        })
    })

}


export const closeTable = params => async (dispatch, getState, { getFirebase, getFirestore }) => {
    const { restaurantOrderId, tip } = params
    const restaurantId = getState().SCANNER['restaurantId']
    console.log('@waiter -> actions -> closeTable', { restaurantId, restaurantOrderId })

    const db = getFirestore()

    const refTableOrders = db.collection(`restaurants/${restaurantId}/horecaOrders`).doc(restaurantOrderId)
    const tableOrdersData = await db.collection(`restaurants/${restaurantId}/horecaOrders`).doc(restaurantOrderId).get()
    const tableOrders = tableOrdersData.data()

    console.log('waiter -> actions -> closeTable', { tableOrdersData, tableOrders })
    if ( !tableOrders.persons || tableOrders.persons.length === 0 || (tableOrders.persons[0].orders && tableOrders.persons[0].orders.length === 0) ) {
        return refTableOrders.delete()
    }

    return refTableOrders.update({
        tip,
        status: tableIs.CLOSED
    })
}


export const removePerson = ({ restaurantOrderId, personId }) => async (dispatch, getState, { getFirebase, getFirestore }) => {
    const restaurantId = getState().SCANNER['restaurantId']

    console.log('@waiter -> actions -> removePerson', { restaurantId, restaurantOrderId, personId })

    const db = getFirestore()

    const refTableOrders = db.collection(`restaurants/${restaurantId}/horecaOrders`).doc(restaurantOrderId)
    const tableOrdersData = await db.collection(`restaurants/${restaurantId}/horecaOrders`).doc(restaurantOrderId).get()
    const tableOrders = tableOrdersData.data()

    if (personId) {
        dispatch({
            type: RESTAURANT_MENU,
            payload: { personId: null, display: false }
        })
        if ( !tableOrders.persons || tableOrders.persons.length === 0 || (tableOrders.persons[0].orders && tableOrders.persons[0].orders.length === 0) ) {
            return refTableOrders.delete()
        }
        return refTableOrders.update({
            persons: [ ...tableOrders.persons.filter(person => person.id !== personId) ]
        })
    }
}


export const clearAndBlockPerson = ({ restaurantOrderId, personId }) => async (dispatch, getState, { getFirebase, getFirestore }) => {
    const restaurantId = getState().SCANNER['restaurantId']

    console.log('@waiter -> actions -> clearAndBlockPerson', { restaurantId, restaurantOrderId, personId })

    const db = getFirestore()

    const refTableOrders = db.collection(`restaurants/${restaurantId}/horecaOrders`).doc(restaurantOrderId)
    const tableOrdersData = await db.collection(`restaurants/${restaurantId}/horecaOrders`).doc(restaurantOrderId).get()
    const tableOrders = tableOrdersData.data()
    
    if (personId) {
        dispatch({
            type: RESTAURANT_MENU,
            payload: { personId: null, display: false }
        })
        
        return refTableOrders.update({
            blockedPersonsIds: { [personId]: Date.now() },
            persons: [ ...tableOrders.persons.filter(person => person.id !== personId) ]
        })
    }
}


export const clearUnverifiedOrder = ({ restaurantOrderId, personId }) => async (dispatch, getState, { getFirebase, getFirestore }) => {
    const restaurantId = getState().SCANNER['restaurantId']
    
    console.log('@waiter -> actions -> clearUnverifiedOrder', { restaurantId, restaurantOrderId, personId })

    const db = getFirestore()

    const refTableOrders = db.collection(`restaurants/${restaurantId}/horecaOrders`).doc(restaurantOrderId)
    const tableOrdersData = await db.collection(`restaurants/${restaurantId}/horecaOrders`).doc(restaurantOrderId).get()
    const tableOrders = tableOrdersData.data()

    if (personId) {
        refTableOrders.update({
            persons: tableOrders.persons.map(person => {
                if (person.id !== personId) { return person }

                return {
                    ...person,
                    orders: [ ...person.orders.filter(order => order.status !== orderStates.ORDER_UNVERIFIED) ]
                }
            }) 
        })

        return dispatch({
            type: CLEAR_UNVERIFIED_ORDER,
            payload: { restaurantOrderId, personId }
        })
    }
}





export const getLiveFeedbacks = () => (dispatch, getState, { getFirebase, getFirestore }) => {
    console.log('@waiter -> actions -> getLiveFeedbacks')
    const restaurantId = getState().SCANNER[RESTAURANT_ID]
    // const waiterId = getState().SCANNER.waiterId
    const db = getFirestore()

    const refFeedbacks = db.collection(`restaurants/${restaurantId}/feedbacks`)
        // .where('READ_WAITER', '==', false)
        // .where('status', '==', 'OPEN')

    console.log('*** firestore -> feedbacks', { refFeedbacks })
    refFeedbacks.onSnapshot({ includeMetadataChanges: true }, snapshotFeedbacks => {
        const feedbacks = orderBy(
            snapshotFeedbacks.docs.map(doc => {
                return ({
                    id: doc.id,
                    ...doc.data(),
                    timestamp: doc.data().timestamp.toMillis()
                })
            }),
            ['timestamp'], ['desc']
        )
        console.log('getOpenedOrders', { feedbacks, snapshotFeedbacks:snapshotFeedbacks.docs })
        
        dispatch({
            type: FEEDBACKS,
            payload: feedbacks
        })
    })

}



export const saveAssigningTablesChanges = (updatedAssignedTables) => async (dispatch, getState, { getFirebase, getFirestore }) => {
    console.log('Waiter -> actions -> saveAssigningTablesChanges')
    const tablesConfig = getState().RESTAURANT.INFO.tablesConfig
    const waiter = getState().WAITER[WAITER_INFO]

    const waiterUid = getState().SCANNER['waiterId']
    const db = getFirestore()

    const refWaiter = await db.collection('users').where('uid', '==', waiterUid).get();
    const docId = refWaiter.docs[0].id
    const waiterData = refWaiter.docs[0].data()

    const assignedTables = adaptorAssignedTables({ assignedTables: updatedAssignedTables, tablesConfig })

    db.collection('users').doc(docId).update({ ...waiterData, assignedTables })

    let tables = updatedAssignedTables
    if (Object.keys(updatedAssignedTables).length === 0) {
        tables = getTables({ tablesConfig, assignedTables })
    }

    return dispatch({
        type: WAITER_INFO,
        payload: { ...waiter, tables }
    })
}

