import * as constants from './constants'

export const SET_LANGUAGE = `${constants.MODULE_NAME}/SET_LANGUAGE`
export const RESET_LANGUAGE = `${constants.MODULE_NAME}/RESET_LANGUAGE`
 
export const setLanguage = (lanugage) => dispatch => {
    return dispatch({
        type: SET_LANGUAGE,
        payload: lanugage
    })
}

export const resetLanguage = () => dispatch => {
    return dispatch({
        type: RESET_LANGUAGE
    })
}