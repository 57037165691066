export default function getWeekDays(language)
{
    const baseDate = new Date(Date.UTC(2017, 0, 2)); // just a Monday
    let weekDays = [""];
    for(let i = 0; i < 7; i++)
    {       
        weekDays.push(baseDate.toLocaleDateString(language, { weekday: 'short' }));
        baseDate.setDate(baseDate.getDate() + 1);       
    }
    return weekDays;
}