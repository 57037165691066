import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'


const styles = theme => ({
    root: {
        ...theme.typography.button,
        padding: theme.spacing(1),
        fontSize: '.7rem'
    },
    languageMenuItem: {
    }
    
})

export const LanguageRow = ({ classes, language, ...props}) => {
    let flag = null
    try {
        flag = require(`../../../assets/countries/flags/4x3/${language.code === 'en' ? 'gb' : language.code}.svg`)
    } catch (error) {
        console.log(error)
    }

    return (
        <MenuItem {...props}>
            <img src={flag} style={{ width: '24px' }} alt="" />
            <Typography className={classes.root}>{language.label}</Typography>
        </MenuItem>
    )
}

class ChooseLanguageComponent extends Component {

    setLanguage = language => {
        console.log('onCLick -> setLanguage', language)
        this.props.setLanguage(language)
    }
    
    listLanguages = (data) => {
        const { classes } = this.props;
        if (!Array.isArray(data)) {
            console.warn('Problem with languages', data)
            return []
        }

        return data.map(language => {
            return (
                <LanguageRow
                    key={language.code}
                    language={language}
                    onClick={() => {
                        this.setLanguage(language.code)
                        this.props.onClose && this.props.onClose(language.code)
                        this.props.closeLanguageOptions && this.props.closeLanguageOptions()
                    }}
                    classes={classes}
                >
                </LanguageRow>
            )
            
        })
    }


    render() {
        const { languagesList } = this.props

        if (languagesList === undefined || languagesList === 'LOADING')
            return null

        return (
            <div style={{ 
                flexDirection: 'row',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                maxHeight: '240px',
                maxWidth: '300px',
                alignItems: 'center'
            }}>
                {this.listLanguages(languagesList)}
            </div>
        )
    }
}

export default withStyles(styles)(ChooseLanguageComponent)
